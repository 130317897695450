import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { PersonalPaymentIdentity } from '../../domain-models/personal-payment.identity';

@IdentityTypeDecorator(PersonalPaymentIdentity)
@ModelTypeNameDecorator('PersonalPaymentBusinessData')
export class GeneratedPersonalPaymentBusinessData extends CoreModel<PersonalPaymentIdentity> {

	private _personalPaymentId: number = 0;

	/**
	 Id pagamento personale
	 */
	@Expose()
	public get personalPaymentId(): number {
		return this.getValueForIdentityValue<number>(() => this._personalPaymentId, 'personalPaymentId');
	}
	public set personalPaymentId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._personalPaymentId = value; }, value, 'personalPaymentId');
	}

	private _userId: number;

	/**
	 Id utente
	 */
	@Expose()
	public get userId(): number {
		return this.getValueForIdentityValue<number>(() => this._userId, 'userId');
	}
	public set userId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._userId = value; }, value, 'userId');
	}

	private _chartAccountCode: number;

	/**
	 Codice sottoconto
	 */
	@Expose()
	public get chartAccountCode(): number {
		return this.getValue<number>(() => this._chartAccountCode, 'chartAccountCode');
	}
	public set chartAccountCode(value: number) {
		this.setValue<number>(() => { this._chartAccountCode = value; }, value, 'chartAccountCode');
	}

	private _companyCode: string;

	/**
	 Codice Ditta
	 */
	@Expose()
	public get companyCode(): string {
		return this.getValue<string>(() => this._companyCode, 'companyCode');
	}
	public set companyCode(value: string) {
		this.setValue<string>(() => { this._companyCode = value; }, value, 'companyCode');
	}

	private _businessModelCode: number;

	/**
	 Codice Business
	 */
	@Expose()
	public get businessModelCode(): number {
		return this.getValue<number>(() => this._businessModelCode, 'businessModelCode');
	}
	public set businessModelCode(value: number) {
		this.setValue<number>(() => { this._businessModelCode = value; }, value, 'businessModelCode');
	}

	private _businessRowNumber: number;

	/**

	 */
	@Expose()
	public get businessRowNumber(): number {
		return this.getValue<number>(() => this._businessRowNumber, 'businessRowNumber');
	}
	public set businessRowNumber(value: number) {
		this.setValue<number>(() => { this._businessRowNumber = value; }, value, 'businessRowNumber');
	}
}
