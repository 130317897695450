import { ExpenseState } from "src/app/expense-annotation/generated/domain-models/enums/generated-expense-state";

export class ExpenseStateMapper {

    private static mapper = {
        [ExpenseState.WebEditing]: {
            icon: 'web-editing',
            color: 'white'
        },
        [ExpenseState.AdministrationEditing]: {
            icon: 'administration-editing',
            color: '#f5a900'
        },
        [ExpenseState.IntegrateDocumentation]: {
            icon: 'integrate-documentation',
            color: '#FEEFC2'
        },
        [ExpenseState.IntegratedDocumentation]: {
            icon: 'integrated-documentation',
            color: '#dae2f0'
        },
        [ExpenseState.Refundable]: {
            icon: 'money-square',
            color: '#d7edca'
        },
        [ExpenseState.NotRefundable]: {
            icon: 'money-square',
            color: '#E91616'
        },
        [ExpenseState.PartiallyRefundable]: {
            icon: 'money-square',
            color: '#d7edca'
        }
    }

    static getIconByExpenseStateType(expenseState: ExpenseState) {
        return this.mapper[expenseState].icon;
    }

    static getColorByExpenseStateType(expenseState: ExpenseState) {
        return this.mapper[expenseState].color;
    }
}
