import { RECEIPT_STEPS } from "../receipt-steps.service";
import { BaseState } from "./base-state";
import { ExpenseSelectionState } from "./expense-selection-state";
import { StepStateInterface } from "./step-state.interface";

export class ExpenseModelDataState extends BaseState implements StepStateInterface {
    classType = ExpenseModelDataState;
    property = 'expenseModelData';
    slug = RECEIPT_STEPS.expenseModel;
    firstStep = true;
    async next(context: any): Promise<StepStateInterface> {
        return new ExpenseSelectionState();
    }
}
