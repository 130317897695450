import {
	InternalViewModel, StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel, 
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel
} from '@nts/std';
import { MileageRefoundData } from '../../domain-models/mileage-refound-data';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';

export class GeneratedMileageRefoundDataViewModel extends InternalViewModel<MileageRefoundData, ReceiptParamsIdentity> {

	private _id: NumericPropertyViewModel;

	public get id(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
	}

	private _distance: NumericPropertyViewModel;

	public get distance(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._distance = value; }, this._distance, 'distance');
	}

	private _unitPrice: NumericPropertyViewModel;

	public get unitPrice(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._unitPrice = value; }, this._unitPrice, 'unitPrice');
	}

}
