import { ItemViewModelTypeDecorator } from '@nts/std';
import { CollectionViewModel } from '@nts/std';
import { AvailablePayment } from '../../domain-models/available-payment';
import { AvailablePaymentIdentity } from '../../domain-models/available-payment.identity';
import { AvailablePaymentCollectionItemViewModel } from '../../view-models/available-payment.collection-item-view-model';
import { AvailablePaymentCollectionViewModel } from '../../view-models/available-payment.collection-view-model';

@ItemViewModelTypeDecorator(AvailablePaymentCollectionItemViewModel)
export class GeneratedAvailablePaymentCollectionViewModel extends
	CollectionViewModel<AvailablePaymentCollectionItemViewModel, AvailablePayment, AvailablePaymentIdentity> {

	constructor() {
		super();

		// Typescript 2.1 BREAKING CHANGE
		// https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, AvailablePaymentCollectionViewModel.prototype);
	}
}

