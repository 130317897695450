import { ExpenseFile } from '../domain-models/expense-file';
import { GeneratedExpenseFileCollectionViewModel } from '../generated/view-models/generated-expense-file.collection-view-model';
import { ExpenseFileCollectionItemViewModel } from './expense-file.collection-item-view-model';

export class ExpenseFileCollectionViewModel extends GeneratedExpenseFileCollectionViewModel {

    async removeFile(itemIndex: number): Promise<void> {
        this.remove(itemIndex);
    }

    async moveUp(itemIndex: number): Promise<void> {
        this.moveItemUp(itemIndex);
    }

    async moveDown(itemIndex: number): Promise<void> {
        this.moveItemDown(itemIndex);
    }

    async addNewItem(): Promise<ExpenseFileCollectionItemViewModel> {
        const entity: ExpenseFile = this.collection.createTypedDomainModel();
        return await this.addFromEntity(entity, true);
    }
}
