import { CoreModel, Expose, IdentityTypeDecorator, ModelTypeNameDecorator, Type } from '@nts/std';
import { AvailablePaymentIdentity } from 'src/app/expense-model/domain-models/available-payment.identity';
import { ExtendedAvailableExpenseIdentity } from './extended-available-expense.identity';
import { AvailableExpenseIdentity } from 'src/app/expense-model/domain-models/available-expense.identity';
import { PersonalExpenseIdentity } from 'src/app/user-available-expenses/domain-models/personal-expense.identity';

@IdentityTypeDecorator(ExtendedAvailableExpenseIdentity)
@ModelTypeNameDecorator('ExtendedAvailableExpense')
export class ExtendedAvailableExpense extends CoreModel<ExtendedAvailableExpenseIdentity> {

	private _id: number = 0;

	/**
	 * Id modalità pagamento
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _description: string;

	/**
	 * Descrizione modalità pagamento
	 */
	@Expose()
	public get description(): string {
		return this.getValue<string>(() => this._description, 'description');
	}
	public set description(value: string) {
		this.setValue<string>(() => { this._description = value; }, value, 'description');
	}

	@Expose()
	@Type(() => AvailableExpenseIdentity)
	public availableExpenseIdentity: AvailableExpenseIdentity;

	@Expose()
	@Type(() => PersonalExpenseIdentity)
	public personalExpenseIdentity: PersonalExpenseIdentity

}
