import { ExpenseSelectionViewModel } from "../../view-models/expense-selection.view-model";
import { RECEIPT_STEPS } from "../receipt-steps.service";
import { BaseState } from "./base-state";
import { ExpenseDataState } from "./expense-data-state";
import { ExpenseModelDataState } from "./expense-model-data-state";
import { StepStateInterface } from "./step-state.interface";

export class ExpenseSelectionState extends BaseState implements StepStateInterface {
    classType = ExpenseSelectionState;
    property = 'expenseSelection';
    slug = RECEIPT_STEPS.expenseClassification;
    async previous(context: any): Promise<StepStateInterface> {

        const viewModel = context.data[context.state.property] as ExpenseSelectionViewModel;
        // se ho una expense model id non posso andare nella lista dei modelli
        if (viewModel.expenseModelId > 0) {
            return null;
        }
        return new ExpenseModelDataState();
    }
    async next(context: any): Promise<StepStateInterface> {
        return new ExpenseDataState();
    }
}
