import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, ChildrenOutletContexts, RouterOutlet } from '@angular/router';
import { OnlineService, UpdateService } from '@nts/std/src/lib/utility';
import { CurrentRouteService, EmptyContainerComponent, RoutingService, ToastMessageService, TokenService } from '@nts/std';
import { faseInAnimation } from '../../animations/fade-in.animation';
import { AuthService } from '@nts/std';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { TelemetryService } from '@nts/std/src/lib/telemetry';

@UntilDestroy()
@Component({
  selector: 'nts-empty-container-with-animation',
  templateUrl: './empty-container-with-animation.component.html',
  styleUrls: ['./empty-container-with-animation.component.scss'],
  animations: [
    faseInAnimation
  ],
  standalone: true,
  imports: [
    RouterOutlet,
  ]
})
export class EmptyContainerWithAnimationComponent extends EmptyContainerComponent {

  constructor(
    protected override readonly routingService: RoutingService,
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly tokenService: TokenService,
    protected override readonly toastMessageService: ToastMessageService,
    protected override readonly updateService: UpdateService,
    protected override readonly onlineService: OnlineService,
    protected override readonly authService: AuthService,
    protected override readonly telemetryService: TelemetryService,
    protected override readonly environmentConfiguration: EnvironmentConfiguration,
    protected override readonly cd: ChangeDetectorRef,
    protected override readonly componentFactoryResolver: ComponentFactoryResolver,
    protected override readonly currentRouteService: CurrentRouteService,
    private contexts: ChildrenOutletContexts,
  ) {
    super(
      routingService,
      activatedRoute,
      tokenService,
      toastMessageService,
      updateService,
      onlineService,
      authService,
      telemetryService,
      environmentConfiguration,
      cd,
      componentFactoryResolver,
      currentRouteService
    )
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
