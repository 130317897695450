import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, BaseRowNumberModel, StringDecorator
} from '@nts/std';
import { ExpenseFileIdentity } from '../../domain-models/expense-file.identity';

@IdentityTypeDecorator(ExpenseFileIdentity)
@ModelTypeNameDecorator('ExpenseFile')
export class GeneratedExpenseFile extends BaseRowNumberModel<ExpenseFileIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _originalFileName: string;

	/**
	 Nome File
	 */
	@Expose()
	public get originalFileName(): string {
		return this.getValue<string>(() => this._originalFileName, 'originalFileName');
	}
	public set originalFileName(value: string) {
		this.setValue<string>(() => { this._originalFileName = value; }, value, 'originalFileName');
	}

	private _storageFileName: string;

	/**
	 Nome File Temporaneo
	 */
	@Expose()
	@StringDecorator({
		isRequired: false
	})
	public get storageFileName(): string {
		return this.getValue<string>(() => this._storageFileName, 'storageFileName');
	}
	public set storageFileName(value: string) {
		this.setValue<string>(() => { this._storageFileName = value; }, value, 'storageFileName');
	}

	private _serial: number = 0;

	/**
	 Seriale file
	 */
	@Expose()
	public get serial(): number {
		return this.getValueForIdentityValue<number>(() => this._serial, 'serial');
	}
	public set serial(value: number) {
		this.setValueForIdentityValue<number>(() => { this._serial = value; }, value, 'serial');
	}

	private _attachmentFileId: number;

	/**
	 Id file spesa
	 */
	@Expose()
	public get attachmentFileId(): number {
		return this.getValue<number>(() => this._attachmentFileId, 'attachmentFileId');
	}
	public set attachmentFileId(value: number) {
		this.setValue<number>(() => { this._attachmentFileId = value; }, value, 'attachmentFileId');
	}

	private _fileSize: number = 0;

	/**
	 Dimensione file
	 */
	@Expose()
	public get fileSize(): number {
		return this.getValue<number>(() => this._fileSize, 'fileSize');
	}
	public set fileSize(value: number) {
		this.setValue<number>(() => { this._fileSize = value; }, value, 'fileSize');
	}
}
