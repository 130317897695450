import { ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, NumericPropertyViewModel, StringPropertyViewModel } from "@nts/std";
import { Lead } from "src/app/external-remote/lead/lead";
import { LeadIdentity } from "src/app/external-remote/lead/lead.identity";

@IdentityTypeDecorator(LeadIdentity)
@ExternalDomainModelTypeDecorator(Lead)
@ExternalDomainModelTypeNameDecorator('Lead')
export class LeadExtViewModel extends ExternalViewModel<Lead, LeadIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: NumericPropertyViewModel;

    get id(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }

    private _companyId: NumericPropertyViewModel;

    public get companyId(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._companyId = value; }, this._companyId, 'companyId');
    }

    // tslint:disable-next-line: variable-name
    private _companyName: StringPropertyViewModel;

    get companyName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._companyName = value; }, this._companyName, 'companyName');
    }
}
