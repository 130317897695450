
import { Expose, IdentityTypeDecorator, ModelTypeNameDecorator, NumberDecorator, OCCModel } from '@nts/std';
import { LeadIdentity } from './lead.identity';


@IdentityTypeDecorator(LeadIdentity)
@ModelTypeNameDecorator('Lead')
export class Lead extends OCCModel<LeadIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'Customer_Id' })
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }

    private _companyId: number;

    /**

     */
    @Expose()
    public get companyId(): number {
        return this.getValueForIdentityValue<number>(() => this._companyId, 'companyId');
    }
    public set companyId(value: number) {
        this.setValueForIdentityValue<number>(() => { this._companyId = value; }, value, 'companyId');
    }

    // tslint:disable-next-line: variable-name
    private _companyName: string;

    @Expose()
    get companyName(): string {
        return this.getValue<string>(() => this._companyName, 'companyName');
    }
    set companyName(value: string) {
        this.setValue<string>(() => { this._companyName = value; }, value, 'companyName');
    }

    // tslint:disable-next-line: variable-name
    private _vatNumber: string;

    @Expose()
    get vatNumber(): string {
        return this.getValue<string>(() => this._vatNumber, 'vatNumber');
    }
    set vatNumber(value: string) {
        this.setValue<string>(() => { this._vatNumber = value; }, value, 'vatNumber');
    }

    // tslint:disable-next-line: variable-name
    private _fiscalCode: string;

    @Expose()
    get fiscalCode(): string {
        return this.getValue<string>(() => this._fiscalCode, 'fiscalCode');
    }
    set fiscalCode(value: string) {
        this.setValue<string>(() => { this._fiscalCode = value; }, value, 'fiscalCode');
    }

    // tslint:disable-next-line: variable-name
    private _city: string;

    @Expose()
    get city(): string {
        return this.getValue<string>(() => this._city, 'city');
    }
    set city(value: string) {
        this.setValue<string>(() => { this._city = value; }, value, 'city');
    }

    private _street: string;

    @Expose()
    get street(): string {
        return this.getValue<string>(() => this._street, 'street');
    }
    set street(value: string) {
        this.setValue<string>(() => { this._street = value; }, value, 'street');
    }

    private _zipCode: string;

    @Expose()
    get zipCode(): string {
        return this.getValue<string>(() => this._zipCode, 'zipCode');
    }
    set zipCode(value: string) {
        this.setValue<string>(() => { this._zipCode = value; }, value, 'zipCode');
    }

    private _buildingNumber: string;

    @Expose()
    get buildingNumber(): string {
        return this.getValue<string>(() => this._buildingNumber, 'buildingNumber');
    }
    set buildingNumber(value: string) {
        this.setValue<string>(() => { this._buildingNumber = value; }, value, 'buildingNumber');
    }

}
