import { EnumPropertyViewModel } from '@nts/std';
import { BehaviorSubject } from 'rxjs';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { ExpenseModel } from 'src/app/expense-model/domain-models/expense-model';
import { StepViewModelAwareInterface } from 'src/app/shared/models/step-view-model-aware.interface';
import { ExpenseStateMapper } from 'src/app/shared/services/expense-state-mapper';
import { GeneratedExpenseModelDataViewModel } from '../generated/view-models/generated-expense-model-data.view-model';
import { ReceiptLongOpOrchestratorViewModel } from './receipt-long-op.orchestrator-view-model';
import { Label } from 'src/app/classification-labels/domain-models/label';

export class ExpenseModelDataViewModel extends GeneratedExpenseModelDataViewModel implements StepViewModelAwareInterface {

    hasActionsButtons = new BehaviorSubject<boolean>(true);
    isPreviousDisabled = new BehaviorSubject<boolean>(false);
    isValid = new BehaviorSubject<boolean>(false);
    hideNext = new BehaviorSubject<boolean>(false);
    expenseModelId = null;
    availableModels: ExpenseModel[] = null;
    modelList: {
        identity: { [key: string]: string | number; },
        description: string,
        all: { [key: string]: string | number; }[]
    }[] = [];
    expenseState: EnumPropertyViewModel
    expenseStateMapper = ExpenseStateMapper;
    showCurrentState: boolean;

    async validateStep(): Promise<void> {
        this.validate();
        this.isValid.next(!this.hasErrors && this.expenseModelRef.modelCode?.value != null && this.expenseModelRef.modelCode?.value > 0)
    };

    async initPresentationLogic(
        expenseAnnotationId: number,
        remoteExpense: boolean,
        modelList: {
            identity: { [key: string]: string | number; },
            description: string,
            all: { [key: string]: string | number; }[]
        }[],
        expenseModelId: number,
        expenseState: EnumPropertyViewModel
    ): Promise<void> {
        const ovm = this.externalRetriever as ReceiptLongOpOrchestratorViewModel;
        this.showCurrentState = expenseAnnotationId > 0 && remoteExpense === true;
        this.modelList = modelList;
        this.expenseState = expenseState;
        this.expenseModelId = expenseModelId;

        // TODO verificare se con la chiamata
        if (expenseModelId > 0) {
            const expenseModel = new ExpenseModel();
            expenseModel.modelCode = expenseModelId;
            await this.expenseModelRef.setFromModel(expenseModel);
        }

        const isEnabled = this.expenseState.value === ExpenseState.WebEditing || this.expenseState.value === ExpenseState.IntegrateDocumentation;

        if (!isEnabled) {
            this.expenseModelCode.isEnabled = isEnabled;
            this.expenseModelRef.isEnabled = isEnabled;
        }
    }
}
