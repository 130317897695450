import { Injectable } from "@angular/core";
import { CacheOptionsInterface, GenericArrayServiceResponse, GenericServiceRequest, ServiceResponse } from "@nts/std";
import { GenericServiceResponse } from "@nts/std";
import { LongOpApiClient, LongOpResultTypeDecorator, RootModelTypeDecorator, RootModelTypeNameDecorator } from "@nts/std";
import { Observable } from "rxjs";
import { ExpenseAnnotationIdentity } from "src/app/expense-annotation/domain-models/expense-annotation.identity";
import { ExpenseIdentity } from "src/app/expense-annotation/domain-models/expense.identity";
import { Customer } from "src/app/external-remote/customer/customer";
import { LeadIdentity } from "src/app/external-remote/lead/lead.identity";
import { UpdateExpenseAnnotationResultDto } from "../domain-models/dto/remove-expense-result.dto";
import { SendExpenseAnnotationToAdministrationResultDto } from "../domain-models/dto/send-expense-annotation-to-administration-result.dto";
import { ResendExpenseAnnotationToAdministrationResultDto } from "../domain-models/dto/resend-expense-annotation-to-administration-result.dto";
import { UpdateExpenseAnnotationDataDto } from "../domain-models/dto/update-expense-annotation-data.dto";
import { RemoveExpenseResultDto } from "../domain-models/dto/update-expense-annotation-result.dto";
import { EditExpenseAnnotationLongOp } from "../domain-models/edit-expense-annotation-long-op";
import { EditExpenseAnnotationLongOpIdentity } from "../domain-models/edit-expense-annotation-long-op-identity";
import { EditExpenseAnnotationParams } from "../domain-models/edit-expense-annotation-params";
import { EditExpenseAnnotationResult } from "../domain-models/edit-expense-annotation-result";
import { Label } from "src/app/classification-labels/domain-models/label";
import { ClassificationLabelResponse } from "../responses/classification-label-response";

@Injectable()
@RootModelTypeNameDecorator('EditExpenseAnnotationLongOp')
@RootModelTypeDecorator(EditExpenseAnnotationLongOp)
@LongOpResultTypeDecorator(EditExpenseAnnotationResult)
export class EditExpenseAnnotationLongOpApiClient extends LongOpApiClient<EditExpenseAnnotationLongOp, EditExpenseAnnotationLongOpIdentity, EditExpenseAnnotationParams, EditExpenseAnnotationResult> {

    sendExpenseAnnotationToAdministration(identity: ExpenseAnnotationIdentity): Observable<GenericServiceResponse<SendExpenseAnnotationToAdministrationResultDto>> {
        const request: GenericServiceRequest<ExpenseAnnotationIdentity> = new GenericServiceRequest<ExpenseAnnotationIdentity>();
        request.requestData = identity;

        const response = new GenericServiceResponse<SendExpenseAnnotationToAdministrationResultDto>(SendExpenseAnnotationToAdministrationResultDto);

        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance('SendExpenseAnnotationToAdministration', request, response);
    }

    getAggregateByExpenseAnnotationIdentityAsync(identity: ExpenseAnnotationIdentity): Observable<GenericServiceResponse<EditExpenseAnnotationLongOp>> {
        const request: GenericServiceRequest<ExpenseAnnotationIdentity> = new GenericServiceRequest<ExpenseAnnotationIdentity>();
        request.requestData = identity;

        const response = new GenericServiceResponse<EditExpenseAnnotationLongOp>(EditExpenseAnnotationLongOp);
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance('GetAggregateByExpenseAnnotationIdentity', request, response);
    }

    removeExpense(identity: ExpenseIdentity): Observable<GenericServiceResponse<RemoveExpenseResultDto>> {
        const request: GenericServiceRequest<ExpenseIdentity> = new GenericServiceRequest<ExpenseIdentity>();
        request.requestData = identity;
        const response = new GenericServiceResponse<RemoveExpenseResultDto>(RemoveExpenseResultDto);

        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance('RemoveExpense', request, response);
    }

    updateExpenseAnnotationData(dto: UpdateExpenseAnnotationDataDto): Observable<GenericServiceResponse<UpdateExpenseAnnotationResultDto>> {
        const request: GenericServiceRequest<UpdateExpenseAnnotationDataDto> = new GenericServiceRequest<UpdateExpenseAnnotationDataDto>();
        request.requestData = dto;

        const response = new GenericServiceResponse<UpdateExpenseAnnotationResultDto>(UpdateExpenseAnnotationResultDto);

        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance('UpdateExpenseAnnotationData', request, response);
    }

    getCustomerByLeadIdentity(identity: LeadIdentity): Observable<GenericServiceResponse<Customer>> {
        const request: GenericServiceRequest<LeadIdentity> = new GenericServiceRequest<LeadIdentity>();
        request.requestData = identity;

        const response = new GenericServiceResponse<Customer>(Customer);
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance('GetCustomerByLeadIdentity', request, response);
    }

    removeExpenseAnnotation(identity: ExpenseAnnotationIdentity): Observable<ServiceResponse> {
        const request: GenericServiceRequest<ExpenseAnnotationIdentity> = new GenericServiceRequest<ExpenseAnnotationIdentity>();
        request.requestData = identity;
        return this.getWebApiServiceAgent()
            .invokePostWithResponse('RemoveExpenseAnnotation', request, ServiceResponse);
    }

    public getClassificationLabelsAsync(cacheOptions: CacheOptionsInterface = {
        bypass: false,
        expirationTime: undefined,
        force: false,
    }): Observable<ClassificationLabelResponse> {

        const response: ClassificationLabelResponse = new ClassificationLabelResponse(Label);
        return this.getWebApiServiceAgent()
            .invokeGetWithOutQueryWithInstance<ClassificationLabelResponse>('GetClassificationLabels', response, false, null, null, null, cacheOptions);
    }

    resendExpenseAnnotationToAdministration(identity: ExpenseAnnotationIdentity): Observable<GenericServiceResponse<ResendExpenseAnnotationToAdministrationResultDto>> {
        const request: GenericServiceRequest<ExpenseAnnotationIdentity> = new GenericServiceRequest<ExpenseAnnotationIdentity>();
        request.requestData = identity;

        const response = new GenericServiceResponse<ResendExpenseAnnotationToAdministrationResultDto>(ResendExpenseAnnotationToAdministrationResultDto);

        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance('ResendExpenseAnnotationToAdministration', request, response);
    }
}
