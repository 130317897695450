import {
	InternalViewModel, StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel,
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel
} from '@nts/std';
import { PaymentData } from '../../domain-models/payment-data';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';

export class GeneratedPaymentDataViewModel extends InternalViewModel<PaymentData, ReceiptParamsIdentity> {

	private _id: NumericPropertyViewModel;

	public get id(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
	}

	private _reliableSupplier: BoolPropertyViewModel;

	public get reliableSupplier(): BoolPropertyViewModel {
		return this.getBoolPropertyViewModel((value) => { this._reliableSupplier = value; }, this._reliableSupplier, 'reliableSupplier');
	}

	private _supplierId: NNumericPropertyViewModel;

	public get supplierId(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._supplierId = value; }, this._supplierId, 'supplierId');
	}

	private _supplierCompanyId: NNumericPropertyViewModel;

	public get supplierCompanyId(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._supplierCompanyId = value; }, this._supplierCompanyId, 'supplierCompanyId');
	}

	private _businessName: StringPropertyViewModel;

	public get businessName(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._businessName = value; }, this._businessName, 'businessName');
	}

	private _vatNumber: StringPropertyViewModel;

	public get vatNumber(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._vatNumber = value; }, this._vatNumber, 'vatNumber');
	}

	private _paymentTypeDescription: StringPropertyViewModel;

	public get paymentTypeDescription(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._paymentTypeDescription = value; }, this._paymentTypeDescription, 'paymentTypeDescription');
	}

}
