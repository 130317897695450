import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { AvailablePaymentIdentity } from '../../domain-models/available-payment.identity';
import { AvailablePaymentBusinessData } from '../../domain-models/available-payment-business-data';
import { ChartAccount } from 'src/app/external-remote/chart-account/chart-account';
import { ChartAccountIdentity } from 'src/app/external-remote/chart-account/chart-account.identity';
import { DateTimeOffset, Transform } from '@nts/std';

@IdentityTypeDecorator(AvailablePaymentIdentity)
@ModelTypeNameDecorator('AvailablePayment')
export class GeneratedAvailablePayment extends CoreModel<AvailablePaymentIdentity> {

	private _modelCode: number = 0;

	/**
	 Codice Modello spesa
	 */
	@Expose()
	public get modelCode(): number {
		return this.getValueForIdentityValue<number>(() => this._modelCode, 'modelCode');
	}
	public set modelCode(value: number) {
		this.setValueForIdentityValue<number>(() => { this._modelCode = value; }, value, 'modelCode');
	}

	private _availablePaymentId: number = 0;

	/**
	 Id pagamento ammesso
	 */
	@Expose()
	public get availablePaymentId(): number {
		return this.getValueForIdentityValue<number>(() => this._availablePaymentId, 'availablePaymentId');
	}
	public set availablePaymentId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._availablePaymentId = value; }, value, 'availablePaymentId');
	}

	private _startDate: DateTimeOffset;

	/**
	 Data inizio
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('startDate', value, obj, type);
	})
	public get startDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._startDate, 'startDate');
	}
	public set startDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._startDate = value; }, value, 'startDate');
	}

	private _expireDate: DateTimeOffset;

	/**
	 Data scadenza
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('expireDate', value, obj, type);
	})
	public get expireDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._expireDate, 'expireDate');
	}
	public set expireDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._expireDate = value; }, value, 'expireDate');
	}

	private _businessData: AvailablePaymentBusinessData;

	/**
	 Dati business
	 */
	@Expose()
	@Type(() => AvailablePaymentBusinessData)
	public get businessData(): AvailablePaymentBusinessData {
		return this.getValueForInternal<AvailablePaymentBusinessData>(
			(value) => {
				this._businessData = value;
			},
			this._businessData,
			'businessData',
			AvailablePaymentBusinessData
		);
	}
	public set businessData(value: AvailablePaymentBusinessData) {
		this.setValueForInternal<AvailablePaymentBusinessData>(() => { this._businessData = value; }, value, 'businessData');
	}

	private _availablePaymentDescription: string;

	/**
	 Descrizione pagamento
	 */
	@Expose()
	public get availablePaymentDescription(): string {
		return this.getValue<string>(() => this._availablePaymentDescription, 'availablePaymentDescription');
	}
	public set availablePaymentDescription(value: string) {
		this.setValue<string>(() => { this._availablePaymentDescription = value; }, value, 'availablePaymentDescription');
	}

	private _isCashAdvance: boolean;

	/**
	 Anticipo contante
	 */
	@Expose()
	public get isCashAdvance(): boolean {
		return this.getValue<boolean>(() => this._isCashAdvance, 'isCashAdvance');
	}
	public set isCashAdvance(value: boolean) {
		this.setValue<boolean>(() => { this._isCashAdvance = value; }, value, 'isCashAdvance');
	}
}
