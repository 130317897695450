import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';

@IdentityTypeDecorator(ReceiptParamsIdentity)
@ModelTypeNameDecorator('MileageRefoundData')
export class GeneratedMileageRefoundData extends CoreModel<ReceiptParamsIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _distance: number = 0;

	/**
	 Distanza
	 */
	@Expose()
	public get distance(): number {
		return this.getValue<number>(() => this._distance, 'distance');
	}
	public set distance(value: number) {
		this.setValue<number>(() => { this._distance = value; }, value, 'distance');
	}

	private _unitPrice: number = 0;

	/**
	 Prezzo unitario
	 */
	@Expose()
	public get unitPrice(): number {
		return this.getValue<number>(() => this._unitPrice, 'unitPrice');
	}
	public set unitPrice(value: number) {
		this.setValue<number>(() => { this._unitPrice = value; }, value, 'unitPrice');
	}
}
