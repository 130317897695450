import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';
import { ExpenseSelection } from '../../domain-models/expense-selection';
import { ExpenseData } from '../../domain-models/expense-data';
import { FoodData } from '../../domain-models/food-data';
import { AccommodationData } from '../../domain-models/accommodation-data';
import { TransportData } from '../../domain-models/transport-data';
import { MileageRefoundData } from '../../domain-models/mileage-refound-data';
import { OtherData } from '../../domain-models/other-data';
import { PaymentData } from '../../domain-models/payment-data';
import { ExpenseModelData } from '../../domain-models/expense-model-data';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';

@IdentityTypeDecorator(ReceiptParamsIdentity)
@ModelTypeNameDecorator('ReceiptParams')
export class GeneratedReceiptParams extends CoreModel<ReceiptParamsIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _expenseSelection: ExpenseSelection;

	/**
	 Tipo spesa
	 */
	@Expose()
	@Type(() => ExpenseSelection)
	public get expenseSelection(): ExpenseSelection {
		return this.getValueForInternal<ExpenseSelection>(
			(value) => {
				this._expenseSelection = value;
			},
			this._expenseSelection,
			'expenseSelection',
			ExpenseSelection
		);
	}
	public set expenseSelection(value: ExpenseSelection) {
		this.setValueForInternal<ExpenseSelection>(() => { this._expenseSelection = value; }, value, 'expenseSelection');
	}

	private _expenseData: ExpenseData;

	/**
	 Dati spesa
	 */
	@Expose()
	@Type(() => ExpenseData)
	public get expenseData(): ExpenseData {
		return this.getValueForInternal<ExpenseData>(
			(value) => {
				this._expenseData = value;
			},
			this._expenseData,
			'expenseData',
			ExpenseData
		);
	}
	public set expenseData(value: ExpenseData) {
		this.setValueForInternal<ExpenseData>(() => { this._expenseData = value; }, value, 'expenseData');
	}

	private _foodData: FoodData;

	/**

	 */
	@Expose()
	@Type(() => FoodData)
	public get foodData(): FoodData {
		return this.getValueForInternal<FoodData>(
			(value) => {
				this._foodData = value;
			},
			this._foodData,
			'foodData',
			FoodData
		);
	}
	public set foodData(value: FoodData) {
		this.setValueForInternal<FoodData>(() => { this._foodData = value; }, value, 'foodData');
	}

	private _accommodationData: AccommodationData;

	/**

	 */
	@Expose()
	@Type(() => AccommodationData)
	public get accommodationData(): AccommodationData {
		return this.getValueForInternal<AccommodationData>(
			(value) => {
				this._accommodationData = value;
			},
			this._accommodationData,
			'accommodationData',
			AccommodationData
		);
	}
	public set accommodationData(value: AccommodationData) {
		this.setValueForInternal<AccommodationData>(() => { this._accommodationData = value; }, value, 'accommodationData');
	}

	private _transportData: TransportData;

	/**

	 */
	@Expose()
	@Type(() => TransportData)
	public get transportData(): TransportData {
		return this.getValueForInternal<TransportData>(
			(value) => {
				this._transportData = value;
			},
			this._transportData,
			'transportData',
			TransportData
		);
	}
	public set transportData(value: TransportData) {
		this.setValueForInternal<TransportData>(() => { this._transportData = value; }, value, 'transportData');
	}

	private _mileageRefoundData: MileageRefoundData;

	/**

	 */
	@Expose()
	@Type(() => MileageRefoundData)
	public get mileageRefoundData(): MileageRefoundData {
		return this.getValueForInternal<MileageRefoundData>(
			(value) => {
				this._mileageRefoundData = value;
			},
			this._mileageRefoundData,
			'mileageRefoundData',
			MileageRefoundData
		);
	}
	public set mileageRefoundData(value: MileageRefoundData) {
		this.setValueForInternal<MileageRefoundData>(() => { this._mileageRefoundData = value; }, value, 'mileageRefoundData');
	}

	private _otherData: OtherData;

	/**

	 */
	@Expose()
	@Type(() => OtherData)
	public get otherData(): OtherData {
		return this.getValueForInternal<OtherData>(
			(value) => {
				this._otherData = value;
			},
			this._otherData,
			'otherData',
			OtherData
		);
	}
	public set otherData(value: OtherData) {
		this.setValueForInternal<OtherData>(() => { this._otherData = value; }, value, 'otherData');
	}

	private _paymentData: PaymentData;

	/**

	 */
	@Expose()
	@Type(() => PaymentData)
	public get paymentData(): PaymentData {
		return this.getValueForInternal<PaymentData>(
			(value) => {
				this._paymentData = value;
			},
			this._paymentData,
			'paymentData',
			PaymentData
		);
	}
	public set paymentData(value: PaymentData) {
		this.setValueForInternal<PaymentData>(() => { this._paymentData = value; }, value, 'paymentData');
	}

	private _expenseModelData: ExpenseModelData;

	/**
	 Modello spesa
	 */
	@Expose()
	@Type(() => ExpenseModelData)
	public get expenseModelData(): ExpenseModelData {
		return this.getValueForInternal<ExpenseModelData>(
			(value) => {
				this._expenseModelData = value;
			},
			this._expenseModelData,
			'expenseModelData',
			ExpenseModelData
		);
	}
	public set expenseModelData(value: ExpenseModelData) {
		this.setValueForInternal<ExpenseModelData>(() => { this._expenseModelData = value; }, value, 'expenseModelData');
	}

	private _expenseState: ExpenseState;

	/**
	 Stato spesa
	 */
	@Expose()
	public get expenseState(): ExpenseState {
		return this.getValue<ExpenseState>(() => this._expenseState, 'expenseState');
	}
	public set expenseState(value: ExpenseState) {
		this.setValue<ExpenseState>(() => { this._expenseState = value; }, value, 'expenseState');
	}
}
