import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ComponentLocator, NtsAppModule } from "@nts/std";
import { SharedModule } from "../shared/shared.module";
import { ReceiptDashboardComponent } from "./component/receipt-dashboard/receipt-dashboard.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { receiptIcons } from "../svg/receipt";
import { EmptyContainerWithAnimationComponent } from "../shared/components/empty-container-with-animation/empty-container-with-animation.component";
import { provideSvgIcons } from '@ngneat/svg-icon';

const routes: Routes = [
    {
        path: '',
        component: EmptyContainerWithAnimationComponent,
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                component: ReceiptDashboardComponent
            }
        ]
    },
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule.forChild(),
        RouterModule.forChild(routes),
    ],
    declarations: [],
    providers: [
        provideSvgIcons(receiptIcons)
    ]
})
export class ReceiptListModule extends NtsAppModule {

    constructor(
        componentLocator: ComponentLocator) {

        super(
            componentLocator
        );
    }

    registerAll() { }
}
