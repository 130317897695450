import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ComponentLocator, CurrentSessionService, NtsAppModule, OnlineService, StdModule, WebApiServiceAgent } from "@nts/std";
import { SharedModule } from "../shared/shared.module";
import { ReceiptStepsService, RECEIPT_STEPS } from "./services/receipt-steps.service";
import { ReceiptStepContainerComponent } from "./component/receipt-step-container/receipt-step-container.component";
import { ReceiptStepGuard } from "./services/receipt-step-guard.service";
import { ReceiptLongOpApiClient } from "./api-clients/receipt-long-op.api-client";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ReceiptCompletePageComponent } from "./component/receipt-complete-page/receipt-complete-page.component";
import { ExpenseDataStepComponent } from "./component/receipt-step-container/expense-data-step/expense-data-step.component";
import { FoodDataStepComponent } from "./component/receipt-step-container/food-data-step/food-data-step.component";
import { AccommodationDataStepComponent } from "./component/receipt-step-container/accommodation-data-step/accommodation-data-step.component";
import { TransportDataStepComponent } from "./component/receipt-step-container/transport-data-step/transport-data-step.component";
import { MileageRefoundDataStepComponent } from "./component/receipt-step-container/mileage-refound-data-step/mileage-refound-data-step.component";
import { OtherDataStepComponent } from "./component/receipt-step-container/other-data-step/other-data-step.component";
import { PaymentDataStepComponent } from "./component/receipt-step-container/payment-data-step/payment-data-step.component";
import { ExpenseModelDataStepComponent } from "./component/receipt-step-container/expense-model-data-step/expense-model-data-step.component";
import { receiptIcons } from "../svg/receipt";
import { ExpenseSelectionStepComponent } from "./component/receipt-step-container/expense-selection-step/expense-selection-step.component";
import { ExpenseModelApiClient } from "../expense-model/api-clients/expense-model.api-client";
import { EmptyContainerWithAnimationComponent } from "../shared/components/empty-container-with-animation/empty-container-with-animation.component";
import { provideSvgIcons } from '@ngneat/svg-icon';

const routes: Routes = [
    {
        path: '',
        component: EmptyContainerWithAnimationComponent,
        children: [
            {
                path: '',
                redirectTo: 'new',
                pathMatch: 'full'
            },
            {
                path: 'creation-complete',
                component: ReceiptCompletePageComponent,
                data: { animation: 'OperationComplete' }
            },
            {
                path: 'update-complete/:id',
                component: ReceiptCompletePageComponent,
                data: { animation: 'OperationComplete' }
            },
            {
                path: ':action',
                component: ReceiptStepContainerComponent,
                children: [
                    {
                        path: RECEIPT_STEPS.expenseModel,
                        component: ExpenseModelDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'One' }
                    },
                    {
                        path: `${RECEIPT_STEPS.expenseModel}/:id`,
                        component: ExpenseModelDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'One' }
                    },
                    {
                        path: RECEIPT_STEPS.expenseClassification,
                        component: ExpenseSelectionStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Two' }
                    },
                    {
                        path: `${RECEIPT_STEPS.expenseClassification}/:id`,
                        component: ExpenseSelectionStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Two' }
                    },
                    {
                        path: RECEIPT_STEPS.expenseData,
                        component: ExpenseDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Three' }
                    },
                    {
                        path: `${RECEIPT_STEPS.expenseData}/:id`,
                        component: ExpenseDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Three' }
                    },
                    {
                        path: RECEIPT_STEPS.foodData,
                        component: FoodDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Four' }
                    },
                    {
                        path: `${RECEIPT_STEPS.foodData}/:id`,
                        component: FoodDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Four' }
                    },
                    {
                        path: RECEIPT_STEPS.accommodationData,
                        component: AccommodationDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Four' }
                    },
                    {
                        path: `${RECEIPT_STEPS.accommodationData}/:id`,
                        component: AccommodationDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Four' }
                    },
                    {
                        path: RECEIPT_STEPS.transportData,
                        component: TransportDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Four' }
                    },
                    {
                        path: `${RECEIPT_STEPS.transportData}/:id`,
                        component: TransportDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Four' }
                    },
                    {
                        path: RECEIPT_STEPS.mileageRefoundData,
                        component: MileageRefoundDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Four' }
                    },
                    {
                        path: `${RECEIPT_STEPS.mileageRefoundData}/:id`,
                        component: MileageRefoundDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Four' }
                    },
                    {
                        path: RECEIPT_STEPS.otherData,
                        component: OtherDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Four' }
                    },
                    {
                        path: `${RECEIPT_STEPS.otherData}/:id`,
                        component: OtherDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Four' }
                    },
                    {
                        path: RECEIPT_STEPS.paymentData,
                        component: PaymentDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Five' }
                    },
                    {
                        path: `${RECEIPT_STEPS.paymentData}/:id`,
                        component: PaymentDataStepComponent,
                        canActivate: [ReceiptStepGuard],
                        data: { animation: 'Five' }
                    },
                    {
                        path: ':id',
                        redirectTo: `${RECEIPT_STEPS.expenseModel}/:id`,
                    },
                    {
                        path: '',
                        redirectTo: RECEIPT_STEPS.expenseModel,
                        pathMatch: 'full'
                    }
                ]
            }
        ]
    },
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule.forChild(),
        RouterModule.forChild(routes),
    ],
    providers: [
        {
            provide: ReceiptLongOpApiClient,
            deps: [WebApiServiceAgent, OnlineService]
        },
        {
            provide: ExpenseModelApiClient,
            deps: [WebApiServiceAgent, OnlineService]
        },
        ReceiptStepsService,
        ReceiptStepGuard,
        provideSvgIcons(receiptIcons)
    ]
})
export class ReceiptLongOpModule extends NtsAppModule {

    constructor(
        componentLocator: ComponentLocator) {

        super(
            componentLocator
        );
    }

    registerAll() { }
}
