import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CacheOptionsInterface, GenericServiceRequest, GenericServiceResponse, LongOpApiClient, LongOpResultTypeDecorator, RootModelTypeDecorator, RootModelTypeNameDecorator } from "@nts/std";
import { map, Observable } from "rxjs";
import { ExpenseModel } from "src/app/expense-model/domain-models/expense-model";
import { Customer } from "src/app/external-remote/customer/customer";
import { LeadIdentity } from "src/app/external-remote/lead/lead.identity";
import { CreateExpenseAnnotationLongOp } from "../domain-models/create-expense-annotation-long-op";
import { CreateExpenseAnnotationLongOpIdentity } from "../domain-models/create-expense-annotation-long-op-identity";
import { CreateExpenseAnnotationParams } from "../domain-models/create-expense-annotation-params";
import { CreateExpenseAnnotationResult } from "../domain-models/create-expense-annotation-result";
import { ExpenseModelListResponse } from "../responses/expense-model-list-response";
import { WeaSettingsDataResponse } from "../responses/wea-settings-data-response";

@Injectable()
@RootModelTypeNameDecorator('CreateExpenseAnnotationLongOp')
@RootModelTypeDecorator(CreateExpenseAnnotationLongOp)
@LongOpResultTypeDecorator(CreateExpenseAnnotationResult)
export class CreateExpenseAnnotationLongOpApiClient extends LongOpApiClient<CreateExpenseAnnotationLongOp, CreateExpenseAnnotationLongOpIdentity, CreateExpenseAnnotationParams, CreateExpenseAnnotationResult> {

  getAvailableModels(
    cacheOptions: CacheOptionsInterface = {
      bypass: false,
      expirationTime: 60, // scadono dopo 60 secondi
      force: false,
    },
    companyId: number = null,
    customHeaders: HttpHeaders = null
  ): Observable<ExpenseModelListResponse> {
    return this.getWebApiServiceAgent()
      .invokeGetWithOutQuery(
        'GetAvailableModels',
        ExpenseModelListResponse,
        false,
        customHeaders,
        null,
        null,
        cacheOptions
      ).pipe(
        map((expenseModelListResponse: ExpenseModelListResponse) => {
          if (expenseModelListResponse.operationSuccedeed && expenseModelListResponse.result?.length > 0 && companyId > 0) {
            expenseModelListResponse.result = expenseModelListResponse.result.filter((model: ExpenseModel) =>
              model.companyId === companyId
            )
          }
          return expenseModelListResponse
        })
      );
  }

  getWEASettingsData(
    cacheOptions: CacheOptionsInterface = {
      bypass: false,
      expirationTime: 60, // scadono dopo 60 secondi
      force: false,
    },
    customHeaders?: HttpHeaders
  ): Observable<WeaSettingsDataResponse> {
    return this.getWebApiServiceAgent()
      .invokeGetWithOutQuery<WeaSettingsDataResponse>(
        'GetWEASettingsData',
        WeaSettingsDataResponse,
        false,
        customHeaders,
        null,
        null,
        cacheOptions
      );
  }

  getCustomerByLeadIdentity(identity: LeadIdentity): Observable<GenericServiceResponse<Customer>> {
    const request: GenericServiceRequest<LeadIdentity> = new GenericServiceRequest<LeadIdentity>();
    request.requestData = identity;

    const response = new GenericServiceResponse<Customer>(Customer);
    return this.getWebApiServiceAgent()
      .invokePostWithResponseWithInstance('GetCustomerByLeadIdentity', request, response);
  }
}
