import { AutoCompleteExternalOptions, NNumericPropertyViewModel } from "@nts/std";
import { OrderByType } from "@nts/std";
import { OrderBy } from "@nts/std";
import { ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, NumericPropertyViewModel, StringPropertyViewModel } from "@nts/std";
import { ExtendedAvailablePaymentIdentity } from "../domain-models/extended-available-payment.identity";
import { ExtendedAvailablePayment } from "../domain-models/extended-available-payment";

@IdentityTypeDecorator(ExtendedAvailablePaymentIdentity)
@ExternalDomainModelTypeDecorator(ExtendedAvailablePayment)
@ExternalDomainModelTypeNameDecorator('ExtendedAvailablePayment')
export class ExtendedAvailablePaymentExtViewModel extends ExternalViewModel<ExtendedAvailablePayment, ExtendedAvailablePaymentIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: NNumericPropertyViewModel;

    get id(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }

    // tslint:disable-next-line: variable-name
    private _description: StringPropertyViewModel;

    get description(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._description = value; }, this._description, 'description');
    }

    override async postInit() {

        await super.postInit();

        this.zoomAddIsEnabled = false;
        this.zoomAddIsVisible = false;
        this.zoomSearchIsEnabled = false;
        this.zoomSearchIsVisible = false;
        this.zoomViewIsEnabled = false;
        this.zoomViewIsVisible = false;

        this.autoCompleteOptions = new AutoCompleteExternalOptions();
        this.autoCompleteOptions.propertySearchList = ['Id', 'Description'];
        this.autoCompleteOptions.orderByPropertyNames = [
            new OrderBy({ propertyName: 'Description', sortType: OrderByType.Ascending })
        ];
        this.autoCompleteOptions.outputProperties = ['Id', 'Description'];
    }

}
