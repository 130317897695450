import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ExpenseModelIdentity } from 'src/app/expense-model/domain-models/expense-model.identity';

@IdentityTypeDecorator(ExpenseModelIdentity)
@ModelTypeNameDecorator('ExpenseModelBusinessData')
export class GeneratedExpenseModelBusinessData extends CoreModel<ExpenseModelIdentity> {

	private _modelCode: number = 0;

	/**
	 Codice Modello spesa
	 */
	@Expose()
	public get modelCode(): number {
		return this.getValueForIdentityValue<number>(() => this._modelCode, 'modelCode');
	}
	public set modelCode(value: number) {
		this.setValueForIdentityValue<number>(() => { this._modelCode = value; }, value, 'modelCode');
	}

	private _companyCode: string;

	/**
	 CodiceDitta
	 */
	@Expose()
	public get companyCode(): string {
		return this.getValue<string>(() => this._companyCode, 'companyCode');
	}
	public set companyCode(value: string) {
		this.setValue<string>(() => { this._companyCode = value; }, value, 'companyCode');
	}

	private _businessModelCode: number = 0;

	/**
	 Codice Business
	 */
	@Expose()
	public get businessModelCode(): number {
		return this.getValue<number>(() => this._businessModelCode, 'businessModelCode');
	}
	public set businessModelCode(value: number) {
		this.setValue<number>(() => { this._businessModelCode = value; }, value, 'businessModelCode');
	}
}
