import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';

@IdentityTypeNameDecorator('FileDataIdentity')
export class FileDataIdentity extends BaseIdentity {

    // tslint:disable-next-line: variable-name
    private _id: number;

    @Expose()
    get id(): number {
        return this.getValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValue<number>(() => { this._id = value; }, value, 'id');
    }
}

