import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CurrentSessionService } from "@nts/std";
import { WebApiServiceAgent } from "@nts/std";
import { FrameworkServiceApiClient } from "@nts/std";
import { OnlineService } from "@nts/std";
import { StdModule } from "@nts/std";
import { ExpenseModelApiClient } from "../expense-model/api-clients/expense-model.api-client";
import { ReceiptLongOpApiClient } from "../receipt-long-op/api-clients/receipt-long-op.api-client";
import { OfflineSyncService } from "./services/offline-sync.service";
import { RouteStateService } from "./services/route-state.service";
import { sharedIcons } from "../svg/shared";
import { CurrentExpenseAnnotationService } from "./services/current-expense-annotation.service";
import { CreateExpenseAnnotationLongOpApiClient } from "../create-expense-annotation-long-op/api-clients/create-expense-annotation-long-op.api-client";
import { ChipsModule } from 'primeng/chips';
import { CustomMailChipsComponent } from "./components/custom-mail-chips/custom-mail-chips.component";

export const RECEIPT_LIST_SLUG = `receiptlist`;
export const RECEIPT_LIST_FULL_PATH = `/manage/${RECEIPT_LIST_SLUG}`;
export const RECEIPT_LONG_OP_SLUG = `receiptlongop`;
export const RECEIPT_LONG_OP_FULL_PATH = `/manage/${RECEIPT_LONG_OP_SLUG}`;
export const CREATE_EXPENSE_ANNOTATION_LONG_OP_SLUG = `createexpenseannotationlongop`;
export const CREATE_EXPENSE_ANNOTATION_LONG_OP_FULL_PATH = `/manage/${CREATE_EXPENSE_ANNOTATION_LONG_OP_SLUG}`;
export const CREATE_EXPENSE_ANNOTATION_LONG_OP_FULL_NAME = 'WebExpenseAnnotation.CreateExpenseAnnotationLongOpObjects.Models.CreateExpenseAnnotationLongOp';
export const EXPENSE_TYPE_SLUG = `expensetype`;
export const EXPENSE_MODEL_SLUG = `expensemodel`;
export const USER_EXPENSE_MODEL_ASSOCIATION_SLUG = `userexpensemodelassociation`;
export const USER_CASH_ADVANCE_SLUG = `usercashadvance`;
export const EXPENSE_ANNOTATION_LIST_LONG_OP_SLUG = `expenseannotationlistlongop`;
export const EXPENSE_ANNOTATION_LIST_LONG_OP_FULL_PATH = `/manage/${EXPENSE_ANNOTATION_LIST_LONG_OP_SLUG}`;
export const EXPENSE_ANNOTATION_LIST_LONG_OP_FULL_NAME = 'WebExpenseAnnotation.ExpenseAnnotationListLongOpObjects.Models.ExpenseAnnotationListLongOp';
export const EDIT_EXPENSE_ANNOTATION_LONG_OP_SLUG = `editexpenseannotationlongop`;
export const EDIT_EXPENSE_ANNOTATION_LONG_OP_FULL_PATH = `/manage/${EDIT_EXPENSE_ANNOTATION_LONG_OP_SLUG}`;
export const EDIT_EXPENSE_ANNOTATION_LONG_OP_FULL_NAME = 'WebExpenseAnnotation.EditExpenseAnnotationLongOpObjects.Models.EditExpenseAnnotationLongOp';
export const USER_CASH_ADVANCE_LONG_OP_SLUG = `usercashadvancelongop`;
export const WEA_SETTINGS_SLUG = `weasettings`;
export const USER_AVAILABLE_PAYMENTS_SLUG = `useravailablepayments`;
export const PRINT_EXPENSE_ANNOTATION_SLUG = `printexpenseannotation`;
export const USER_AVAILABLE_EXPENSES_SLUG = `useravailableexpenses`;
export const CLASSIFICATION_LABELS_SLUG = `classificationlabels`;
export const EXPENSE_ANNOTATION_ALERT_SLUG = `expenseannotationalert`;
export const EXPENSE_ANNOTATION_ALERT_FULL_NAME = `WebExpenseAnnotation.ExpenseAnnotationAlertObjects.Models.ExpenseAnnotationAlert`;
import { provideSvgIcons } from '@ngneat/svg-icon';
import { EditExpenseAnnotationLongOpApiClient } from "../edit-expense-annotation-long-op/api-clients/edit-expense-annotation-long-op.api-client";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        StdModule,
        ChipsModule,
    ],
    declarations: [],
    exports: [],
    providers: [
        provideSvgIcons(sharedIcons)
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                RouteStateService,
                {
                    provide: FrameworkServiceApiClient,
                    deps: [WebApiServiceAgent, OnlineService]
                },
                {
                    provide: ReceiptLongOpApiClient,
                    deps: [WebApiServiceAgent, OnlineService]
                },
                {
                    provide: CreateExpenseAnnotationLongOpApiClient,
                    deps: [WebApiServiceAgent, OnlineService]
                },
                {
                    provide: EditExpenseAnnotationLongOpApiClient,
                    deps: [WebApiServiceAgent, OnlineService]
                },
                {
                    provide: ExpenseModelApiClient,
                    deps: [WebApiServiceAgent, OnlineService]
                },
                OfflineSyncService,
                CurrentExpenseAnnotationService,
            ]
        };
    }

    static forChild(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }
}
