import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { AvailableExpenseIdentity } from '../../domain-models/available-expense.identity';

@IdentityTypeDecorator(AvailableExpenseIdentity)
@ModelTypeNameDecorator('CompilationInstructions')
export class GeneratedCompilationInstructions extends CoreModel<AvailableExpenseIdentity> {

	private _availableExpenseId: number = 0;

	/**
	 Id tipologia spesa ammessa
	 */
	@Expose()
	public get availableExpenseId(): number {
		return this.getValueForIdentityValue<number>(() => this._availableExpenseId, 'availableExpenseId');
	}
	public set availableExpenseId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._availableExpenseId = value; }, value, 'availableExpenseId');
	}

	private _modelCode: number = 0;

	/**
	 Codice Modello spesa
	 */
	@Expose()
	public get modelCode(): number {
		return this.getValueForIdentityValue<number>(() => this._modelCode, 'modelCode');
	}
	public set modelCode(value: number) {
		this.setValueForIdentityValue<number>(() => { this._modelCode = value; }, value, 'modelCode');
	}

	private _instructions: string;

	/**
	 istruzioni di compilazione
	 */
	@Expose()
	public get instructions(): string {
		return this.getValue<string>(() => this._instructions, 'instructions');
	}
	public set instructions(value: string) {
		this.setValue<string>(() => { this._instructions = value; }, value, 'instructions');
	}
}
