import { StateMachine } from "src/app/shared/services/state-machine";
import { ExpenseClassification } from "../../generated/domain-models/enums/generated-expense-classification";
import { ExpenseDataViewModel } from "../../view-models/expense-data.view-model";
import { ReceiptParamsViewModel } from "../../view-models/receipt-params.view-model";
import { RECEIPT_STEPS } from "../receipt-steps.service";
import { AccommodationDataState } from "./accommodation-data-state";
import { BaseState } from "./base-state";
import { ExpenseSelectionState } from "./expense-selection-state";
import { FoodDataState } from "./food-data-state";
import { MileageRefoundDataState } from "./mileage-refound-data-state";
import { OtherDataState } from "./other-data-state";
import { StepStateInterface } from "./step-state.interface";
import { TransportDataState } from "./transport-data-state";

export class ExpenseDataState extends BaseState implements StepStateInterface {
    classType = ExpenseDataState;
    property = 'expenseData';
    slug = RECEIPT_STEPS.expenseData;
    async previous(context: any): Promise<StepStateInterface> {
        return new ExpenseSelectionState();
    }
    async next(context: StateMachine<ReceiptParamsViewModel, StepStateInterface>): Promise<StepStateInterface> {
        {
            const viewModel = context.data[context.state.property] as ExpenseDataViewModel;
            switch (viewModel.currentExpenseClassificationType) {
                case ExpenseClassification.Food:
                    return new FoodDataState();
                case ExpenseClassification.Accommodation:
                    return new AccommodationDataState();
                case ExpenseClassification.MileageRefound:
                    return new MileageRefoundDataState();
                case ExpenseClassification.Other:
                    return new OtherDataState();
                case ExpenseClassification.Transport:
                    return new TransportDataState();
                case ExpenseClassification.Accommodation:
                    return new AccommodationDataState();
                default:
                    return new FoodDataState();
            }
        }
    }
}
