<div class="step-template" *ngIf="viewModel">
    <h2>{{viewModel.newLabels().value[viewModel.currentExpenseClassificationType] ?? ('ExpenseClassification_' +
        viewModel.expenseClassificationEnum[viewModel.currentExpenseClassificationType] |
        NTSTranslate)}}</h2>

    <div class="state-container" *ngIf="viewModel.showCurrentState">
        <div class="state-box">
            <div class="state-color"
                [style.backgroundColor]="viewModel.expenseStateMapper.getColorByExpenseStateType(viewModel.expenseState.value)">
                <div class="state-icon">
                    <svg-icon
                        [key]="viewModel.expenseStateMapper.getIconByExpenseStateType(viewModel.expenseState.value)">
                    </svg-icon>
                </div>
            </div>
            <div class="state-label">{{viewModel.expenseState.formattedValue}}</div>
        </div>
    </div>

    <div class="field-list" [class.has-state-visible]="viewModel.showCurrentState">
        <!-- <nts-loader [isVisible]="!stepLoaded"></nts-loader> -->
        <nts-expandable [title]="'TransportDataStep_Expandable_Main' | NTSTranslate" [disableAdditionalFields]="true"
            [isCollapsed]="false" [disableToggle]="true" [promotedFields]="">

            <nts-labelled-text-box #expandableChild [propertyViewModel]="viewModel.peopleQuantity">
            </nts-labelled-text-box>

        </nts-expandable>
    </div>
</div>
