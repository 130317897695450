import {
	InternalViewModel, StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel,
	ExternalViewModelTypeDecorator,
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel
} from '@nts/std';
import { ExpenseModelData } from '../../domain-models/expense-model-data';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';
import { ExpenseModelExtViewModel } from '../../view-models/expense-model.ext-view-model';

export class GeneratedExpenseModelDataViewModel extends InternalViewModel<ExpenseModelData, ReceiptParamsIdentity> {

	private _id: NumericPropertyViewModel;

	public get id(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
	}

	private _expenseModelCode: NNumericPropertyViewModel;

	public get expenseModelCode(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._expenseModelCode = value; }, this._expenseModelCode, 'expenseModelCode');
	}

	@ExternalViewModelTypeDecorator(ExpenseModelExtViewModel)
	public expenseModelRef: ExpenseModelExtViewModel;

}
