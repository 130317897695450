import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
    selector: 'nts-model-box',
    templateUrl: './model-box.component.html',
    styleUrls: ['./model-box.component.scss'],
    standalone: true,
    imports: [
        SvgIconComponent
    ]
})
export class ModelBoxComponent implements OnInit {

    @Input() model!: {
        identity: { [key: string]: string | number; },
        description: string,
        all: { [key: string]: string | number; }[]
    };
    @Input() selected = false;
    @Output() onClicked = new EventEmitter<{
        identity: { [key: string]: string | number; },
        description: string,
        all: { [key: string]: string | number; }[]
    }>();

    constructor() { }

    ngOnInit(): void {
    }
}
