import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';
import { EditExpenseAnnotationParamsIdentity } from '../../domain-models/edit-expense-annotation-params.identity';

@IdentityTypeNameDecorator('ExpenseItemIdentity')
export class GeneratedExpenseItemIdentity extends EditExpenseAnnotationParamsIdentity {

	private _expenseId: number;

	@Expose()
	public get expenseId(): number {
		return this.getValue<number>(() => this._expenseId, 'expenseId');
	}
	public set expenseId(value: number) {
		this.setValue<number>(() => { this._expenseId = value; }, value, 'expenseId');
	}
}
