import { IdentityTypeNameDecorator, BaseIdentity, Expose } from "@nts/std";

@IdentityTypeNameDecorator('ReceiptLongOpIdentity')
export class ReceiptLongOpIdentity extends BaseIdentity {

    private _id: number;

    @Expose()
    get id(): number {
        return this.getValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValue<number>(() => { this._id = value; }, value, 'id');
    }
}
