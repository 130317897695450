<div class="main-content">
    <div class="top-content">
        <div class="selected-icon">
            <svg-icon fontSize="35px" key="check">
            </svg-icon>
        </div>
        <div class="icon">
            <svg-icon fontSize="100px" [key]="icon">
            </svg-icon>
        </div>
        <div class="content">
            <div class="overview_card-header">{{newLabels[type] ?? (('ExpenseClassification_' +
                expenseTypeEnum[type])
                |
                NTSTranslate)}}</div>
            <div class="overview_card-content">
                {{ currencyIso?.length > 0 ? (totalAmount |
                currency:currencyIso:'symbol') : totalAmount | currency:'EUR':'symbol'}}
            </div>
            <div class="overview_card-footer">{{date |date:'d/MM/yyyy' }}</div>
        </div>
        <div class="actions">

            <nts-text-button *ngIf="selectionMode" (onClicked)="toggleSelection()"
                [label]="(isSelected ? 'CMD_UnchooseReceipt_DisplayName' : 'CMD_ChooseReceipt_DisplayName') | NTSTranslate">
            </nts-text-button>
            <nts-text-button *ngIf="!selectionMode" (onClicked)="onEdit.emit()" icon="edit-pencil"></nts-text-button>
            <nts-text-button *ngIf="!selectionMode" (onClicked)="onDelete.emit()" icon="trash">
            </nts-text-button>

        </div>
    </div>
    <div class="footer-content">
        <div class="actions">

            <nts-text-button *ngIf="selectionMode" (onClicked)="toggleSelection()"
                [label]="(isSelected ? 'CMD_UnchooseReceipt_DisplayName' : 'CMD_ChooseReceipt_DisplayName') | NTSTranslate">
            </nts-text-button>
            <nts-text-button *ngIf="!selectionMode" (onClicked)="onEdit.emit()" icon="edit-pencil"></nts-text-button>
            <nts-text-button *ngIf="!selectionMode" (onClicked)="onDelete.emit()" icon="trash">
            </nts-text-button>

        </div>
    </div>
</div>
<div class="additional-info">
    <div class="business-data-container">
        <div class="business-data-label">{{'ExpenseItem_ExpenseNotes' | NTSTranslate}}
        </div>
        <div class="business-data-value">{{notes?.length > 0 ? notes: 'Non impostato'}} </div>
    </div>
</div>
