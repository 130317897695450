import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from "@ngneat/until-destroy";
import { RECEIPT_LIST_FULL_PATH } from 'src/app/shared/shared.module';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { FilledButtonComponent, NTSTranslatePipe } from '@nts/std';

@UntilDestroy()
@Component({
  selector: 'nts-receipt-complete-page',
  templateUrl: './receipt-complete-page.component.html',
  styleUrls: ['./receipt-complete-page.component.scss'],
  standalone: true,
  imports: [
    SvgIconComponent,
    FilledButtonComponent,
    NTSTranslatePipe
  ]
})
export class ReceiptCompletePageComponent implements OnInit {

  isUpdate = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.isUpdate = this.activatedRoute.routeConfig.path.startsWith('update-complete');
  }

  goToDashboard() {
    this.router.navigate([`${RECEIPT_LIST_FULL_PATH}`])
  }
}
