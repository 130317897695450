interface StoredRoute {
    route: ActivatedRouteSnapshot;
    handle: DetachedRouteHandle;
}

// Helper per ricostruire l'intero URL da una rotta
// function getFullPath(route: ActivatedRouteSnapshot): string {
//     return route.pathFromRoot
//         .map(v => v.url.map(segment => segment.toString()).join("/"))
//         .join("/")
//         .trim()
//         .replace(/\/$/, ""); // Remove trailing slash
// }

import {
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
    Route,
    RouteReuseStrategy
} from "@angular/router";

export class CustomReuseStrategy implements RouteReuseStrategy {

    // Oggetto Dictionary dove tengo le rotte salvate
    storedRoutes: Map<Route, StoredRoute> = new Map();

    // Dovremmo salvare questa rotta?
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return !!route.data['storeRoute'];
    }

    // Salviamo la rotta
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const key = route.routeConfig; // Ex. users/1, users/2, users/3, ...
        this.storedRoutes.set(key, { route, handle });
        if ((<any>handle)?.componentRef?.instance?.ngOnDetach) {
            (<any>handle).componentRef.instance.ngOnDetach();
        }
    }

    // Dovremmo riprenderci questa rotta dallo store?
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const key = route.routeConfig;
        const isStored = !!route.routeConfig && !!this.storedRoutes.get(key);

        if (isStored) {
            // Comparazione dei parametri, inutile, ma la lascio come esempio!
            const paramsMatch = this.compareObjects(
                route.params,
                this.storedRoutes.get(key).route.params
            );
            const queryParamsMatch = this.compareObjects(
                route.queryParams,
                this.storedRoutes.get(key).route.queryParams
            );

            return paramsMatch && queryParamsMatch;
        }
        return false;
    }

    // Ritorniamo la rotta dallo store (se c'è)
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        const key = route.routeConfig;//getFullPath(route);
        if (!route.routeConfig || !this.storedRoutes.get(key)) return null;

        if ((<any>this.storedRoutes.get(key).handle)?.componentRef?.instance?.ngOnAttach) {
            (<any>this.storedRoutes.get(key).handle).componentRef.instance.ngOnAttach();
        }

        return this.storedRoutes.get(key).handle;
    }

    // Dovremmo riutilizzare questa rotta? Default: solo se non ha la stessa configurazione.
    shouldReuseRoute(
        previous: ActivatedRouteSnapshot,
        next: ActivatedRouteSnapshot
    ): boolean {
        return previous.routeConfig === next.routeConfig;
    }

    // Simple object comparison (from StackOverflow, needs to be double-checked)
    private compareObjects(a: any, b: any): boolean {
        return true;
    }
}
