import { Expose, Type } from '@nts/std';
import { AttachmentIdentity } from 'src/app/expense-annotation/domain-models/attachment.identity';
import { GeneratedExpenseFile } from '../generated/domain-models/generated-expense-file';

export class ExpenseFile extends GeneratedExpenseFile {


    /**
    * identity allegato
    */
    @Expose()
    @Type(() => AttachmentIdentity)
    attachmentIdentity: AttachmentIdentity;

}
