import { Expose, Type } from '@nts/std';
import { ExpenseAnnotationIdentity } from 'src/app/expense-annotation/domain-models/expense-annotation.identity';
import { ExpenseIdentity } from 'src/app/expense-annotation/domain-models/expense.identity';
import { CommissionIdentity } from 'src/app/external-remote/commission/commission.identity';
import { GeneralSubjectIdentity } from 'src/app/external-remote/general-subject/general-subject.identity';
import { LeadIdentity } from 'src/app/external-remote/lead/lead.identity';
import { GeneratedReceiptParams } from '../generated/domain-models/generated-receipt-params';

export class ReceiptParams extends GeneratedReceiptParams {

    /**
     * identity nota spese
     */
    @Expose()
    @Type(() => ExpenseAnnotationIdentity)
    expenseAnnotationIdentity: ExpenseAnnotationIdentity;

    /**
     * identity spesa
     */
    @Expose()
    @Type(() => ExpenseIdentity)
    expenseIdentity: ExpenseIdentity;

    @Expose()
    @Type(() => GeneralSubjectIdentity)
    customerIdentity: GeneralSubjectIdentity

    @Expose()
    @Type(() => LeadIdentity)
    leadIdentity: LeadIdentity

    @Expose()
    @Type(() => CommissionIdentity)
    commissionIdentity: CommissionIdentity
}
