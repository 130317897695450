import { BaseError } from '@nts/std';
import { FileUploadStatusInterface } from 'src/app/shared/components/multiple-file-upload/multiple-file-upload.component';
import { GeneratedExpenseFileCollectionItemViewModel } from '../generated/view-models/generated-expense-file.collection-item-view-model';
1
export class ExpenseFileCollectionItemViewModel extends GeneratedExpenseFileCollectionItemViewModel implements FileUploadStatusInterface {
    fileObject?: File;
    progress?: number;
    filePreviewUrl = null;
    fileErrors: BaseError[] = []

    get fileUploadFileSize(): number {
        return this.fileSize.value;
    }

    set fileUploadFileSize(fileSize: number) {
        this.fileSize.value = fileSize;
    }

    get fileUploadOriginalFileName(): string {
        return this.originalFileName.value;
    }

    set fileUploadOriginalFileName(originalFileName: string) {
        this.originalFileName.value = originalFileName;
    }

    get fileUploadStorageName(): string {
        return this.storageFileName.value;
    }

    set fileUploadStorageName(storageFileName: string) {
        this.storageFileName.value = storageFileName;
    }
}
