import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { CreateExpenseAnnotationParamsIdentity } from '../../domain-models/create-expense-annotation-params.identity';
import { ModelSelection } from '../../domain-models/model-selection';
import { AnnotationData } from '../../domain-models/annotation-data';

@IdentityTypeDecorator(CreateExpenseAnnotationParamsIdentity)
@ModelTypeNameDecorator('CreateExpenseAnnotationParams')
export class GeneratedCreateExpenseAnnotationParams extends CoreModel<CreateExpenseAnnotationParamsIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _modelSelection: ModelSelection;

	/**
	 Modello Spesa
	 */
	@Expose()
	@Type(() => ModelSelection)
	public get modelSelection(): ModelSelection {
		return this.getValueForInternal<ModelSelection>(
			(value) => {
				this._modelSelection = value;
			},
			this._modelSelection,
			'modelSelection',
			ModelSelection
		);
	}
	public set modelSelection(value: ModelSelection) {
		this.setValueForInternal<ModelSelection>(() => { this._modelSelection = value; }, value, 'modelSelection');
	}

	private _annotationData: AnnotationData;

	/**
	 Dati nota spese
	 */
	@Expose()
	@Type(() => AnnotationData)
	public get annotationData(): AnnotationData {
		return this.getValueForInternal<AnnotationData>(
			(value) => {
				this._annotationData = value;
			},
			this._annotationData,
			'annotationData',
			AnnotationData
		);
	}
	public set annotationData(value: AnnotationData) {
		this.setValueForInternal<AnnotationData>(() => { this._annotationData = value; }, value, 'annotationData');
	}
}
