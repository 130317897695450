import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';
import { WEASettingsIdentity } from 'src/app/wea-settings/domain-models/wea-settings.identity';


@IdentityTypeNameDecorator('LabelIdentity')
export class GeneratedLabelIdentity extends WEASettingsIdentity {

	private _serial: number;

	@Expose()
	public get serial(): number {
		return this.getValue<number>(() => this._serial, 'serial');
	}
	public set serial(value: number) {
		this.setValue<number>(() => { this._serial = value; }, value, 'serial');
	}
}
