import { ExpenseClassification } from "../../generated/domain-models/enums/generated-expense-classification";
import { PaymentDataViewModel } from "../../view-models/payment-data.view-model";
import { RECEIPT_STEPS } from "../receipt-steps.service";
import { AccommodationDataState } from "./accommodation-data-state";
import { BaseState } from "./base-state";
import { FoodDataState } from "./food-data-state";
import { MileageRefoundDataState } from "./mileage-refound-data-state";
import { OtherDataState } from "./other-data-state";
import { StepStateInterface } from "./step-state.interface";
import { TransportDataState } from "./transport-data-state";

export class PaymentDataState extends BaseState implements StepStateInterface {
    classType = PaymentDataState;
    property = 'paymentData';
    slug = RECEIPT_STEPS.paymentData;
    lastStep = true;

    async previous(context: any): Promise<StepStateInterface> {
        const viewModel = context.data[context.state.property] as PaymentDataViewModel;
        switch (viewModel.currentExpenseClassificationType) {
            case ExpenseClassification.Food:
                return new FoodDataState();
            case ExpenseClassification.Accommodation:
                return new AccommodationDataState();
            case ExpenseClassification.MileageRefound:
                return new MileageRefoundDataState();
            case ExpenseClassification.Other:
                return new OtherDataState();
            case ExpenseClassification.Transport:
                return new TransportDataState();
            case ExpenseClassification.Accommodation:
                return new AccommodationDataState();
            default:
                return new FoodDataState();
        }
    }
}
