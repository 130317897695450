import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel, Transform, DateTimeOffset
} from '@nts/std';
import { AvailableExpenseIdentity } from '../../domain-models/available-expense.identity';
import { CompilationInstructions } from '../../domain-models/compilation-instructions';
import { AvailableExpenseBusinessData } from '../../domain-models/available-expense-business-data';
import { ExpenseType } from 'src/app/expense-type/domain-models/expense-type';
import { ExpenseTypeIdentity } from 'src/app/expense-type/domain-models/expense-type.identity';

@IdentityTypeDecorator(AvailableExpenseIdentity)
@ModelTypeNameDecorator('AvailableExpense')
export class GeneratedAvailableExpense extends CoreModel<AvailableExpenseIdentity> {

	private _modelCode: number = 0;

	/**
	 Codice Modello spesa
	 */
	@Expose()
	public get modelCode(): number {
		return this.getValueForIdentityValue<number>(() => this._modelCode, 'modelCode');
	}
	public set modelCode(value: number) {
		this.setValueForIdentityValue<number>(() => { this._modelCode = value; }, value, 'modelCode');
	}

	private _availableExpenseId: number = 0;

	/**
	 Id tipologia spesa ammessa
	 */
	@Expose()
	public get availableExpenseId(): number {
		return this.getValueForIdentityValue<number>(() => this._availableExpenseId, 'availableExpenseId');
	}
	public set availableExpenseId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._availableExpenseId = value; }, value, 'availableExpenseId');
	}

	private _description: string;

	/**
	 Descrizione
	 */
	@Expose()
	public get description(): string {
		return this.getValue<string>(() => this._description, 'description');
	}
	public set description(value: string) {
		this.setValue<string>(() => { this._description = value; }, value, 'description');
	}

	private _expenseTypeId: number;

	/**
	 Id Tipo spesa
	 */
	@Expose()
	public get expenseTypeId(): number {
		return this.getValue<number>(() => this._expenseTypeId, 'expenseTypeId');
	}
	public set expenseTypeId(value: number) {
		this.setValue<number>(() => { this._expenseTypeId = value; }, value, 'expenseTypeId');
	}

	private _expenseTypeRef: ExpenseType;

	/**
	 Tipo spesa
	 */
	@Expose()
	@Type(() => ExpenseType)
	public get expenseTypeRef(): ExpenseType {
		return this.getValueForExternal<ExpenseType>(
			() => this._expenseTypeRef,
			'expenseTypeRef',
			ExpenseType
		);
	}
	public set expenseTypeRef(value: ExpenseType) {
		this.setValueForExternal<ExpenseType, ExpenseTypeIdentity>(() => { this._expenseTypeRef = value; }, value, 'expenseTypeRef');
	}

	private _instructions: CompilationInstructions;

	/**
	 Istruzioni compilazione
	 */
	@Expose()
	@Type(() => CompilationInstructions)
	public get instructions(): CompilationInstructions {
		return this.getValueForInternal<CompilationInstructions>(
			(value) => {
				this._instructions = value;
			},
			this._instructions,
			'instructions',
			CompilationInstructions
		);
	}
	public set instructions(value: CompilationInstructions) {
		this.setValueForInternal<CompilationInstructions>(() => { this._instructions = value; }, value, 'instructions');
	}

	private _businessData: AvailableExpenseBusinessData;

	/**
	 Dati business
	 */
	@Expose()
	@Type(() => AvailableExpenseBusinessData)
	public get businessData(): AvailableExpenseBusinessData {
		return this.getValueForInternal<AvailableExpenseBusinessData>(
			(value) => {
				this._businessData = value;
			},
			this._businessData,
			'businessData',
			AvailableExpenseBusinessData
		);
	}
	public set businessData(value: AvailableExpenseBusinessData) {
		this.setValueForInternal<AvailableExpenseBusinessData>(() => { this._businessData = value; }, value, 'businessData');
	}

	private _startDate: DateTimeOffset;

	/**
	 Data inizio
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('startDate', value, obj, type);
	})
	public get startDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._startDate, 'startDate');
	}
	public set startDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._startDate = value; }, value, 'startDate');
	}

	private _expireDate: DateTimeOffset;

	/**
	 Data scadenza
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('expireDate', value, obj, type);
	})
	public get expireDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._expireDate, 'expireDate');
	}
	public set expireDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._expireDate = value; }, value, 'expireDate');
	}
}
