import { BaseLongOpModel, Expose, IdentityTypeDecorator, LongOpParametersTypeDecorator, LongOpResultTypeDecorator, ModelTypeNameDecorator } from "@nts/std";
import { EditExpenseAnnotationLongOpIdentity } from "./edit-expense-annotation-long-op-identity";
import { EditExpenseAnnotationParams } from "./edit-expense-annotation-params";
import { EditExpenseAnnotationResult } from "./edit-expense-annotation-result";



@IdentityTypeDecorator(EditExpenseAnnotationLongOpIdentity)
@ModelTypeNameDecorator('EditExpenseAnnotationLongOp')
@LongOpParametersTypeDecorator(EditExpenseAnnotationParams)
@LongOpResultTypeDecorator(EditExpenseAnnotationResult)
export class EditExpenseAnnotationLongOp extends BaseLongOpModel<EditExpenseAnnotationLongOpIdentity, EditExpenseAnnotationParams, EditExpenseAnnotationResult> {

    private _id: number;

    @Expose()
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }
}
