import { Lead } from 'src/app/external-remote/lead/lead';
import { GeneratedAnnotationData } from '../generated/domain-models/generated-annotation-data';
import { Expose, Type } from '@nts/std';
import { LeadIdentity } from 'src/app/external-remote/lead/lead.identity';
import { Customer } from 'src/app/external-remote/customer/customer';
import { GeneralSubjectIdentity } from 'src/app/external-remote/general-subject/general-subject.identity';
import { Commission } from 'src/app/external-remote/commission/commission';
import { CommissionIdentity } from 'src/app/external-remote/commission/commission.identity';

export class AnnotationData extends GeneratedAnnotationData {


    private _leadRef: Lead;

    /**
     Lead
     */
    @Expose()
    @Type(() => Lead)
    public get leadRef(): Lead {
        return this.getValueForExternal<Lead>(
            () => this._leadRef,
            'leadRef',
            Lead
        );
    }
    public set leadRef(value: Lead) {
        this.setValueForExternal<Lead, LeadIdentity>(() => { this._leadRef = value; }, value, 'leadRef');
    }

    private _customerRef: Customer;

    /**
     Cliente
     */
    @Expose()
    @Type(() => Customer)
    public get customerRef(): Customer {
        return this.getValueForExternal<Customer>(
            () => this._customerRef,
            'customerRef',
            Customer
        );
    }
    public set customerRef(value: Customer) {
        this.setValueForExternal<Customer, GeneralSubjectIdentity>(() => { this._customerRef = value; }, value, 'customerRef');
    }

    private _commissionRef: Commission;

    /**
     Commessa
     */
    @Expose()
    @Type(() => Commission)
    public get commissionRef(): Commission {
        return this.getValueForExternal<Commission>(
            () => this._commissionRef,
            'commissionRef',
            Commission
        );
    }
    public set commissionRef(value: Commission) {
        this.setValueForExternal<Commission, CommissionIdentity>(() => { this._commissionRef = value; }, value, 'commissionRef');
    }


}
