import { GenericServiceResponse } from '@nts/std';
import { ResponseCachedDecorator, ResponseResultTypeDecorator } from '@nts/std';
import { WeaSettingsDataDto } from '../api-clients/dto/wea-settings-data.dto';


@ResponseResultTypeDecorator(WeaSettingsDataDto)
@ResponseCachedDecorator()
export class WeaSettingsDataResponse extends GenericServiceResponse<WeaSettingsDataDto> {

}
