<nts-loader *ngIf="showLoader" [isVisible]="true" [text]="'Caricamento..'" [skipHeader]="false">
</nts-loader>

<main class="main" [class.show-actions]="showActions">

    <div class="page-title">{{ 'ReceiptDashBoard_Title' | NTSTranslate }}</div>

    <div *ngIf="showCounters" class="main_overview">
        <div class="overview_card" *ngFor="let expenseType of expenseTypeList;">
            <div class="top-content">
                <div class="icon">
                    <svg-icon fontSize="100px" [key]="expenseTypeMapper.getIconByClassificationType(expenseType)">
                    </svg-icon>
                </div>
                <div class="content">
                    <div class="overview_card-header">{{ newLabels[expenseType] ?? (('ExpenseClassification_' +
                        expenseTypeEnum[expenseType]) |
                        NTSTranslate)}}</div>
                    <div class="overview_card-content">{{getTotalByExpenseType(expenseType)}}
                    </div>
                    <div class="overview_card-footer">{{getQtyByExpenseType(expenseType)}}
                        {{(getQtyByExpenseType(expenseType)
                        >
                        1
                        ||
                        getQtyByExpenseType(expenseType) == 0 ) ?
                        ('ReceiptDashBoard_Receipts' | NTSTranslate) : ('ReceiptDashBoard_Receipt' | NTSTranslate)}}
                    </div>
                </div>
            </div>
            <div class="footer-content"></div>
        </div>
    </div>

    <div class="main_cards">

        <div class="card">
            <div class="card-head">
                <header>{{'ReceiptDashBoard_ReceiptList' | NTSTranslate}}</header>
                <nts-ribbon-button *ngIf="!selectionMode" [icon]="'plus'" (onClicked)="newReceipt()"
                    [label]="'CMD_AddReceipt_DisplayName' | NTSTranslate"></nts-ribbon-button>
            </div>
            <div class="card-body" *ngIf="currentCompanyId > 0">

                <nts-single-expense [newLabels]="newLabels"
                    [color]="expenseTypeMapper.getColorByClassificationType(receipt.expenseType)"
                    [currencyIso]="currencyIso" [selectionMode]="selectionMode" (onDelete)="deleteReceipt(receipt)"
                    (onEdit)="editReceipt(receipt)" (onSelectionChanged)="onReceiptSelectionChanged($event, receipt)"
                    [icon]="expenseTypeMapper.getIconByClassificationType(receipt.expenseType)"
                    [date]="receipt.expenseDate" [type]="receipt.expenseType" [totalAmount]="receipt.expenseTotalAmount"
                    *ngFor="let receipt of receipts | receiptForCompanyFilter: currentCompanyId"></nts-single-expense>

                <div *ngIf="!receipts || (receipts | receiptForCompanyFilter: currentCompanyId)?.length == 0"
                    class="no-receipts">
                    {{ 'ReceiptDashBoard_NoExpenseFound' | NTSTranslate}}
                </div>
            </div>
        </div>
    </div>

</main>

<div *ngIf="showActions" class="actions-container">
    <nts-text-button [label]="'CMD_PreviousStep_DisplayName' | NTSTranslate" (onClicked)="onPreviousStep()">
    </nts-text-button>

    <nts-filled-button [label]="'CMD_AddSelectedReceiptsStep_DisplayName' | NTSTranslate"
        [isDisabled]="(receipts  | selectedReceiptsFilter)?.length == 0" (onClicked)="onAddSelectedReceipts()">
    </nts-filled-button>
</div>

<nts-fullscreen-preview [show]="true" [inIframe]="inIframe" [(imageSrc)]="currentImageModalSrc">
</nts-fullscreen-preview>
