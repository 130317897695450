import { CoreModel, Expose, IdentityTypeDecorator, ModelTypeNameDecorator, Type } from '@nts/std';
import { AvailablePaymentIdentity } from 'src/app/expense-model/domain-models/available-payment.identity';
import { PersonalPaymentIdentity } from 'src/app/user-available-payments/domain-models/personal-payment.identity';
import { ExtendedAvailablePaymentIdentity } from './extended-available-payment.identity';

@IdentityTypeDecorator(ExtendedAvailablePaymentIdentity)
@ModelTypeNameDecorator('ExtendedAvailablePayment')
export class ExtendedAvailablePayment extends CoreModel<ExtendedAvailablePaymentIdentity> {

	private _id: number = 0;

	/**
	 * Id modalità pagamento
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

    private _description: string;

	/**
	 * Descrizione modalità pagamento
	 */
	@Expose()
	public get description(): string {
		return this.getValue<string>(() => this._description, 'description');
	}
	public set description(value: string) {
		this.setValue<string>(() => { this._description = value; }, value, 'description');
	}

    @Expose()
    @Type(() => AvailablePaymentIdentity)
    public availablePaymentIdentity: AvailablePaymentIdentity;

    @Expose()
    @Type(() => PersonalPaymentIdentity)
    public personalPaymentIdentity: PersonalPaymentIdentity;
}
