import { BaseRowNumberCollectionViewModel, ItemViewModelTypeDecorator } from '@nts/std';
import { CollectionViewModel } from '@nts/std';
import { ExpenseFile } from '../../domain-models/expense-file';
import { ExpenseFileIdentity } from '../../domain-models/expense-file.identity';
import { ExpenseFileCollectionItemViewModel } from '../../view-models/expense-file.collection-item-view-model';
import { ExpenseFileCollectionViewModel } from '../../view-models/expense-file.collection-view-model';

@ItemViewModelTypeDecorator(ExpenseFileCollectionItemViewModel)
export class GeneratedExpenseFileCollectionViewModel extends
	BaseRowNumberCollectionViewModel<ExpenseFileCollectionItemViewModel, ExpenseFile, ExpenseFileIdentity> {

	constructor() {
		super();

		// Typescript 2.1 BREAKING CHANGE
		// https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, ExpenseFileCollectionViewModel.prototype);
	}
}

