import { ExternalViewModelTypeDecorator, NNumericPropertyViewModel } from "@nts/std";
import { ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, NumericPropertyViewModel, StringPropertyViewModel } from "@nts/std";
import { Customer } from "src/app/external-remote/customer/customer";
import { GeneralSubjectIdentity } from "src/app/external-remote/general-subject/general-subject.identity";
import { LeadExtViewModel } from "./lead.ext-view-model";

@IdentityTypeDecorator(GeneralSubjectIdentity)
@ExternalDomainModelTypeDecorator(Customer)
@ExternalDomainModelTypeNameDecorator('Customer')
export class CustomerExtViewModel extends ExternalViewModel<Customer, GeneralSubjectIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: NumericPropertyViewModel;

    get id(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }

    private _companyId: NumericPropertyViewModel;

    public get companyId(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._companyId = value; }, this._companyId, 'companyId');
    }

    // tslint:disable-next-line: variable-name
    private _companyName: StringPropertyViewModel;

    get companyName(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._companyName = value; }, this._companyName, 'companyName');
    }

    // tslint:disable-next-line: variable-name
    private _vatNumber: StringPropertyViewModel;

    get vatNumber(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._vatNumber = value; }, this._vatNumber, 'vatNumber');
    }
    // tslint:disable-next-line: variable-name
    private _fiscalCode: StringPropertyViewModel;

    get fiscalCode(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._fiscalCode = value; }, this._fiscalCode, 'fiscalCode');
    }
    // tslint:disable-next-line: variable-name
    private _city: StringPropertyViewModel;

    get city(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._city = value; }, this._city, 'city');
    }
    // tslint:disable-next-line: variable-name
    private _zipCode: StringPropertyViewModel;

    get zipCode(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._zipCode = value; }, this._zipCode, 'zipCode');
    }

    private _leadId: NNumericPropertyViewModel;

    public get leadId(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._leadId = value; }, this._leadId, 'leadId');
    }

    private _leadCompanyId: NNumericPropertyViewModel;

    public get leadCompanyId(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._leadCompanyId = value; }, this._leadCompanyId, 'leadCompanyId');
    }


    @ExternalViewModelTypeDecorator(LeadExtViewModel)
    public leadRef: LeadExtViewModel;

}
