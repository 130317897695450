import {
	InternalViewModel, StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel,
	InternalViewModelTypeDecorator,
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel, ItemViewModel
} from '@nts/std';
import { AvailablePayment } from '../../domain-models/available-payment';
import { AvailablePaymentBusinessDataViewModel } from '../../view-models/available-payment-business-data.view-model';
import { AvailablePaymentIdentity } from '../../domain-models/available-payment.identity';

export class GeneratedAvailablePaymentCollectionItemViewModel extends ItemViewModel<AvailablePayment, AvailablePaymentIdentity> {

	private _modelCode: NumericPropertyViewModel;

	public get modelCode(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._modelCode = value; }, this._modelCode, 'modelCode');
	}

	private _availablePaymentId: NumericPropertyViewModel;

	public get availablePaymentId(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._availablePaymentId = value; }, this._availablePaymentId, 'availablePaymentId');
	}

	private _startDate: DateTimeOffsetPropertyViewModel;

	public get startDate(): DateTimeOffsetPropertyViewModel {
		return this.getDateTimeOffsetPropertyViewModel((value) => { this._startDate = value; }, this._startDate, 'startDate');
	}

	private _expireDate: DateTimeOffsetPropertyViewModel;

	public get expireDate(): DateTimeOffsetPropertyViewModel {
		return this.getDateTimeOffsetPropertyViewModel((value) => { this._expireDate = value; }, this._expireDate, 'expireDate');
	}

	@InternalViewModelTypeDecorator(AvailablePaymentBusinessDataViewModel)
	public businessData: AvailablePaymentBusinessDataViewModel;

	private _availablePaymentDescription: StringPropertyViewModel;

	public get availablePaymentDescription(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._availablePaymentDescription = value; }, this._availablePaymentDescription, 'availablePaymentDescription');
	}

	private _isCashAdvance: BoolPropertyViewModel;

	public get isCashAdvance(): BoolPropertyViewModel {
		return this.getBoolPropertyViewModel((value) => { this._isCashAdvance = value; }, this._isCashAdvance, 'isCashAdvance');
	}

}
