import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';
import { ExpenseIdentity } from '../../domain-models/expense.identity';

@IdentityTypeNameDecorator('AttachmentIdentity')
export class GeneratedAttachmentIdentity extends ExpenseIdentity {

	private _attachmentId: number;

	@Expose()
	public get attachmentId(): number {
		return this.getValue<number>(() => this._attachmentId, 'attachmentId');
	}
	public set attachmentId(value: number) {
		this.setValue<number>(() => { this._attachmentId = value; }, value, 'attachmentId');
	}
}
