<div class="step-template" *ngIf="viewModel">
    <h2>{{'ExpenseSelectionStep_Title'| NTSTranslate}}</h2>

    <div class="state-container" *ngIf="viewModel.showCurrentState">
        <div class="state-box">
            <div class="state-color"
                [style.backgroundColor]="viewModel.expenseStateMapper.getColorByExpenseStateType(viewModel.expenseState.value)">
                <div class="state-icon">
                    <svg-icon
                        [key]="viewModel.expenseStateMapper.getIconByExpenseStateType(viewModel.expenseState.value)">
                    </svg-icon>
                </div>
            </div>
            <div class="state-label">{{viewModel.expenseState.formattedValue}}</div>
        </div>
    </div>

    <div class="element-list">
        <nts-expense-classification-box *ngFor="let expenseClassification of expenseClassificationList;"
            [editable]="viewModel.expenseClassification.isEnabled"
            [selected]="expenseClassification === viewModel.expenseClassification.value" [item]="expenseClassification"
            (onClicked)="selectExpenseClassification($event)" [newLabel]="viewModel.newLabels() | async">
        </nts-expense-classification-box>

        <div *ngIf="!expenseClassificationList.length" class="empty-message-container">
            <div class="empty-logo">
                <svg-icon [fontSize]="'50px'" [key]="'warning-circle-outline'"></svg-icon>
            </div>
            <div class="empty-message">
                {{'ExpenseSelectionStep_NoExpenseClassificationFoundMessage'| NTSTranslate}}
            </div>
        </div>

    </div>
</div>
