import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ExpenseTypeIdentity } from 'src/app/expense-type/domain-models/expense-type.identity';

@IdentityTypeDecorator(ExpenseTypeIdentity)
@ModelTypeNameDecorator('ExpenseTypeBusinessData')
export class GeneratedExpenseTypeBusinessData extends CoreModel<ExpenseTypeIdentity> {

	private _code: number = 0;

	/**
	 Codice tipo spesa
	 */
	@Expose()
	public get code(): number {
		return this.getValueForIdentityValue<number>(() => this._code, 'code');
	}
	public set code(value: number) {
		this.setValueForIdentityValue<number>(() => { this._code = value; }, value, 'code');
	}

	private _businessCode: string;

	/**
	 Business code
	 */
	@Expose()
	public get businessCode(): string {
		return this.getValue<string>(() => this._businessCode, 'businessCode');
	}
	public set businessCode(value: string) {
		this.setValue<string>(() => { this._businessCode = value; }, value, 'businessCode');
	}

	private _companyCode: string;

	/**
	 Codice Ditta
	 */
	@Expose()
	public get companyCode(): string {
		return this.getValue<string>(() => this._companyCode, 'companyCode');
	}
	public set companyCode(value: string) {
		this.setValue<string>(() => { this._companyCode = value; }, value, 'companyCode');
	}
}
