import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, Transform, DateTimeOffset, TimeSpan, CoreModel
} from '@nts/std';
import { PersonalExpenseIdentity } from '../../domain-models/personal-expense.identity';
import { PersonalExpenseBusinessData } from '../../domain-models/personal-expense-business-data';
import { ExpenseType } from 'src/app/expense-type/domain-models/expense-type';
import { ExpenseTypeIdentity } from 'src/app/expense-type/domain-models/expense-type.identity';

@IdentityTypeDecorator(PersonalExpenseIdentity)
@ModelTypeNameDecorator('PersonalExpense')
export class GeneratedPersonalExpense extends CoreModel<PersonalExpenseIdentity> {

	private _userId: number;

	/**
	 Id utente
	 */
	@Expose()
	public get userId(): number {
		return this.getValueForIdentityValue<number>(() => this._userId, 'userId');
	}
	public set userId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._userId = value; }, value, 'userId');
	}

	private _companyId: number = 0;

	/**
	 
	 */
	@Expose()
	public get companyId(): number {
		return this.getValueForIdentityValue<number>(() => this._companyId, 'companyId');
	}
	public set companyId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._companyId = value; }, value, 'companyId');
	}

	private _personalExpenseId: number = 0;

	/**
	 Id spesa personale
	 */
	@Expose()
	public get personalExpenseId(): number {
		return this.getValueForIdentityValue<number>(() => this._personalExpenseId, 'personalExpenseId');
	}
	public set personalExpenseId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._personalExpenseId = value; }, value, 'personalExpenseId');
	}

	private _expenseTypeId: number;

	/**
	 Id Tipo spesa
	 */
	@Expose()
	public get expenseTypeId(): number {
		return this.getValue<number>(() => this._expenseTypeId, 'expenseTypeId');
	}
	public set expenseTypeId(value: number) {
		this.setValue<number>(() => { this._expenseTypeId = value; }, value, 'expenseTypeId');
	}

	private _expenseTypeRef: ExpenseType;

	/**
	 Tipo spesa
	 */
	@Expose()
	@Type(() => ExpenseType)
	public get expenseTypeRef(): ExpenseType {
		return this.getValueForExternal<ExpenseType>(
			() => this._expenseTypeRef,
			'expenseTypeRef',
			ExpenseType
		);
	}
	public set expenseTypeRef(value: ExpenseType) {
		this.setValueForExternal<ExpenseType, ExpenseTypeIdentity>(() => { this._expenseTypeRef = value; }, value, 'expenseTypeRef');
	}

	private _description: string;

	/**
	 Descrizione
	 */
	@Expose()
	public get description(): string {
		return this.getValue<string>(() => this._description, 'description');
	}
	public set description(value: string) {
		this.setValue<string>(() => { this._description = value; }, value, 'description');
	}

	private _expenseModelNCTCode: number;

	/**
	 Codice modello di origine
	 */
	@Expose()
	public get expenseModelNCTCode(): number {
		return this.getValue<number>(() => this._expenseModelNCTCode, 'expenseModelNCTCode');
	}
	public set expenseModelNCTCode(value: number) {
		this.setValue<number>(() => { this._expenseModelNCTCode = value; }, value, 'expenseModelNCTCode');
	}

	private _businessData: PersonalExpenseBusinessData;

	/**
	 Dati business
	 */
	@Expose()
	@Type(() => PersonalExpenseBusinessData)
	public get businessData(): PersonalExpenseBusinessData {
		return this.getValueForInternal<PersonalExpenseBusinessData>(
			(value) => {
				this._businessData = value;
			},
			this._businessData,
			'businessData',
			PersonalExpenseBusinessData
		);
	}
	public set businessData(value: PersonalExpenseBusinessData) {
		this.setValueForInternal<PersonalExpenseBusinessData>(() => { this._businessData = value; }, value, 'businessData');
	}

	private _startDate: DateTimeOffset;

	/**
	 Data inizio
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('startDate', value, obj, type);
	})
	public get startDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._startDate, 'startDate');
	}
	public set startDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._startDate = value; }, value, 'startDate');
	}

	private _expireDate: DateTimeOffset;

	/**
	 Data scadenza
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('expireDate', value, obj, type);
	})
	public get expireDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._expireDate, 'expireDate');
	}
	public set expireDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._expireDate = value; }, value, 'expireDate');
	}
}
