import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';

@IdentityTypeDecorator(ReceiptParamsIdentity)
@ModelTypeNameDecorator('FoodData')
export class GeneratedFoodData extends CoreModel<ReceiptParamsIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _peopleQuantity: number = 0;

	/**
	 Numero persone
	 */
	@Expose()
	public get peopleQuantity(): number {
		return this.getValue<number>(() => this._peopleQuantity, 'peopleQuantity');
	}
	public set peopleQuantity(value: number) {
		this.setValue<number>(() => { this._peopleQuantity = value; }, value, 'peopleQuantity');
	}

	private _guestQuantity: number = 0;

	/**
	 Numero ospiti
	 */
	@Expose()
	public get guestQuantity(): number {
		return this.getValue<number>(() => this._guestQuantity, 'guestQuantity');
	}
	public set guestQuantity(value: number) {
		this.setValue<number>(() => { this._guestQuantity = value; }, value, 'guestQuantity');
	}
}
