import { BaseLongOpModel, Expose, IdentityTypeDecorator, LongOpParametersTypeDecorator, LongOpResultTypeDecorator, ModelTypeNameDecorator } from "@nts/std";
import { CreateExpenseAnnotationLongOpIdentity } from "./create-expense-annotation-long-op-identity";
import { CreateExpenseAnnotationParams } from "./create-expense-annotation-params";
import { CreateExpenseAnnotationResult } from "./create-expense-annotation-result";



@IdentityTypeDecorator(CreateExpenseAnnotationLongOpIdentity)
@ModelTypeNameDecorator('CreateExpenseAnnotationLongOp')
@LongOpParametersTypeDecorator(CreateExpenseAnnotationParams)
@LongOpResultTypeDecorator(CreateExpenseAnnotationResult)
export class CreateExpenseAnnotationLongOp extends BaseLongOpModel<CreateExpenseAnnotationLongOpIdentity, CreateExpenseAnnotationParams, CreateExpenseAnnotationResult> {

    private _id: number;

    @Expose()
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }
}
