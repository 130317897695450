import { GeneratedAvailablePaymentCollectionItemViewModel } from '../generated/view-models/generated-available-payment.collection-item-view-model';

export class AvailablePaymentCollectionItemViewModel extends GeneratedAvailablePaymentCollectionItemViewModel {

    override async postInit(): Promise<void> {
        this.isCashAdvance.isEnabled = false
        this.availablePaymentDescription.isEnabled = false
    }

}
