import {
	InternalViewModel, StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel, 
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel
} from '@nts/std';
import { FoodData } from '../../domain-models/food-data';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';

export class GeneratedFoodDataViewModel extends InternalViewModel<FoodData, ReceiptParamsIdentity> {

	private _id: NumericPropertyViewModel;

	public get id(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
	}

	private _peopleQuantity: NumericPropertyViewModel;

	public get peopleQuantity(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._peopleQuantity = value; }, this._peopleQuantity, 'peopleQuantity');
	}

	private _guestQuantity: NumericPropertyViewModel;

	public get guestQuantity(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._guestQuantity = value; }, this._guestQuantity, 'guestQuantity');
	}

}
