import { BaseLongOpModel, Expose, IdentityTypeDecorator, LongOpParametersTypeDecorator, LongOpResultTypeDecorator, ModelTypeNameDecorator } from "@nts/std";
import { ReceiptLongOpIdentity } from "./receipt-long-op-identity";
import { ReceiptParams } from "./receipt-params";
import { ReceiptResult } from "./receipt-result";



@IdentityTypeDecorator(ReceiptLongOpIdentity)
@ModelTypeNameDecorator('ReceiptLongOp')
@LongOpParametersTypeDecorator(ReceiptParams)
@LongOpResultTypeDecorator(ReceiptResult)
export class ReceiptLongOp extends BaseLongOpModel<ReceiptLongOpIdentity, ReceiptParams, ReceiptResult> {

    private _id: number;

    @Expose()
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }
}
