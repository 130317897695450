import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, NgZone } from '@angular/core';
import '@angular/common/locales/global/it';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StdModule, NtsAppRootModule, EndPointResolver } from '@nts/std';
import { EnvironmentConfiguration } from '@nts/std/src/lib/environments';
import { environment } from 'src/environments/environment';
import { environment as devEnvironment } from 'src/environments/environment.dev';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { unproxiedEnvironmentFactory } from '@nts/std/src/lib/environments';
import { UNPROXIED_ENVIRONMENT } from '@nts/std/src/lib/environments';
import { SharedModule } from './shared/shared.module';
import { OfflineSyncService } from './shared/services/offline-sync.service';
import { ReceiptLongOpApiClient } from './receipt-long-op/api-clients/receipt-long-op.api-client';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './shared/custom-reuse-strategy';
import { RESPONSE_CACHE_SETTINGS_WEBWORKER_FACTORY } from '@nts/std';
import { NtsBootService } from '@nts/std';
import { ResponseCacheWebWorkerSettings } from '@nts/std/src/lib/worker';
import { CreateExpenseAnnotationLongOpApiClient } from './create-expense-annotation-long-op/api-clients/create-expense-annotation-long-op.api-client';
import { filter, take } from 'rxjs';
import { EditExpenseAnnotationLongOpApiClient } from './edit-expense-annotation-long-op/api-clients/edit-expense-annotation-long-op.api-client';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    // StdModule.forRoot(),
    SharedModule.forRoot(),
    StdModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: EnvironmentConfiguration,
      useValue: { ...environment }
    },
    {
      provide: UNPROXIED_ENVIRONMENT,
      useFactory: unproxiedEnvironmentFactory(devEnvironment),
      deps: [EnvironmentConfiguration]
    },
    { provide: LOCALE_ID, useValue: 'it' },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    },
    ...StdModule.getRootProviders(),
    {
      provide: RESPONSE_CACHE_SETTINGS_WEBWORKER_FACTORY,
      useValue: {
        isEnabled: false,
        showLogs: false
      } as ResponseCacheWebWorkerSettings
    },
    EndPointResolver,
  ],
  bootstrap: [AppComponent]
})
export class AppModule extends NtsAppRootModule {

  constructor(
    bootService: NtsBootService,
    offlineSyncService: OfflineSyncService,
    receiptLongOpApiClient: ReceiptLongOpApiClient,
    createExpenseAnnotationLongOpApiClient: CreateExpenseAnnotationLongOpApiClient,
    editExpenseAnnotationLongOpApiClient: EditExpenseAnnotationLongOpApiClient,
  ) {
    super(
      bootService
    );

    this.bootService.authService.initialized$.pipe(filter((isInitialized) => isInitialized), take(1)).subscribe(() => {
      offlineSyncService.init(
        bootService,
        receiptLongOpApiClient,
        createExpenseAnnotationLongOpApiClient,
        editExpenseAnnotationLongOpApiClient,
      )
    })
  }
}
