import { CollectionViewModelTypeDecorator, NNumericPropertyViewModel } from "@nts/std";
import { ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, NumericPropertyViewModel, StringPropertyViewModel } from "@nts/std";
import { ExpenseModel } from "src/app/expense-model/domain-models/expense-model";
import { ExpenseModelIdentity } from "src/app/expense-model/domain-models/expense-model.identity";
import { AvailablePaymentCollectionViewModel } from "src/app/expense-model/view-models/available-payment.collection-view-model";

@IdentityTypeDecorator(ExpenseModelIdentity)
@ExternalDomainModelTypeDecorator(ExpenseModel)
@ExternalDomainModelTypeNameDecorator('ExpenseModel')
export class ExpenseModelExtViewModel extends ExternalViewModel<ExpenseModel, ExpenseModelIdentity> {

    // tslint:disable-next-line: variable-name
    private _modelCode: NNumericPropertyViewModel;

    get modelCode(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._modelCode = value; }, this._modelCode, 'modelCode');
    }

    // tslint:disable-next-line: variable-name
    private _description: StringPropertyViewModel;

    get description(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._description = value; }, this._description, 'description');
    }

    @CollectionViewModelTypeDecorator(AvailablePaymentCollectionViewModel)
    public availablePayments: AvailablePaymentCollectionViewModel;
}
