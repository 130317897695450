import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';

@IdentityTypeNameDecorator('ExpenseTypeIdentity')
export class GeneratedExpenseTypeIdentity extends BaseIdentity {

	private _code: number;

	@Expose()
	public get code(): number {
		return this.getValue<number>(() => this._code, 'code');
	}
	public set code(value: number) {
		this.setValue<number>(() => { this._code = value; }, value, 'code');
	}
}
