import { BaseError, RequiredValidation } from "@nts/std";
import { ValidationArguments } from 'class-validator';

export class ErrorManager {
    public static getRequiredError(
        displayNameKey: string,
        property: string,
        objectName: string,
        objectType: any,
        value: any
    ) {
        const errorObj = new BaseError();

        const requiredValidation = new RequiredValidation<number>();
        const validationArguments: ValidationArguments = {
            value,
            constraints: [{
                displayNameKey
            }],
            targetName: '',
            object: objectType,
            property
        }

        let errorMessage = '';

        if (!requiredValidation.validate(null, validationArguments)) {
            errorMessage = requiredValidation.errorMessage;
        }

        errorObj.propertyName = property;
        errorObj.objectName = objectName;
        errorObj.description = errorMessage;
        errorObj.code = 'VALIDATION_ERROR_isDefined';
        return errorObj;
    }

    public static getCustomError(
        property: string,
        objectName: string,
        errorMessage: string,
        errorCode: string
    ) {
        const errorObj = new BaseError();
        errorObj.propertyName = property;
        errorObj.objectName = objectName;
        errorObj.description = errorMessage;
        errorObj.code = errorCode;
        return errorObj;
    }
}
