import { Expose, Type } from "@nts/std"
import { AnnotationState } from "src/app/expense-annotation/generated/domain-models/enums/generated-annotation-state";
import { ExpenseItem } from "../expense-item";

export class SendExpenseAnnotationToAdministrationResultDto {
    /**
     * Lo stato della ExpenseAnnotation dopo l'operazione di invio in ammininstrazione
     */
    @Expose()
    public annotationState: AnnotationState;

    /**
     * Le ExpenseItem che fanno parte della ExpenseAnnotation inviata in amministrazione
     */
    @Expose()
    @Type(() => ExpenseItem)
    public expenseItems: ExpenseItem[];

    /**
     * Flag che rispecchia ExpenseAnnotationAlert.HasAlert dopo aver inviato la nota spese in amministrazione
     */
    @Expose()
    public hasAlert: boolean
}
