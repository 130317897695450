import { Injectable } from "@angular/core";
import { ParamMap, Params } from "@angular/router";
import { BehaviorSubject, map, Observable } from "rxjs";

@Injectable()
export class RouteStateService {
    private paramMap: BehaviorSubject<ParamMap> = new BehaviorSubject<ParamMap>(null);
    private queryParamsMap: BehaviorSubject<ParamMap> = new BehaviorSubject<ParamMap>(null);

    params: Observable<ParamMap>;
    queryParams: Observable<Params>;

    constructor() {
        this.params = this.paramMap.asObservable();
        this.queryParams = this.queryParamsMap.asObservable().pipe(map((qpm) => {
            return [...qpm.keys].reduce((obj, key) => {
                obj[key] = qpm.get(key)
                return obj
            }, {})
        }));
    }

    updateState(paramMap?: ParamMap, queryParamsMap?: ParamMap) {
        if (paramMap) {
            this.paramMap.next(paramMap);
        }

        if (queryParamsMap) {
            this.queryParamsMap.next(queryParamsMap);
        }
    }
}
