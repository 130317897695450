import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActivatedRoute } from '@angular/router';
import { ViewModelAwareInterface } from 'src/app/shared/models/view-model-aware.interface';
import { RouteStateService } from 'src/app/shared/services/route-state.service';
import { ReceiptStepsService } from 'src/app/receipt-long-op/services/receipt-steps.service';
import { OtherDataViewModel } from 'src/app/receipt-long-op/view-models/other-data.view-model';
import { firstValueFrom } from 'rxjs';
import { ExpandableComponent, LabelledTextBoxComponent, MessageResourceManager, NTSTranslatePipe, ToastMessage, ToastMessageType } from '@nts/std';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'nts-other-data-step',
  templateUrl: './other-data-step.component.html',
  styleUrls: ['./other-data-step.component.scss'],
  standalone: true,
  imports: [
    NTSTranslatePipe,
    SvgIconComponent,
    ExpandableComponent,
    LabelledTextBoxComponent,
    NgIf
  ]
})
export class OtherDataStepComponent implements ViewModelAwareInterface {

  viewModel: OtherDataViewModel;
  inIframe = false;

  constructor(
    private readonly receiptStepsService: ReceiptStepsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routeStateService: RouteStateService,
    private readonly telemetryService: TelemetryService
  ) {
    this.activatedRoute.paramMap.pipe(untilDestroyed(this)).subscribe((paramMap) => {
      this.routeStateService.updateState(paramMap)
    })

    this.activatedRoute.queryParamMap.pipe(untilDestroyed(this)).subscribe((queryParamMap) => {
      this.routeStateService.updateState(null, queryParamMap)
    })
  }

  async onViewModelLoaded() {

    this.receiptStepsService.stepLoading.next(true);

    const viewModel = this.receiptStepsService.getCurrentState() as OtherDataViewModel;

    const rootViewModel = this.receiptStepsService.orchestratorViewModel.rootViewModel;

    const queryParams = await firstValueFrom(this.routeStateService.queryParams);
    const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);
    const remoteExpense = queryParams['remoteExpense'] === 'true';

    viewModel.initPresentationLogic(
      expenseAnnotationId,
      remoteExpense,
      rootViewModel.params.expenseSelection.expenseClassification.value,
      rootViewModel.params.expenseState
    );

    this.receiptStepsService.orchestratorViewModel.rootViewModelModified.pipe(untilDestroyed(this)).subscribe(() => {
      viewModel.validateStep();
    });

    viewModel.validateStep();

    this.inIframe = this.activatedRoute.snapshot.queryParams['iframe'] === 'true';

    const params = await firstValueFrom(this.routeStateService.params);
    const currentAction = params.get('action');

    if (currentAction === 'add' && viewModel.isValid.value === true) {
      if (!this.receiptStepsService.orchestratorViewModel.errorDuringAdd) {
        this.receiptStepsService.nextStep();
      } else {
        this.receiptStepsService.stepLoading.next(false);
      }
    } else if (currentAction === 'add' && viewModel.isValid.value === false) {
      const toastMessage: ToastMessage = {
        message: MessageResourceManager.Current.getMessage('MissinigDataInStep'),
        type: ToastMessageType.warn,
        title: MessageResourceManager.Current.getMessage('std_Warning')
      };
      this.receiptStepsService.orchestratorViewModel.toastMessageService.showToast(toastMessage);
      this.receiptStepsService.orchestratorViewModel.errorDuringAdd = true;
      this.receiptStepsService.stepLoading.next(false);
    } else {
      this.receiptStepsService.stepLoading.next(false);
    }

    this.viewModel = viewModel;

    const customTitle = viewModel.newLabels().value[viewModel.currentExpenseClassificationType];
    const title = customTitle ?? MessageResourceManager.Current.getMessage('ExpenseClassification_' + viewModel.expenseClassificationEnum[viewModel.currentExpenseClassificationType]);
    this.telemetryService.trackPageView({ name: title });
  };

  async beforeNextAction(): Promise<void> {
  }
}

