import { ExternalViewModelTypeDecorator, NNumericPropertyViewModel } from "@nts/std";
import { ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, NumericPropertyViewModel, StringPropertyViewModel } from "@nts/std";
import { Commission } from "src/app/external-remote/commission/commission";
import { CommissionIdentity } from "src/app/external-remote/commission/commission.identity";
import { CustomerExtViewModel } from "./customer.ext-view-model";

@IdentityTypeDecorator(CommissionIdentity)
@ExternalDomainModelTypeDecorator(Commission)
@ExternalDomainModelTypeNameDecorator('Commission')
export class CommissionExtViewModel extends ExternalViewModel<Commission, CommissionIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: NumericPropertyViewModel;

    get id(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }

    private _companyId: NumericPropertyViewModel;

    get companyId(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._companyId = value; }, this._companyId, 'companyId');
    }

    // tslint:disable-next-line: variable-name
    private _description: StringPropertyViewModel;

    get description(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._description = value; }, this._description, 'description');
    }

    private _customerId: NNumericPropertyViewModel;

    public get customerId(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._customerId = value; }, this._customerId, 'customerId');
    }

    private _customerCompanyId: NNumericPropertyViewModel;

    public get customerCompanyId(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._customerCompanyId = value; }, this._customerCompanyId, 'customerCompanyId');
    }

    @ExternalViewModelTypeDecorator(CustomerExtViewModel)
    public customerRef: CustomerExtViewModel;

}
