import { Expose, Type } from "@nts/std"
import { ExpenseAnnotationIdentity } from "src/app/expense-annotation/domain-models/expense-annotation.identity"
import { CommissionIdentity } from "src/app/external-remote/commission/commission.identity";
import { GeneralSubjectIdentity } from "src/app/external-remote/general-subject/general-subject.identity";
import { LeadIdentity } from "src/app/external-remote/lead/lead.identity";

export class RemoveExpenseResultDto {

    @Expose()
    hasAlert: boolean;
}
