import {
	InternalViewModel, StringPropertyViewModel, NumericPropertyViewModel, DateTimePropertyViewModel,
	CollectionViewModelTypeDecorator,
	ExternalViewModelTypeDecorator,
	NNumericPropertyViewModel,
	NDateTimeOffsetPropertyViewModel
} from '@nts/std';
import { ExpenseData } from '../../domain-models/expense-data';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';
import { ExpenseFileCollectionViewModel } from '../../view-models/expense-file.collection-view-model';
import { ExpenseTypeExtViewModel } from '../../view-models/expense-type.ext-view-model';

export class GeneratedExpenseDataViewModel extends InternalViewModel<ExpenseData, ReceiptParamsIdentity> {

	private _id: NumericPropertyViewModel;

	public get id(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
	}

	private _expenseDate: NDateTimeOffsetPropertyViewModel;

	public get expenseDate(): NDateTimeOffsetPropertyViewModel {
		return this.getNDateTimeOffsetPropertyViewModel((value) => { this._expenseDate = value; }, this._expenseDate, 'expenseDate');
	}

	private _expenseTypeCode: NNumericPropertyViewModel;

	public get expenseTypeCode(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._expenseTypeCode = value; }, this._expenseTypeCode, 'expenseTypeCode');
	}

	@ExternalViewModelTypeDecorator(ExpenseTypeExtViewModel)
	public expenseTypeRef: ExpenseTypeExtViewModel;

	private _expenseTotalAmount: NumericPropertyViewModel;

	public get expenseTotalAmount(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._expenseTotalAmount = value; }, this._expenseTotalAmount, 'expenseTotalAmount');
	}

	private _description: StringPropertyViewModel;

	public get description(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._description = value; }, this._description, 'description');
	}

	private _note: StringPropertyViewModel;

	public get note(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._note = value; }, this._note, 'note');
	}

	@CollectionViewModelTypeDecorator(ExpenseFileCollectionViewModel)
	public files: ExpenseFileCollectionViewModel;

	private _expenseTypeDescription: StringPropertyViewModel;

	public get expenseTypeDescription(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._expenseTypeDescription = value; }, this._expenseTypeDescription, 'expenseTypeDescription');
	}

	private _leadId: NNumericPropertyViewModel;

	public get leadId(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._leadId = value; }, this._leadId, 'leadId');
	}

	private _leadCompanyId: NNumericPropertyViewModel;

	public get leadCompanyId(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._leadCompanyId = value; }, this._leadCompanyId, 'leadCompanyId');
	}

	private _customerId: NNumericPropertyViewModel;

	public get customerId(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._customerId = value; }, this._customerId, 'customerId');
	}

	private _customerCompanyId: NNumericPropertyViewModel;

	public get customerCompanyId(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._customerCompanyId = value; }, this._customerCompanyId, 'customerCompanyId');
	}

	private _commissionId: NNumericPropertyViewModel;

	public get commissionId(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._commissionId = value; }, this._commissionId, 'commissionId');
	}

	private _commissionCompanyId: NNumericPropertyViewModel;

	public get commissionCompanyId(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._commissionCompanyId = value; }, this._commissionCompanyId, 'commissionCompanyId');
	}

}
