import { EnumPropertyViewModel, MessageResourceManager, SourceMessage } from '@nts/std';
import { BehaviorSubject } from 'rxjs';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { StepViewModelAwareInterface } from 'src/app/shared/models/step-view-model-aware.interface';
import { ValidCheckInterface } from 'src/app/shared/models/valid-check.interface';
import { ColorUtility } from 'src/app/shared/services/color-utility';
import { ExpenseClassificationMapper } from 'src/app/shared/services/expense-classification-mapper';
import { ExpenseStateMapper } from 'src/app/shared/services/expense-state-mapper';
import { ExpenseClassification } from '../generated/domain-models/enums/generated-expense-classification';
import { GeneratedMileageRefoundDataViewModel } from '../generated/view-models/generated-mileage-refound-data.view-model';
import { ErrorManager } from '../services/errorr-manager.service';
import { ReceiptParamsViewModel } from './receipt-params.view-model';

export class MileageRefoundDataViewModel extends GeneratedMileageRefoundDataViewModel implements StepViewModelAwareInterface {

    currentColor = null;
    currentActiveColor = null;
    currentHoverColor = null;
    currentExpenseClassificationType: ExpenseClassification;
    expenseClassificationEnum = ExpenseClassification;
    isValid = new BehaviorSubject<boolean>(false);
    hasActionsButtons = new BehaviorSubject<boolean>(true);
    isPreviousDisabled = new BehaviorSubject<boolean>(false);
    hideNext = new BehaviorSubject<boolean>(false);
    expenseState: EnumPropertyViewModel;
    expenseStateMapper = ExpenseStateMapper;
    showCurrentState: boolean;

    async initPresentationLogic(
        expenseAnnotationId: number,
        remoteExpense: boolean,
        expenseClassificationType: ExpenseClassification,
        expenseState: EnumPropertyViewModel
    ) {
        this.showCurrentState = expenseAnnotationId > 0 && remoteExpense === true;
        this.expenseState = expenseState;
        this.currentExpenseClassificationType = expenseClassificationType;
        this.currentColor = ColorUtility.shade(ExpenseClassificationMapper.getColorByClassificationType(expenseClassificationType), -0.1);
        this.currentActiveColor = ColorUtility.shade(this.currentColor, -0.1)
        this.currentHoverColor = ColorUtility.shade(this.currentColor, -0.3)

        // const originalLabel = 'ExpenseClassification_' + this.expenseClassificationEnum[this.currentExpenseClassificationType];
        const newLabel: string = (this.parent as ReceiptParamsViewModel).newLabels$.value[this.currentExpenseClassificationType];
        if (newLabel && newLabel.length != 0 && this.currentExpenseClassificationType == ExpenseClassification.MileageRefound) {
            this.distance.metadataDescription = MessageResourceManager.Current.getMessage('MileageRefoundData_Distance_Alias');
            this.distance.metadataShortDescription = MessageResourceManager.Current.getMessage('MileageRefoundData_Distance_Alias');
        }


        const isEnabled = this.expenseState.value === ExpenseState.WebEditing || this.expenseState.value === ExpenseState.IntegrateDocumentation;
        if (!isEnabled) {
            this.distance.isEnabled = isEnabled;
            this.unitPrice.isEnabled = isEnabled;
        }

        this.distance.defaultColor = this.currentColor;
        this.distance.activeColor = this.currentActiveColor;
        this.distance.hoverColor = this.currentHoverColor;

        this.unitPrice.defaultColor = this.currentColor;
        this.unitPrice.activeColor = this.currentActiveColor;
        this.unitPrice.hoverColor = this.currentHoverColor;
    }

    newLabels(): BehaviorSubject<any[]> {
        return (this.parent as ReceiptParamsViewModel).newLabels$;
    }

    async validateStep(): Promise<void> {
        this.validate();
        if (this.distance.isEnabled && (this.distance?.value <= 0 || this.distance == undefined)) {
            const errorObj = ErrorManager.getCustomError(
                this.distance.metadataDescription,
                'distance',
                MessageResourceManager.Current.getMessage("DistanceRequired"),
                "MIN_VALUE_ERROR",
            )
            this.distance.addError(SourceMessage.ViewModel, errorObj)
        }
        this.isValid.next(!this.hasErrors)
    };
}
