import { RECEIPT_STEPS } from "../receipt-steps.service";
import { BaseState } from "./base-state";
import { ExpenseDataState } from "./expense-data-state";
import { StepStateInterface } from "./step-state.interface";

export class MileageRefoundDataState extends BaseState implements StepStateInterface {
    classType = MileageRefoundDataState;
    property = 'mileageRefoundData';
    slug = RECEIPT_STEPS.mileageRefoundData;
    lastStep = true;
    async previous(context: any): Promise<StepStateInterface> {
        return new ExpenseDataState();
    }
}
