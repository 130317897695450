import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel, DateTimeOffset, Transform
} from '@nts/std';
import { PersonalPaymentIdentity } from '../../domain-models/personal-payment.identity';
import { PersonalPaymentBusinessData } from '../../domain-models/personal-payment-business-data';
import { PersonalPayments } from './enums/generated-personal-payments';

@IdentityTypeDecorator(PersonalPaymentIdentity)
@ModelTypeNameDecorator('PersonalPayment')
export class GeneratedPersonalPayment extends CoreModel<PersonalPaymentIdentity> {

	private _userId: number;

	/**
	 Id utente
	 */
	@Expose()
	public get userId(): number {
		return this.getValueForIdentityValue<number>(() => this._userId, 'userId');
	}
	public set userId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._userId = value; }, value, 'userId');
	}

	private _personalPaymentId: number = 0;

	/**
	 Id pagamento personale
	 */
	@Expose()
	public get personalPaymentId(): number {
		return this.getValueForIdentityValue<number>(() => this._personalPaymentId, 'personalPaymentId');
	}
	public set personalPaymentId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._personalPaymentId = value; }, value, 'personalPaymentId');
	}

	private _personalPaymentType: PersonalPayments;

	/**
	 Tipo pagamento personale
	 */
	@Expose()
	public get personalPaymentType(): PersonalPayments {
		return this.getValue<PersonalPayments>(() => this._personalPaymentType, 'personalPaymentType');
	}
	public set personalPaymentType(value: PersonalPayments) {
		this.setValue<PersonalPayments>(() => { this._personalPaymentType = value; }, value, 'personalPaymentType');
	}

	private _description: string;

	/**
	 Descrizione
	 */
	@Expose()
	public get description(): string {
		return this.getValue<string>(() => this._description, 'description');
	}
	public set description(value: string) {
		this.setValue<string>(() => { this._description = value; }, value, 'description');
	}

	private _startDate: DateTimeOffset;

	/**
	 Data inizio
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('startDate', value, obj, type);
	})
	public get startDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._startDate, 'startDate');
	}
	public set startDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._startDate = value; }, value, 'startDate');
	}

	private _expireDate: DateTimeOffset;

	/**
	 Data scadenza
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('expireDate', value, obj, type);
	})
	public get expireDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._expireDate, 'expireDate');
	}
	public set expireDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._expireDate = value; }, value, 'expireDate');
	}

	private _expenseModelNCTCode: number;

	/**
	 Codice modello di origine
	 */
	@Expose()
	public get expenseModelNCTCode(): number {
		return this.getValue<number>(() => this._expenseModelNCTCode, 'expenseModelNCTCode');
	}
	public set expenseModelNCTCode(value: number) {
		this.setValue<number>(() => { this._expenseModelNCTCode = value; }, value, 'expenseModelNCTCode');
	}

	private _businessData: PersonalPaymentBusinessData;

	/**
	 Dati business
	 */
	@Expose()
	@Type(() => PersonalPaymentBusinessData)
	public get businessData(): PersonalPaymentBusinessData {
		return this.getValueForInternal<PersonalPaymentBusinessData>(
			(value) => {
				this._businessData = value;
			},
			this._businessData,
			'businessData',
			PersonalPaymentBusinessData
		);
	}
	public set businessData(value: PersonalPaymentBusinessData) {
		this.setValueForInternal<PersonalPaymentBusinessData>(() => { this._businessData = value; }, value, 'businessData');
	}

	private _companyId: number;

	@Expose()
	public get companyId(): number {
		return this.getValue<number>(() => this._companyId, 'companyId');
	}
	public set companyId(value: number) {
		this.setValue<number>(() => { this._companyId = value; }, value, 'companyId');
	}
}
