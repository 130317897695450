import { BehaviorSubject } from 'rxjs';
import { GeneratedReceiptParamsViewModel } from '../generated/view-models/generated-receipt-params.view-model';
import { Label } from 'src/app/classification-labels/domain-models/label';

export class ReceiptParamsViewModel extends GeneratedReceiptParamsViewModel {

    newClassificationLabels$: BehaviorSubject<Label[]> = new BehaviorSubject(null);
    newLabels$: BehaviorSubject<any[]> = new BehaviorSubject([]);

}
