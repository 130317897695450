<div class="container" [style.borderColor]="overrideBorderColor ? overrideBorderColor : null"
    [class.is-disabled]="!editable" [class.is-active]="isActive" (mouseover)="mouseover($event)"
    (mouseout)="mouseout($event)" appDnd (fileDropped)="onFileDropped($event)">
    <input (blur)="onBlur($event)" (focus)="onFocus($event)" type="file"
        [attr.disabled]="editable === false ? true : null" #fileDropRef id="fileDropRef" multiple
        (change)="fileBrowseHandler($event)" />
    <div class="drag-text-container">
        <span class="drag-text">{{'MultipleFileUpload_AddFile_DsiplayName' | NTSTranslate}}</span>
        <!-- <h3>{{'MultipleFileUpload_AddFile_DsiplayName' | NTSTranslate}}</h3> -->
        <svg-icon class="drag-icon" fontSize="40px" key="attachment"></svg-icon>
    </div>
</div>
<div class="files-list">
    <div class="single-file"
        [style.borderColor]="overrideBorderColor && file.fileErrors?.length == 0 ? overrideBorderColor : null"
        [class.has-error]="file.fileErrors?.length > 0" *ngFor="let file of files; let i = index">
        <div *ngIf="!file.filePreviewUrl" class="file-icon" (click)="viewFile(file)">
            <svg-icon fontSize="50px" [key]="getKeyFromFile(file)"></svg-icon>
        </div>
        <div *ngIf="file.filePreviewUrl" class="file-image">
            <img class="file-preview-image" (click)="viewFile(file)" [attr.src]="file.filePreviewUrl"
                alt="your image" />
        </div>
        <div class="right-content">
            <div class="info">
                <div class="info-actions" *ngIf="files?.length > 1 && editable">
                    <nts-text-button icon="move-up" [isDisabled]="i == 0" (onClicked)="moveUpFile(i)">
                    </nts-text-button>
                    <nts-text-button icon="move-down" [isDisabled]="i == (files.length - 1)"
                        (onClicked)="moveDownFile(i)"></nts-text-button>
                </div>
                <h4 class="name" [class.has-actions]="files?.length > 1">
                    {{ file?.fileUploadOriginalFileName }}
                </h4>
                <p class="size">
                    {{ file?.fileUploadFileSize ? formatBytes(file?.fileUploadFileSize) : 'N/A Bytes' }}
                </p>
                <app-progress [progress]="file?.progress"></app-progress>
            </div>

            <div class="actions">
                <nts-text-button *ngIf="file.fileErrors.length == 0" label="Visualizza"
                    (onClicked)="viewFile(file)"></nts-text-button>
                <nts-text-button *ngIf="file.fileErrors.length > 0" label="Riprova" class="retry-upload"
                    (onClicked)="retryUpload(file)"></nts-text-button>
                <nts-text-button *ngIf="editable" class="delete" label="Elimina" (onClicked)="deleteFile(i)">
                </nts-text-button>
            </div>
            <div *ngIf="file.fileErrors?.length > 0" class="error-list">
                <span *ngFor="let fileError of file.fileErrors" class="error-span">
                    Errore: {{fileError}}
                </span>
            </div>
        </div>
    </div>
</div>
