import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ReceiptResultIdentity } from '../../domain-models/receipt-result.identity';

@IdentityTypeDecorator(ReceiptResultIdentity)
@ModelTypeNameDecorator('ReceiptResult')
export class GeneratedReceiptResult extends CoreModel<ReceiptResultIdentity> {

	private _id: number = 0;

	/**
	 Id longop
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}
}
