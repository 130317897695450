import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, Transform, DateTimeOffset, TimeSpan, CoreModel
} from '@nts/std';
import { PersonalExpenseIdentity } from '../../domain-models/personal-expense.identity';


@IdentityTypeDecorator(PersonalExpenseIdentity)
@ModelTypeNameDecorator('PersonalExpenseBusinessData')
export class GeneratedPersonalExpenseBusinessData extends CoreModel<PersonalExpenseIdentity> {

	private _personalExpenseId: number = 0;

	/**
	 Id spesa personale
	 */
	@Expose()
	public get personalExpenseId(): number {
		return this.getValueForIdentityValue<number>(() => this._personalExpenseId, 'personalExpenseId');
	}
	public set personalExpenseId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._personalExpenseId = value; }, value, 'personalExpenseId');
	}

	private _userId: number;

	/**
	 Id utente
	 */
	@Expose()
	public get userId(): number {
		return this.getValueForIdentityValue<number>(() => this._userId, 'userId');
	}
	public set userId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._userId = value; }, value, 'userId');
	}

	private _companyId: number = 0;

	/**
	 
	 */
	@Expose()
	public get companyId(): number {
		return this.getValueForIdentityValue<number>(() => this._companyId, 'companyId');
	}
	public set companyId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._companyId = value; }, value, 'companyId');
	}

	private _companyCode: string;

	/**
	 Codice Ditta
	 */
	@Expose()
	public get companyCode(): string {
		return this.getValue<string>(() => this._companyCode, 'companyCode');
	}
	public set companyCode(value: string) {
		this.setValue<string>(() => { this._companyCode = value; }, value, 'companyCode');
	}

	private _businessModelCode: number;

	/**
	 Codice Business
	 */
	@Expose()
	public get businessModelCode(): number {
		return this.getValue<number>(() => this._businessModelCode, 'businessModelCode');
	}
	public set businessModelCode(value: number) {
		this.setValue<number>(() => { this._businessModelCode = value; }, value, 'businessModelCode');
	}

	private _businessRowNumber: number;

	/**
	 
	 */
	@Expose()
	public get businessRowNumber(): number {
		return this.getValue<number>(() => this._businessRowNumber, 'businessRowNumber');
	}
	public set businessRowNumber(value: number) {
		this.setValue<number>(() => { this._businessRowNumber = value; }, value, 'businessRowNumber');
	}
}
