import { Pipe, PipeTransform } from '@angular/core';
import { OfflineReceipt } from 'src/app/shared/models/offline-receipt.interface';

@Pipe({
    name: 'receiptForCompanyFilter',
    pure: false,
    standalone: true
})
export class ReceiptForCompanyFilterPipe implements PipeTransform {

    transform(
        items: Array<OfflineReceipt>,
        companyId: number
    ): Array<any> {

        return items.filter(
            item =>
                // se l'elemento è della stessa company o non ha la company impostata
                (item.companyId === companyId || item.companyId == null)

        );
    }
}
