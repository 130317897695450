import { Expose } from "@nts/std";

export class WeaSettingsDataDto {

    /**
     * Property che vale "true" se il client deve gestire il "Subject.LeadObjects.Models.Lead", altrimenti "false"
     */
    @Expose()
    manageLead: boolean;

    /**
     *  Property che vale "true" se il client deve gestire il "CommissionMS.CommissionObjects.Models.Commission", altrimenti "false"
     */
    @Expose()
    manageCommission: boolean;

    /**
     * Property che vale "true" se il client deve gestire il "Subject.CustomerObjects.Models.Customer", altrimenti "false"
     */
    @Expose()
    manageCustomer: boolean;

    /**
     * Property utilizzata per definire il tempo che deve passare tra una sincronizzazione dati e un'altra lato client.
     */
    @Expose()
    sinchronizationFrequency: number;

}
