import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NTSTranslatePipe } from "@nts/std";
import { ExpenseClassification } from "src/app/receipt-long-op/generated/domain-models/enums/generated-expense-classification";
import { ExpenseClassificationMapper } from "src/app/shared/services/expense-classification-mapper";
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgClass } from "@angular/common";

@Component({
    selector: 'nts-expense-classification-box',
    templateUrl: './expense-classification-box.component.html',
    styleUrls: ['./expense-classification-box.component.scss'],
    standalone: true,
    imports: [
        NTSTranslatePipe,
        SvgIconComponent,
        NgClass
    ]
})
export class ExpenseClassificationBoxComponent implements OnInit {

    @Input() editable = true;
    @Input() item!: ExpenseClassification;
    @Input() selected = false;
    @Input() newLabel = [];
    @Output() onClicked = new EventEmitter<ExpenseClassification>();

    expenseClassificationEnum = ExpenseClassification;
    expenseClassificationKey: string;
    expenseClassificationColor: string;

    constructor() { }

    ngOnInit(): void {
        this.expenseClassificationKey = ExpenseClassificationMapper.getIconByClassificationType(this.item);
        this.expenseClassificationColor = ExpenseClassificationMapper.getColorByClassificationType(this.item);
    }
}
