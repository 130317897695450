import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { ExpenseClassification } from "src/app/receipt-long-op/generated/domain-models/enums/generated-expense-classification";
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NTSTranslatePipe, TextButtonComponent } from "@nts/std";
import { CurrencyPipe, DatePipe, NgIf } from "@angular/common";

@Component({
    selector: 'nts-single-expense',
    templateUrl: './single-expense.component.html',
    styleUrls: ['./single-expense.component.scss'],
    standalone: true,
    imports: [
        SvgIconComponent,
        TextButtonComponent,
        NTSTranslatePipe,
        CurrencyPipe,
        DatePipe,
        NgIf
    ]
})
export class SingleExpenseComponent implements OnInit {

    @Input() icon!: string;
    @Input() type!: ExpenseClassification;
    @Input() totalAmount!: number;
    @Input() date!: Date;
    @Input() notes!: string;
    @Input()
    @HostBinding('class.selection-mode')
    selectionMode!: boolean;
    @Input() currencyIso?: string;

    @Input() newLabels = [];

    @Input()
    @HostBinding('style.background')
    color!: string;

    @Output() onEdit = new EventEmitter<void>();
    @Output() onDelete = new EventEmitter<void>();
    @Output() onSelectionChanged = new EventEmitter<boolean>();

    @HostBinding('class.is-selected')
    isSelected = false;

    expenseTypeEnum = ExpenseClassification;

    constructor() { }

    toggleSelection() {
        this.isSelected = !this.isSelected;
        this.onSelectionChanged.emit(this.isSelected);
    }

    ngOnInit(): void {
    }
}
