
import { IgnoreAutomaticDecoratorWarningDecorator } from '@nts/std';
import {
    IdentityTypeDecorator, OCCModel, ModelTypeNameDecorator, Expose, AllowedChars, NumberDecorator,
    StringDecorator, EnumDecorator
} from '@nts/std';
import { GeneralSubject } from '../general-subject/general-subject';
import { GeneralSubjectIdentity } from '../general-subject/general-subject.identity';


@IdentityTypeDecorator(GeneralSubjectIdentity)
@ModelTypeNameDecorator('Supplier')
@IgnoreAutomaticDecoratorWarningDecorator({
    'BusinessData': 'SubjectBusinessData'
})
export class Supplier extends GeneralSubject {

}
