import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';

@IdentityTypeNameDecorator('ExpenseFileIdentity')
export class GeneratedExpenseFileIdentity extends ReceiptParamsIdentity {

	private _serial: number;

	@Expose()
	public get serial(): number {
		return this.getValue<number>(() => this._serial, 'serial');
	}
	public set serial(value: number) {
		this.setValue<number>(() => { this._serial = value; }, value, 'serial');
	}
}
