<div class="refound-type-box" [class.is-disabled]="!editable" [class.is-selected]="selected"
    [style.backgroundColor]="expenseClassificationColor" (click)="this.editable ? this.onClicked.emit(this.item) : null"
    [ngClass]="expenseClassificationEnum[this.item]">
    <div class="selected-icon" [class.is-selected]="selected">
        <svg-icon fontSize="35px" key="check"></svg-icon>
    </div>
    <div class="refound-type-logo">
        <svg-icon fontSize="100px" [key]="expenseClassificationKey"></svg-icon>
    </div>
    <div class="refound-type-detail">{{newLabel[this.item] ?? (('ExpenseClassification_' +
        expenseClassificationEnum[this.item]) |
        NTSTranslate)}}
    </div>
</div>
