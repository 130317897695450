import { AutoCompleteExternalOptions, NNumericPropertyViewModel } from "@nts/std";
import { OrderBy } from "@nts/std";
import { OrderByType } from "@nts/std";
import { EnumPropertyViewModel, ExternalDomainModelTypeDecorator, ExternalDomainModelTypeNameDecorator, ExternalViewModel, IdentityTypeDecorator, NumericPropertyViewModel, StringPropertyViewModel } from "@nts/std";
import { ExpenseType } from "src/app/expense-type/domain-models/expense-type";
import { ExpenseTypeIdentity } from "src/app/expense-type/domain-models/expense-type.identity";

@IdentityTypeDecorator(ExpenseTypeIdentity)
@ExternalDomainModelTypeDecorator(ExpenseType)
@ExternalDomainModelTypeNameDecorator('ExpenseType')
export class ExpenseTypeExtViewModel extends ExternalViewModel<ExpenseType, ExpenseTypeIdentity> {

    // tslint:disable-next-line: variable-name
    private _code: NNumericPropertyViewModel;

    get code(): NNumericPropertyViewModel {
        return this.getNNumericPropertyViewModel((value) => { this._code = value; }, this._code, 'code');
    }

    // tslint:disable-next-line: variable-name
    private _description: StringPropertyViewModel;

    get description(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._description = value; }, this._description, 'description');
    }

    private _refoundClass: EnumPropertyViewModel;

    get refoundClass(): EnumPropertyViewModel {
        return this.getEnumPropertyViewModel((value) => { this._refoundClass = value; }, this._refoundClass, 'refoundClass');
    }

    override async postInit() {

        this.zoomAddIsEnabled = false;
        this.zoomAddIsVisible = false;
        this.zoomSearchIsEnabled = false;
        this.zoomSearchIsVisible = false;
        this.zoomViewIsEnabled = false;
        this.zoomViewIsVisible = false;

        this.autoCompleteOptions = new AutoCompleteExternalOptions();
        this.autoCompleteOptions.propertySearchList = ['Code', 'Description'];
        this.autoCompleteOptions.orderByPropertyNames = [
            new OrderBy({ propertyName: 'Description', sortType: OrderByType.Ascending })
        ];
        this.autoCompleteOptions.outputProperties = ['Code', 'Description', 'RefoundClass'];
    }
}
