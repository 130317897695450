import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';

@IdentityTypeDecorator(ReceiptParamsIdentity)
@ModelTypeNameDecorator('PaymentData')
export class GeneratedPaymentData extends CoreModel<ReceiptParamsIdentity> {

	private _id: number = null;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _reliableSupplier: boolean;

	/**
	 Fornitore conosciuto
	 */
	@Expose()
	public get reliableSupplier(): boolean {
		return this.getValue<boolean>(() => this._reliableSupplier, 'reliableSupplier');
	}
	public set reliableSupplier(value: boolean) {
		this.setValue<boolean>(() => { this._reliableSupplier = value; }, value, 'reliableSupplier');
	}

	private _supplierId: number;

	/**
	 Id fornitore
	 */
	@Expose()
	public get supplierId(): number {
		return this.getValue<number>(() => this._supplierId, 'supplierId');
	}
	public set supplierId(value: number) {
		this.setValue<number>(() => { this._supplierId = value; }, value, 'supplierId');
	}

	private _supplierCompanyId: number;

	/**
	 Codice ditta fornitore
	 */
	@Expose()
	public get supplierCompanyId(): number {
		return this.getValue<number>(() => this._supplierCompanyId, 'supplierCompanyId');
	}
	public set supplierCompanyId(value: number) {
		this.setValue<number>(() => { this._supplierCompanyId = value; }, value, 'supplierCompanyId');
	}

	private _businessName: string;

	/**
	 Ragione sociale
	 */
	@Expose()
	public get businessName(): string {
		return this.getValue<string>(() => this._businessName, 'businessName');
	}
	public set businessName(value: string) {
		this.setValue<string>(() => { this._businessName = value; }, value, 'businessName');
	}

	private _vatNumber: string;

	/**
	 Partita iva
	 */
	@Expose()
	public get vatNumber(): string {
		return this.getValue<string>(() => this._vatNumber, 'vatNumber');
	}
	public set vatNumber(value: string) {
		this.setValue<string>(() => { this._vatNumber = value; }, value, 'vatNumber');
	}

	private _paymentTypeDescription: string;

	/**
	 Tipo pagamento
	 */
	@Expose()
	public get paymentTypeDescription(): string {
		return this.getValue<string>(() => this._paymentTypeDescription, 'paymentTypeDescription');
	}
	public set paymentTypeDescription(value: string) {
		this.setValue<string>(() => { this._paymentTypeDescription = value; }, value, 'paymentTypeDescription');
	}
}
