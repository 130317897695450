import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ExpenseModelIdentity } from '../../domain-models/expense-model.identity';
import { ExpenseModelBusinessData } from '../../domain-models/espense-model-business-data';
import { AvailablePaymentCollection } from '../../domain-models/available-payment.collection';
import { AvailablePayment } from '../../domain-models/available-payment';
import { AvailableExpenseCollection } from '../../domain-models/available-expense.collection';
import { AvailableExpense } from '../../domain-models/available-expense';

@IdentityTypeDecorator(ExpenseModelIdentity)
@ModelTypeNameDecorator('ExpenseModel')
export class GeneratedExpenseModel extends CoreModel<ExpenseModelIdentity> {

	private _modelCode: number = 0;

	/**
	 Codice Modello spesa
	 */
	@Expose()
	public get modelCode(): number {
		return this.getValueForIdentityValue<number>(() => this._modelCode, 'modelCode');
	}
	public set modelCode(value: number) {
		this.setValueForIdentityValue<number>(() => { this._modelCode = value; }, value, 'modelCode');
	}

	private _description: string;

	/**
	 Descrizione modello spesa
	 */
	@Expose()
	public get description(): string {
		return this.getValue<string>(() => this._description, 'description');
	}
	public set description(value: string) {
		this.setValue<string>(() => { this._description = value; }, value, 'description');
	}

	private _availablePayments: AvailablePaymentCollection;

	/**
	 Tipologie pagamenti
	 */
	@Expose()
	@Type(() => AvailablePaymentCollection)
	public get availablePayments(): AvailablePaymentCollection {
		return this.getValueForInternalCollection<AvailablePaymentCollection, AvailablePayment>(
			(value) => {
				this._availablePayments = value;
			},
			this._availablePayments,
			'availablePayments',
			AvailablePaymentCollection
		);
	}
	public set availablePayments(value: AvailablePaymentCollection) {
		this.setValueForInternalCollection<AvailablePaymentCollection, AvailablePayment>(() => { this._availablePayments = value; }, value, 'availablePayments');
	}

	private _availableExpenses: AvailableExpenseCollection;

	/**
	 Tipologie spese ammesse
	 */
	@Expose()
	@Type(() => AvailableExpenseCollection)
	public get availableExpenses(): AvailableExpenseCollection {
		return this.getValueForInternalCollection<AvailableExpenseCollection, AvailableExpense>(
			(value) => {
				this._availableExpenses = value;
			},
			this._availableExpenses,
			'availableExpenses',
			AvailableExpenseCollection
		);
	}
	public set availableExpenses(value: AvailableExpenseCollection) {
		this.setValueForInternalCollection<AvailableExpenseCollection, AvailableExpense>(() => { this._availableExpenses = value; }, value, 'availableExpenses');
	}

	private _businessData: ExpenseModelBusinessData;

	/**
	 Dati Business
	 */
	@Expose()
	@Type(() => ExpenseModelBusinessData)
	public get businessData(): ExpenseModelBusinessData {
		return this.getValueForInternal<ExpenseModelBusinessData>(
			(value) => {
				this._businessData = value;
			},
			this._businessData,
			'businessData',
			ExpenseModelBusinessData
		);
	}
	public set businessData(value: ExpenseModelBusinessData) {
		this.setValueForInternal<ExpenseModelBusinessData>(() => { this._businessData = value; }, value, 'businessData');
	}

	private _companyId: number = 0;

	/**

	 */
	@Expose()
	public get companyId(): number {
		return this.getValue<number>(() => this._companyId, 'companyId');
	}
	public set companyId(value: number) {
		this.setValue<number>(() => { this._companyId = value; }, value, 'companyId');
	}
}
