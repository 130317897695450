import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { CreateExpenseAnnotationResultIdentity } from '../../domain-models/create-expense-annotation-result.identity';

@IdentityTypeDecorator(CreateExpenseAnnotationResultIdentity)
@ModelTypeNameDecorator('CreateExpenseAnnotationResult')
export class GeneratedCreateExpenseAnnotationResult extends CoreModel<CreateExpenseAnnotationResultIdentity> {

	private _id: number = 0;

	/**
	 Id longop
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _expenseAnnotationId: number = 0;

	/**
	 Id nota spese
	 */
	@Expose()
	public get expenseAnnotationId(): number {
		return this.getValue<number>(() => this._expenseAnnotationId, 'expenseAnnotationId');
	}
	public set expenseAnnotationId(value: number) {
		this.setValue<number>(() => { this._expenseAnnotationId = value; }, value, 'expenseAnnotationId');
	}
}
