import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';
import { ExpenseClassification } from './enums/generated-expense-classification';

@IdentityTypeDecorator(ReceiptParamsIdentity)
@ModelTypeNameDecorator('ExpenseSelection')
export class GeneratedExpenseSelection extends CoreModel<ReceiptParamsIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _expenseClassification: ExpenseClassification;

	/**
	 Tipo spesa
	 */
	@Expose()
	public get expenseClassification(): ExpenseClassification {
		return this.getValue<ExpenseClassification>(() => this._expenseClassification, 'expenseClassification');
	}
	public set expenseClassification(value: ExpenseClassification) {
		this.setValue<ExpenseClassification>(() => { this._expenseClassification = value; }, value, 'expenseClassification');
	}
}
