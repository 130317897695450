import { Expose, ExternalDecorator, ModelPropertyListDecorator, Type } from '@nts/std';
import { GeneratedExpenseData } from '../generated/domain-models/generated-expense-data';
import { Lead } from 'src/app/external-remote/lead/lead';
import { LeadIdentity } from 'src/app/external-remote/lead/lead.identity';
import { Customer } from 'src/app/external-remote/customer/customer';
import { GeneralSubjectIdentity } from 'src/app/external-remote/general-subject/general-subject.identity';
import { Commission } from 'src/app/external-remote/commission/commission';
import { CommissionIdentity } from 'src/app/external-remote/commission/commission.identity';
import { ExtendedAvailableExpense } from './extended-available-expense';
import { ExtendedAvailableExpenseIdentity } from './extended-available-expense.identity';

@ModelPropertyListDecorator([
    'id',
    'expenseDate',
    'expenseTypeCode',
    'expenseTypeRef',
    'expenseTotalAmount',
    'description',
    'note',
    'files',
    'expenseTypeDescription',
    'leadId',
    'leadRef',
    'customerId',
    'customerRef',
    'commissionId',
    'commissionRef',
])
export class ExpenseData extends GeneratedExpenseData {

    private _leadRef: Lead;

    /**
     Lead spesa
     */
    @Expose()
    @Type(() => Lead)
    @ExternalDecorator({
    })
    public get leadRef(): Lead {
        return this.getValueForExternal<Lead>(
            () => this._leadRef,
            'leadRef',
            Lead
        );
    }
    public set leadRef(value: Lead) {
        this.setValueForExternal<Lead, LeadIdentity>(() => { this._leadRef = value; }, value, 'leadRef');
    }

    private _customerRef: Customer;

    /**
     Cliente spesa
     */
    @Expose()
    @Type(() => Customer)
    @ExternalDecorator({
    })
    public get customerRef(): Customer {
        return this.getValueForExternal<Customer>(
            () => this._customerRef,
            'customerRef',
            Customer
        );
    }
    public set customerRef(value: Customer) {
        this.setValueForExternal<Customer, GeneralSubjectIdentity>(() => { this._customerRef = value; }, value, 'customerRef');
    }

    private _commissionRef: Commission;

    /**
     Commessa spesa
     */
    @Expose()
    @Type(() => Commission)
    @ExternalDecorator({
    })
    public get commissionRef(): Commission {
        return this.getValueForExternal<Commission>(
            () => this._commissionRef,
            'commissionRef',
            Commission
        );
    }
    public set commissionRef(value: Commission) {
        this.setValueForExternal<Commission, CommissionIdentity>(() => { this._commissionRef = value; }, value, 'commissionRef');
    }

    private _extendedExpenseTypeId: number;

    /**
     Id tipo spesa
     */
    @Expose()
    get extendedExpenseTypeId(): number {
        return this.getValue<number>(() => this._extendedExpenseTypeId, 'extendedExpenseTypeId');
    }
    set extendedExpenseTypeId(value: number) {
        this.setValue<number>(() => { this._extendedExpenseTypeId = value; }, value, 'extendedExpenseTypeId');
    }

    private _extendedExpenseTypeRef: ExtendedAvailableExpense;

    //l'oldExpenseAbsoluteId rappresenta l'id "assoluto" del pagamento selezionato di una spesa; in pratica questo id viene usato quando
    //apriamo una spesa in modifica(non offline) e vogliamo salvare il pagamento originale della spesa, per permetterci di selezionarlo anche
    //quando non sarebbe valido. è absolute perchè sfrutta la posizione che il pagamento otterrebbe nella lista di ExtendedAvailablePayment
    //dell'ultimo step della ReceiptLongOp.
    oldExpenseAbsoluteId: number = null;

    /**
     Tipo pagamento
     */
    @Expose()
    @Type(() => ExtendedAvailableExpense)
    public get extendedExpenseTypeRef(): ExtendedAvailableExpense {
        return this.getValueForExternal<ExtendedAvailableExpense>(
            () => this._extendedExpenseTypeRef,
            'extendedExpenseTypeRef',
            ExtendedAvailableExpense
        );
    }
    public set extendedExpenseTypeRef(value: ExtendedAvailableExpense) {
        this.setValueForExternal<ExtendedAvailableExpense, ExtendedAvailableExpenseIdentity>(() => { this._extendedExpenseTypeRef = value; }, value, 'extendedExpenseTypeRef');
    }
}
