import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { AvailablePaymentIdentity } from '../../domain-models/available-payment.identity';
import { BusinessPaymentTypes } from './enums/generated-business-payment-types';

@IdentityTypeDecorator(AvailablePaymentIdentity)
@ModelTypeNameDecorator('AvailablePaymentBusinessData')
export class GeneratedAvailablePaymentBusinessData extends CoreModel<AvailablePaymentIdentity> {

	private _availablePaymentId: number = 0;

	/**
	 Id pagamento ammesso
	 */
	@Expose()
	public get availablePaymentId(): number {
		return this.getValueForIdentityValue<number>(() => this._availablePaymentId, 'availablePaymentId');
	}
	public set availablePaymentId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._availablePaymentId = value; }, value, 'availablePaymentId');
	}

	private _modelCode: number = 0;

	/**
	 Codice Modello spesa
	 */
	@Expose()
	public get modelCode(): number {
		return this.getValueForIdentityValue<number>(() => this._modelCode, 'modelCode');
	}
	public set modelCode(value: number) {
		this.setValueForIdentityValue<number>(() => { this._modelCode = value; }, value, 'modelCode');
	}

	private _businessPaymentType: BusinessPaymentTypes;

	/**

	 */
	@Expose()
	public get businessPaymentType(): BusinessPaymentTypes {
		return this.getValue<BusinessPaymentTypes>(() => this._businessPaymentType, 'businessPaymentType');
	}
	public set businessPaymentType(value: BusinessPaymentTypes) {
		this.setValue<BusinessPaymentTypes>(() => { this._businessPaymentType = value; }, value, 'businessPaymentType');
	}

	private _companyCode: string;

	/**
	 Codice Ditta
	 */
	@Expose()
	public get companyCode(): string {
		return this.getValue<string>(() => this._companyCode, 'companyCode');
	}
	public set companyCode(value: string) {
		this.setValue<string>(() => { this._companyCode = value; }, value, 'companyCode');
	}

	private _businessModelCode: number = 0;

	/**
	 Codice Business
	 */
	@Expose()
	public get businessModelCode(): number {
		return this.getValue<number>(() => this._businessModelCode, 'businessModelCode');
	}
	public set businessModelCode(value: number) {
		this.setValue<number>(() => { this._businessModelCode = value; }, value, 'businessModelCode');
	}

	private _businessRowNumber: number;

	/**

	 */
	@Expose()
	public get businessRowNumber(): number {
		return this.getValue<number>(() => this._businessRowNumber, 'businessRowNumber');
	}
	public set businessRowNumber(value: number) {
		this.setValue<number>(() => { this._businessRowNumber = value; }, value, 'businessRowNumber');
	}

	private _chartAccountCode: number = 0;

	/**
	 Codice sottoconto
	 */
	@Expose()
	public get chartAccountCode(): number {
		return this.getValue<number>(() => this._chartAccountCode, 'chartAccountCode');
	}
	public set chartAccountCode(value: number) {
		this.setValue<number>(() => { this._chartAccountCode = value; }, value, 'chartAccountCode');
	}
}
