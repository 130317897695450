import { LogService } from "@nts/std/src/lib/utility";
import { ClassType } from "@nts/std";

export interface State<TData, TClassType = any> {
    classType: ClassType<TClassType>;
    next?: (context: StateMachine<TData>) => Promise<State<TData>>;
    previous?: (context: StateMachine<TData>) => Promise<State<TData>>;
}

export class StateMachine<TData, TState extends State<TData> = State<TData>> {
    private internalState: TState;
    private internalData: TData;
    private internalHistory: ClassType<TState>[];

    constructor(state: TState, data: TData) {
        this.internalState = state;
        this.internalData = data;
        this.internalHistory = [];
    }

    get history(): ClassType<TState>[] {
        return this.internalHistory;
    }

    get data(): TData {
        return this.internalData;
    }

    get state(): TState {
        return this.internalState;
    }

    async next(): Promise<boolean> {
        if (this.state.next) {
            this.history.push(this.state.classType);
            const result = await this.state.next(this) as TState;
            if (result != null) {
                this.internalState = result;
                return true;
            } else {
                // se si fa l'override e si restituisce null vuol dire che non è consentito continuare
                LogService.log('next: not allowed');
                this.history.pop();
                return false;
            }
        } else {
            // se non esiste il next non è consentito continuare
            LogService.log('next: not allowed');
            return false;
        }

    }

    async previous(): Promise<boolean> {
        if (this.state.previous) {
            const result = await this.state.previous(this) as TState;
            if (result != null) {
                this.internalState = result;
                this.history.pop();
                return true;
            } else {
                // se si fa l'override e si restituisce null vuol dire che non è consentito andare indietro
                LogService.log('previous: not allowed');
                // restore removed history
                return false;
            }
        } else {
            // Usa l'history
            if (this.history?.length > 1) {
                const result = new this.history[this.history.length - 1]();
                if (result != null) {
                    this.internalState = result;
                    this.history.pop();
                    return true;
                } else {
                    // history corrotta
                    LogService.log('previous: corrupted history');
                    return false;
                }
            } else {
                LogService.log('previous: not allowed');
                return false;
            }
        }
    }
}
