import {
	InternalViewModel, StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel,
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel, ItemViewModel, BaseRowNumberItemViewModel
} from '@nts/std';
import { ExpenseFile } from '../../domain-models/expense-file';
import { ExpenseFileIdentity } from '../../domain-models/expense-file.identity';

export class GeneratedExpenseFileCollectionItemViewModel extends BaseRowNumberItemViewModel<ExpenseFile, ExpenseFileIdentity> {

	private _originalFileName: StringPropertyViewModel;

	public get originalFileName(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._originalFileName = value; }, this._originalFileName, 'originalFileName');
	}

	private _storageFileName: StringPropertyViewModel;

	public get storageFileName(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._storageFileName = value; }, this._storageFileName, 'storageFileName');
	}

	private _serial: NumericPropertyViewModel;

	public get serial(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._serial = value; }, this._serial, 'serial');
	}

	private _attachmentFileId: NNumericPropertyViewModel;

	public get attachmentFileId(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._attachmentFileId = value; }, this._attachmentFileId, 'attachmentFileId');
	}

	private _fileSize: NumericPropertyViewModel;

	public get fileSize(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._fileSize = value; }, this._fileSize, 'fileSize');
	}

}
