
import {
    IdentityTypeDecorator, ModelTypeNameDecorator
} from '@nts/std';
import { IgnoreAutomaticDecoratorWarningDecorator } from '@nts/std';
import { GeneralSubject } from '../general-subject/general-subject';
import { GeneralSubjectIdentity } from '../general-subject/general-subject.identity';

@IdentityTypeDecorator(GeneralSubjectIdentity)
@ModelTypeNameDecorator('Customer')
@IgnoreAutomaticDecoratorWarningDecorator({
    'BusinessData': 'SubjectBusinessData'
})
export class Customer extends GeneralSubject {

}
