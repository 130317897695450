import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActivatedRoute } from '@angular/router';
import { ViewModelAwareInterface } from 'src/app/shared/models/view-model-aware.interface';
import { RouteStateService } from 'src/app/shared/services/route-state.service';
import { ExpenseDataViewModel } from 'src/app/receipt-long-op/view-models/expense-data.view-model';
import { ReceiptStepsService } from 'src/app/receipt-long-op/services/receipt-steps.service';
import { firstValueFrom } from 'rxjs';
import { DecodeTextBoxComponent, EnterpriseDataDto, ExpandableComponent, ExternalListComponent, LabelBoxComponent, LabelledTextBoxComponent, MessageResourceManager, NTSTranslatePipe, ToastMessage, ToastMessageType } from '@nts/std';
import { ReceiptLongOpApiClient } from 'src/app/receipt-long-op/api-clients/receipt-long-op.api-client';
import { ExpenseModel } from 'src/app/expense-model/domain-models/expense-model';
import getSymbolFromCurrency from 'currency-symbol-map'
import { ReceiptLongOpOrchestratorViewModel } from 'src/app/receipt-long-op/view-models/receipt-long-op.orchestrator-view-model';
import { OnlineService } from '@nts/std/src/lib/utility';
import { AuthService } from '@nts/std';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { CreateExpenseAnnotationLongOpApiClient } from 'src/app/create-expense-annotation-long-op/api-clients/create-expense-annotation-long-op.api-client';
import { WeaSettingsDataDto } from 'src/app/create-expense-annotation-long-op/api-clients/dto/wea-settings-data.dto';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';
import { MultipleFileUploadComponent } from 'src/app/shared/components/multiple-file-upload/multiple-file-upload.component';
import { FullscreenPreviewComponent } from 'src/app/shared/components/fullscreen-preview/fullscreen-preview.component';


@UntilDestroy()
@Component({
  selector: 'nts-expense-data-step',
  templateUrl: './expense-data-step.component.html',
  styleUrls: ['./expense-data-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NTSTranslatePipe,
    ExpandableComponent,
    SvgIconComponent,
    LabelledTextBoxComponent,
    NgIf,
    ExternalListComponent,
    DecodeTextBoxComponent,
    LabelBoxComponent,
    MultipleFileUploadComponent,
    FullscreenPreviewComponent
  ]
})
export class ExpenseDataStepComponent implements ViewModelAwareInterface {

  viewModel: ExpenseDataViewModel;
  orchestratorViewModel: ReceiptLongOpOrchestratorViewModel;
  inIframe = false;

  constructor(
    private readonly receiptStepsService: ReceiptStepsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly routeStateService: RouteStateService,
    private readonly receiptLongOpApiClient: ReceiptLongOpApiClient,
    private readonly createExpenseAnnotationLongOpApiClient: CreateExpenseAnnotationLongOpApiClient,
    // private readonly currencyPipe: CurrencyPipe,
    private readonly cd: ChangeDetectorRef,
    private readonly onlineService: OnlineService,
    private readonly authService: AuthService,
    private readonly telemetryService: TelemetryService
  ) {
    this.activatedRoute.paramMap.pipe(untilDestroyed(this)).subscribe((paramMap) => {
      this.routeStateService.updateState(paramMap)
    })

    this.activatedRoute.queryParamMap.pipe(untilDestroyed(this)).subscribe((queryParamMap) => {
      this.routeStateService.updateState(null, queryParamMap)
    })
  }

  async onViewModelLoaded() {


    this.receiptStepsService.stepLoading.next(true);
    this.orchestratorViewModel = this.receiptStepsService.orchestratorViewModel;

    const viewModel = this.receiptStepsService.getCurrentState() as ExpenseDataViewModel;

    const rootViewModel = this.receiptStepsService.orchestratorViewModel.rootViewModel;
    const selectedModelCode = rootViewModel.params.expenseModelData.expenseModelRef?.modelCode?.value;

    const tenantId: number = await this.authService.getTenantId();
    const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);

    // se non ho popolato gli availableModels li popolo
    if (rootViewModel.params.expenseModelData.availableModels == null) {
      const availableModelsResponse = await firstValueFrom(this.receiptLongOpApiClient.getAvailableModels(undefined, enterpriseData.companyId))
      if (availableModelsResponse.operationSuccedeed === true) {
        rootViewModel.params.expenseModelData.availableModels = availableModelsResponse.result || [];
      } else {
        rootViewModel.params.expenseModelData.availableModels = [];
      }
    }

    let fullExpenseModel: ExpenseModel = null;

    if (rootViewModel.params.expenseModelData.availableModels.length > 0) {
      fullExpenseModel = rootViewModel.params.expenseModelData.availableModels.find((e: ExpenseModel) => e.modelCode === selectedModelCode)
    }

    const queryParams = await firstValueFrom(this.routeStateService.queryParams);
    const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);
    const remoteExpense = queryParams['remoteExpense'] === 'true';

    let currencySymbol = null;

    const isoCode = queryParams['isoCode'];
    if (isoCode?.length > 0) {
      currencySymbol = getSymbolFromCurrency(isoCode)
    } else {
      const currencyName = queryParams['currencyName'];
      currencySymbol = currencyName;
    }

    let configuration: WeaSettingsDataDto = null;
    const configurationResponse = await firstValueFrom(this.createExpenseAnnotationLongOpApiClient.getWEASettingsData());
    if (configurationResponse && configurationResponse.operationSuccedeed === true) {
      configuration = configurationResponse.result;
    }

    const currentExpenseClassification = rootViewModel.params.expenseSelection.expenseClassification.value;
    const extendedAvailableExpenses = await this.receiptStepsService.orchestratorViewModel.getExtendedAvailableExpenses(
      selectedModelCode,
      currentExpenseClassification
    );

    const customerIdentity = rootViewModel.params.getDomainModel().customerIdentity;
    const leadIdentity = rootViewModel.params.getDomainModel().leadIdentity;
    const commissionIdentity = rootViewModel.params.getDomainModel().commissionIdentity;

    await viewModel.initPresentationLogic(
      rootViewModel.params.expenseSelection.expenseClassification.value,
      fullExpenseModel,
      extendedAvailableExpenses,
      expenseAnnotationId,
      remoteExpense,
      currencySymbol,
      rootViewModel.params.expenseState,
      configuration,
      customerIdentity,
      leadIdentity,
      commissionIdentity,
      this.onlineService
    );

    this.receiptStepsService.orchestratorViewModel.rootViewModelModified.pipe(untilDestroyed(this)).subscribe(() => {
      viewModel.validateStep();
    });

    await viewModel.validateStep();

    const params = await firstValueFrom(this.routeStateService.params);
    const currentAction = params.get('action');

    if (currentAction === 'add' && viewModel.isValid.value === true) {
      if (!this.receiptStepsService.orchestratorViewModel.errorDuringAdd) {
        this.receiptStepsService.nextStep();
      } else {
        this.receiptStepsService.stepLoading.next(false);
      }
    } else if (currentAction === 'add' && viewModel.isValid.value === false) {
      const toastMessage: ToastMessage = {
        message: MessageResourceManager.Current.getMessage('MissinigDataInStep'),
        type: ToastMessageType.warn,
        title: MessageResourceManager.Current.getMessage('std_Warning')
      };
      this.receiptStepsService.orchestratorViewModel.toastMessageService.showToast(toastMessage);
      this.receiptStepsService.orchestratorViewModel.errorDuringAdd = true;
      this.receiptStepsService.stepLoading.next(false);
    } else {
      this.receiptStepsService.stepLoading.next(false);
    }
    this.viewModel = viewModel;

    this.inIframe = this.activatedRoute.snapshot.queryParams['iframe'] === 'true';

    const customTitle = viewModel.newLabels().value[viewModel.currentExpenseClassificationType];
    const title = customTitle ?? MessageResourceManager.Current.getMessage('ExpenseClassification_' + viewModel.expenseClassificationEnum[viewModel.currentExpenseClassificationType]);
    this.telemetryService.trackPageView({ name: title });

    this.cd.detectChanges();
  };

  fileUploadValidation() {
    this.viewModel.validateStep();
    this.cd.detectChanges()
  }

  async beforeNextAction(): Promise<void> {
  }
}

