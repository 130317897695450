import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';
import { ExpenseModelIdentity } from '../../../expense-model/domain-models/expense-model.identity';

@IdentityTypeNameDecorator('AvailableExpenseIdentity')
export class GeneratedAvailableExpenseIdentity extends ExpenseModelIdentity {

	private _availableExpenseId: number;

	@Expose()
	public get availableExpenseId(): number {
		return this.getValue<number>(() => this._availableExpenseId, 'availableExpenseId');
	}
	public set availableExpenseId(value: number) {
		this.setValue<number>(() => { this._availableExpenseId = value; }, value, 'availableExpenseId');
	}
}
