import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel, DateTime, Transform, DateTimeOffset
} from '@nts/std';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';
import { ExpenseType } from 'src/app/expense-type/domain-models/expense-type';
import { ExpenseTypeIdentity } from 'src/app/expense-type/domain-models/expense-type.identity';
import { ExternalDecorator } from '@nts/std';
import { ExpenseFileCollection } from '../../domain-models/expense-file.collection';
import { ExpenseFile } from '../../domain-models/expense-file';
import { Lead } from 'src/app/external-remote/lead/lead';
import { LeadIdentity } from 'src/app/external-remote/lead/lead.identity';
import { Customer } from 'src/app/external-remote/customer/customer';
import { GeneralSubjectIdentity } from 'src/app/external-remote/general-subject/general-subject.identity';
import { Commission } from 'src/app/external-remote/commission/commission';
import { CommissionIdentity } from 'src/app/external-remote/commission/commission.identity';

@IdentityTypeDecorator(ReceiptParamsIdentity)
@ModelTypeNameDecorator('ExpenseData')
export class GeneratedExpenseData extends CoreModel<ReceiptParamsIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _expenseDate: DateTimeOffset;

	/**
	 Data spesa
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('expenseDate', value, obj, type);
	})
	public get expenseDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._expenseDate, 'expenseDate');
	}
	public set expenseDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._expenseDate = value; }, value, 'expenseDate');
	}

	private _expenseTypeCode: number;

	/**
	 Codice tipo spesa
	 */
	@Expose()
	public get expenseTypeCode(): number {
		return this.getValue<number>(() => this._expenseTypeCode, 'expenseTypeCode');
	}
	public set expenseTypeCode(value: number) {
		this.setValue<number>(() => { this._expenseTypeCode = value; }, value, 'expenseTypeCode');
	}

	private _expenseTypeRef: ExpenseType;

	/**
	 Tipo spesa
	 */
	@Expose()
	@Type(() => ExpenseType)
	@ExternalDecorator({
		isRequired: true,
	})
	public get expenseTypeRef(): ExpenseType {
		return this.getValueForExternal<ExpenseType>(
			() => this._expenseTypeRef,
			'expenseTypeRef',
			ExpenseType
		);
	}
	public set expenseTypeRef(value: ExpenseType) {
		this.setValueForExternal<ExpenseType, ExpenseTypeIdentity>(() => { this._expenseTypeRef = value; }, value, 'expenseTypeRef');
	}

	private _expenseTotalAmount: number = 0;

	/**
	 Importo totale spesa
	 */
	@Expose()
	public get expenseTotalAmount(): number {
		return this.getValue<number>(() => this._expenseTotalAmount, 'expenseTotalAmount');
	}
	public set expenseTotalAmount(value: number) {
		this.setValue<number>(() => { this._expenseTotalAmount = value; }, value, 'expenseTotalAmount');
	}

	private _description: string;

	/**
	 Descrizione spesa
	 */
	@Expose()
	public get description(): string {
		return this.getValue<string>(() => this._description, 'description');
	}
	public set description(value: string) {
		this.setValue<string>(() => { this._description = value; }, value, 'description');
	}

	private _note: string;

	/**
	 Note
	 */
	@Expose()
	public get note(): string {
		return this.getValue<string>(() => this._note, 'note');
	}
	public set note(value: string) {
		this.setValue<string>(() => { this._note = value; }, value, 'note');
	}

	private _files: ExpenseFileCollection;

	/**
	 Allegati
	 */
	@Expose()
	@Type(() => ExpenseFileCollection)
	public get files(): ExpenseFileCollection {
		return this.getValueForInternalCollection<ExpenseFileCollection, ExpenseFile>(
			(value) => {
				this._files = value;
			},
			this._files,
			'files',
			ExpenseFileCollection
		);
	}
	public set files(value: ExpenseFileCollection) {
		this.setValueForInternalCollection<ExpenseFileCollection, ExpenseFile>(() => { this._files = value; }, value, 'files');
	}

	private _expenseTypeDescription: string;

	/**
	 Tipo spesa
	 */
	@Expose()
	public get expenseTypeDescription(): string {
		return this.getValue<string>(() => this._expenseTypeDescription, 'expenseTypeDescription');
	}
	public set expenseTypeDescription(value: string) {
		this.setValue<string>(() => { this._expenseTypeDescription = value; }, value, 'expenseTypeDescription');
	}

	private _leadId: number;

	/**
	 Id Lead
	 */
	@Expose()
	public get leadId(): number {
		return this.getValue<number>(() => this._leadId, 'leadId');
	}
	public set leadId(value: number) {
		this.setValue<number>(() => { this._leadId = value; }, value, 'leadId');
	}

	private _leadCompanyId: number;

	/**
	 Id Codice ditta Lead
	 */
	@Expose()
	public get leadCompanyId(): number {
		return this.getValue<number>(() => this._leadCompanyId, 'leadCompanyId');
	}
	public set leadCompanyId(value: number) {
		this.setValue<number>(() => { this._leadCompanyId = value; }, value, 'leadCompanyId');
	}

	private _customerId: number;

	/**
	 Id cliente
	 */
	@Expose()
	public get customerId(): number {
		return this.getValue<number>(() => this._customerId, 'customerId');
	}
	public set customerId(value: number) {
		this.setValue<number>(() => { this._customerId = value; }, value, 'customerId');
	}

	private _customerCompanyId: number;

	/**
	 Codice ditta cliente
	 */
	@Expose()
	public get customerCompanyId(): number {
		return this.getValue<number>(() => this._customerCompanyId, 'customerCompanyId');
	}
	public set customerCompanyId(value: number) {
		this.setValue<number>(() => { this._customerCompanyId = value; }, value, 'customerCompanyId');
	}

	private _commissionId: number;

	/**
	 Id commessa
	 */
	@Expose()
	public get commissionId(): number {
		return this.getValue<number>(() => this._commissionId, 'commissionId');
	}
	public set commissionId(value: number) {
		this.setValue<number>(() => { this._commissionId = value; }, value, 'commissionId');
	}

	private _commissionCompanyId: number;

	/**
	 Codice ditta commessa
	 */
	@Expose()
	public get commissionCompanyId(): number {
		return this.getValue<number>(() => this._commissionCompanyId, 'commissionCompanyId');
	}
	public set commissionCompanyId(value: number) {
		this.setValue<number>(() => { this._commissionCompanyId = value; }, value, 'commissionCompanyId');
	}
}
