import { RootModelTypeNameDecorator, RootModelTypeDecorator } from '@nts/std';
import { Injectable } from '@angular/core';
import { CrudApiClient } from '@nts/std';
import { ExpenseModel } from '../../domain-models/expense-model';
import { ExpenseModelIdentity } from '../../domain-models/expense-model.identity';

@Injectable()
@RootModelTypeNameDecorator('ExpenseModel')
@RootModelTypeDecorator(ExpenseModel)
export class GeneratedExpenseModelApiClient extends CrudApiClient<ExpenseModel, ExpenseModelIdentity> {

}
