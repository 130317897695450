import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { EditExpenseAnnotationResultIdentity } from '../../domain-models/edit-expense-annotation-result.identity';

@IdentityTypeDecorator(EditExpenseAnnotationResultIdentity)
@ModelTypeNameDecorator('EditExpenseAnnotationResult')
export class GeneratedEditExpenseAnnotationResult extends CoreModel<EditExpenseAnnotationResultIdentity> {

	private _id: number = 0;

	/**
	 Id longop
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}
}
