import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';
import { UserAvailablePaymentsIdentity } from '../../../user-available-payments/domain-models/user-available-payments.identity';

@IdentityTypeNameDecorator('PersonalPaymentIdentity')
export class GeneratedPersonalPaymentIdentity extends UserAvailablePaymentsIdentity {

	private _personalPaymentId: number;

	@Expose()
	public get personalPaymentId(): number {
		return this.getValue<number>(() => this._personalPaymentId, 'personalPaymentId');
	}
	public set personalPaymentId(value: number) {
		this.setValue<number>(() => { this._personalPaymentId = value; }, value, 'personalPaymentId');
	}
}
