import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Params,
} from '@angular/router';
import { LogService } from '@nts/std/src/lib/utility';
import { RECEIPT_LONG_OP_FULL_PATH } from 'src/app/shared/shared.module';
import { ReceiptStepsService, RECEIPT_VALID_ACTIONS } from './receipt-steps.service';

@Injectable()
export class ReceiptStepGuard implements CanActivate {
    constructor(
        private router: Router,
        private receiptStepsService: ReceiptStepsService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const path: string = route.routeConfig.path;
        return this.verifyWorkFlow(path, route.params, route.queryParams);
    }

    async verifyWorkFlow(
        path: string,
        params: Params,
        queryParams: Params
    ): Promise<boolean> {
        LogService.log('Receipt: Entered ' + path + ' path.');

        let currentAction = params['action'];
        let currentId = params['id'];
        let invalidAction = false;

        if (!params['action'] || params['action'].length == 0 || RECEIPT_VALID_ACTIONS.indexOf(params['action'].toLowerCase()) == -1) {
            currentAction = 'new';
            invalidAction = true;
        }

        if (params['action'] === 'edit' && currentId == null) {
            currentAction = 'new';
            invalidAction = true;
        }

        if ((params['action'] === 'edit' || params['action'] === 'add') && currentId?.length > 0) {

            let remoteExpense = false;
            if (queryParams['remoteExpense'] === 'true') {
                remoteExpense = true;
            }

            const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);

            const res = await this.receiptStepsService.init(currentId, remoteExpense, expenseAnnotationId);
            if (!res) {
                return false;
            }
        } else {

            const expenseAnnotationId = parseInt(queryParams['expenseAnnotationId'], 10);

            const res = await this.receiptStepsService.init(null, false, expenseAnnotationId);
            if (!res) {
                return false;
            }
        }

        // If any of the previous steps is invalid, go back to the first invalid step
        const firstPath = await this.receiptStepsService.getFirstInvalidStep(path);
        if (firstPath.length > 0) {
            LogService.log('Redirected to ' + firstPath + ' path which it is the first invalid step.');
            let url = `${RECEIPT_LONG_OP_FULL_PATH}/${currentAction}/${firstPath}`;
            if (currentAction === 'edit' && currentId?.length > 0) {
                url = `${RECEIPT_LONG_OP_FULL_PATH}/${currentAction}/${firstPath}/${currentId}`;
            }
            this.router.navigate([url], { queryParams });
            return false;
        }

        if (invalidAction) {
            LogService.log(`${RECEIPT_LONG_OP_FULL_PATH}/${params['action']}/${path} is not a valid url. Redirected to ${RECEIPT_LONG_OP_FULL_PATH}/${currentAction}/${path} path beacause action is not valid.`);
            const url = `${RECEIPT_LONG_OP_FULL_PATH}/${currentAction}/${path}`;
            this.router.navigate([url], { queryParams });
            return false;
        }

        this.receiptStepsService.updateCurrentStep(null, `${RECEIPT_LONG_OP_FULL_PATH}/${currentAction}/${path}`);
        return true;

    }
}
