import {
	InternalViewModel, StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel, 
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel
} from '@nts/std';
import { AvailablePaymentBusinessData } from '../../domain-models/available-payment-business-data';
import { AvailablePaymentIdentity } from '../../domain-models/available-payment.identity';

export class GeneratedAvailablePaymentBusinessDataViewModel extends InternalViewModel<AvailablePaymentBusinessData, AvailablePaymentIdentity> {

	private _availablePaymentId: NumericPropertyViewModel;

	public get availablePaymentId(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._availablePaymentId = value; }, this._availablePaymentId, 'availablePaymentId');
	}

	private _businessPaymentType: EnumPropertyViewModel;

	public get businessPaymentType(): EnumPropertyViewModel {
		return this.getEnumPropertyViewModel((value) => { this._businessPaymentType = value; }, this._businessPaymentType, 'businessPaymentType');
	}

	private _companyCode: StringPropertyViewModel;

	public get companyCode(): StringPropertyViewModel {
		return this.getStringPropertyViewModel((value) => { this._companyCode = value; }, this._companyCode, 'companyCode');
	}

	private _businessModelCode: NumericPropertyViewModel;

	public get businessModelCode(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._businessModelCode = value; }, this._businessModelCode, 'businessModelCode');
	}

	private _businessRowNumber: NNumericPropertyViewModel;

	public get businessRowNumber(): NNumericPropertyViewModel {
		return this.getNNumericPropertyViewModel((value) => { this._businessRowNumber = value; }, this._businessRowNumber, 'businessRowNumber');
	}

	private _chartAccountCode: NumericPropertyViewModel;

	public get chartAccountCode(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._chartAccountCode = value; }, this._chartAccountCode, 'chartAccountCode');
	}

}
