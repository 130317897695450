import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel, DateTimeOffset, Transform
} from '@nts/std';
import { Currency } from 'src/app/external-remote/currency/currency';
import { CurrencyIdentity } from 'src/app/external-remote/currency/currency.identity';
import { CreateExpenseAnnotationParamsIdentity } from '../../domain-models/create-expense-annotation-params.identity';

@IdentityTypeDecorator(CreateExpenseAnnotationParamsIdentity)
@ModelTypeNameDecorator('AnnotationData')
export class GeneratedAnnotationData extends CoreModel<CreateExpenseAnnotationParamsIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _isLeadSelected: boolean;

	/**
	 Lead associato
	 */
	@Expose()
	public get isLeadSelected(): boolean {
		return this.getValue<boolean>(() => this._isLeadSelected, 'isLeadSelected');
	}
	public set isLeadSelected(value: boolean) {
		this.setValue<boolean>(() => { this._isLeadSelected = value; }, value, 'isLeadSelected');
	}

	private _leadId: number;

	/**
	 Id lead
	 */
	@Expose()
	public get leadId(): number {
		return this.getValue<number>(() => this._leadId, 'leadId');
	}
	public set leadId(value: number) {
		this.setValue<number>(() => { this._leadId = value; }, value, 'leadId');
	}

	private _leadCompanyId: number;

	/**
	 Codice ditta lead
	 */
	@Expose()
	public get leadCompanyId(): number {
		return this.getValue<number>(() => this._leadCompanyId, 'leadCompanyId');
	}
	public set leadCompanyId(value: number) {
		this.setValue<number>(() => { this._leadCompanyId = value; }, value, 'leadCompanyId');
	}

	private _isCustomerSelected: boolean;

	/**
	 Cliente associato
	 */
	@Expose()
	public get isCustomerSelected(): boolean {
		return this.getValue<boolean>(() => this._isCustomerSelected, 'isCustomerSelected');
	}
	public set isCustomerSelected(value: boolean) {
		this.setValue<boolean>(() => { this._isCustomerSelected = value; }, value, 'isCustomerSelected');
	}

	private _customerId: number;

	/**
	 Id cliente
	 */
	@Expose()
	public get customerId(): number {
		return this.getValue<number>(() => this._customerId, 'customerId');
	}
	public set customerId(value: number) {
		this.setValue<number>(() => { this._customerId = value; }, value, 'customerId');
	}

	private _customerCompanyId: number;

	/**
	 Codice ditta cliente
	 */
	@Expose()
	public get customerCompanyId(): number {
		return this.getValue<number>(() => this._customerCompanyId, 'customerCompanyId');
	}
	public set customerCompanyId(value: number) {
		this.setValue<number>(() => { this._customerCompanyId = value; }, value, 'customerCompanyId');
	}

	private _isCommissionSelected: boolean;

	/**
	 Commessa associata
	 */
	@Expose()
	public get isCommissionSelected(): boolean {
		return this.getValue<boolean>(() => this._isCommissionSelected, 'isCommissionSelected');
	}
	public set isCommissionSelected(value: boolean) {
		this.setValue<boolean>(() => { this._isCommissionSelected = value; }, value, 'isCommissionSelected');
	}

	private _commissionId: number;

	/**
	 Id commessa
	 */
	@Expose()
	public get commissionId(): number {
		return this.getValue<number>(() => this._commissionId, 'commissionId');
	}
	public set commissionId(value: number) {
		this.setValue<number>(() => { this._commissionId = value; }, value, 'commissionId');
	}

	private _commissionCompanyId: number;

	/**
	 Codice ditta commessa
	 */
	@Expose()
	public get commissionCompanyId(): number {
		return this.getValue<number>(() => this._commissionCompanyId, 'commissionCompanyId');
	}
	public set commissionCompanyId(value: number) {
		this.setValue<number>(() => { this._commissionCompanyId = value; }, value, 'commissionCompanyId');
	}

	private _startDate: DateTimeOffset;

	/**
	 Data inizio
	 */
	@Expose()
	@Transform((value, obj, type) => {
		return DateTimeOffset.transform('startDate', value, obj, type);
	})
	public get startDate(): DateTimeOffset {
		return this.getValue<DateTimeOffset>(() => this._startDate, 'startDate');
	}
	public set startDate(value: DateTimeOffset) {
		this.setValue<DateTimeOffset>(() => { this._startDate = value; }, value, 'startDate');
	}

	private _currencyId: number;

	/**
	 Id valuta
	 */
	@Expose()
	public get currencyId(): number {
		return this.getValue<number>(() => this._currencyId, 'currencyId');
	}
	public set currencyId(value: number) {
		this.setValue<number>(() => { this._currencyId = value; }, value, 'currencyId');
	}

	private _currencyRef: Currency;

	/**
	 Valuta
	 */
	@Expose()
	@Type(() => Currency)
	public get currencyRef(): Currency {
		return this.getValueForExternal<Currency>(
			() => this._currencyRef,
			'currencyRef',
			Currency
		);
	}
	public set currencyRef(value: Currency) {
		this.setValueForExternal<Currency, CurrencyIdentity>(() => { this._currencyRef = value; }, value, 'currencyRef');
	}

	private _description: string;

	/**
	 Descrizione
	 */
	@Expose()
	public get description(): string {
		return this.getValue<string>(() => this._description, 'description');
	}
	public set description(value: string) {
		this.setValue<string>(() => { this._description = value; }, value, 'description');
	}
}
