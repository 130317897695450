import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { ChildrenOutletContexts, RouterOutlet } from '@angular/router';
import { ViewModelAwareInterface } from 'src/app/shared/models/view-model-aware.interface';
import { ReceiptStepsService } from '../../services/receipt-steps.service';
import { RECEIPT_LONG_OP_FULL_PATH } from 'src/app/shared/shared.module';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { StepViewModelAwareInterface } from 'src/app/shared/models/step-view-model-aware.interface';
import { map, Observable, of, switchMap } from 'rxjs';
import { FilledButtonComponent, LoaderComponent, MessageResourceManager, TextButtonComponent } from '@nts/std';
import { stepTransitionAnimations } from 'src/app/shared/animations';
import { ExpenseState } from 'src/app/expense-annotation/generated/domain-models/enums/generated-expense-state';
import { TelemetryService } from '@nts/std/src/lib/telemetry';
import { AsyncPipe, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'nts-receipt-step-container',
  templateUrl: './receipt-step-container.component.html',
  styleUrls: ['./receipt-step-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextButtonComponent,
    LoaderComponent,
    RouterOutlet,
    FilledButtonComponent,
    AsyncPipe,
    NgIf
  ],
  // make slide in/out animation available to this component
  // animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  // host: { '[@slideInOutAnimation]': '' }
  animations: [stepTransitionAnimations]
})
export class ReceiptStepContainerComponent {
  currentViewModel: StepViewModelAwareInterface;
  stepLoading: boolean;
  baseRoutePath = RECEIPT_LONG_OP_FULL_PATH;
  defaultColor = '#3e709b'
  currentColor = this.defaultColor;
  forwordButtonLabel = '';

  constructor(
    public stepsService: ReceiptStepsService,
    private cd: ChangeDetectorRef,
    private contexts: ChildrenOutletContexts,
    private telemetryService: TelemetryService
  ) {

    this.stepsService.stepLoading.pipe(untilDestroyed(this)).subscribe((stepLoading) => {
      this.stepLoading = stepLoading;
      this.cd.markForCheck();
    })
  }

  async onActivate(component: ViewModelAwareInterface) {
    await this.stepsService.init();
    component.onViewModelLoaded();
    this.cd.detectChanges();
    this.stepsService.currentStepChanged.pipe(untilDestroyed(this), switchMap(() => this.getForwordButtonLabel())).subscribe(async (forwordButtonLabel: string) => {
      this.currentViewModel = this.stepsService.getCurrentState();
      this.forwordButtonLabel = forwordButtonLabel;
      this.cd.detectChanges();
    })
  }

  async onNextStep() {
    this.stepsService.nextStep();
  }

  async onExit() {
    this.telemetryService.trackEvent({
      name: 'WEA_ReceiptLongOp_CloseButtonForExit',
    });
    this.stepsService.exit();
  }

  async onPreviousStep() {
    this.stepsService.previousStep();
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  getForwordButtonLabel(): Observable<string> {
    this.currentViewModel = this.stepsService.getCurrentState();
    if (this.currentViewModel?.customNextLabel) {
      return this.currentViewModel.customNextLabel;
    } else {

      return this.stepsService.currentStepChanged.pipe(map(() => {
        if (this.stepsService.isLastStep()) {
          let label = MessageResourceManager.Current.getMessage('CMD_ReceiptLastStep_StoreInDevice');
          const expenseAnnotationId = this.stepsService.expenseAnnotationId;
          const remoteExpense = this.stepsService.remoteExpense;
          const expenseState = this.stepsService.orchestratorViewModel.rootViewModel.params.expenseState;

          // In questo caso sto usando una expense sul device e ho il riferimento della nota spesa
          // Quindi sono in creazione partendo da una spesa del device
          if (expenseAnnotationId > 0 && remoteExpense === false && expenseState.value === ExpenseState.WebEditing) {
            label =
              MessageResourceManager.Current.getMessage('CMD_ReceiptLastStep_Add')

            // In questo caso sto usando una expense remota e ho il riferimento della nota spesa
            // Quindi sono in modica di una spesa esistente di una determinata nota spesa
          } else if (expenseAnnotationId > 0 && remoteExpense === true && expenseState.value === ExpenseState.WebEditing) {
            label =
              MessageResourceManager.Current.getMessage('CMD_ReceiptLastStep_Update')

          } else if (expenseState.value !== ExpenseState.WebEditing && expenseState.value !== ExpenseState.IntegrateDocumentation) {
            label =
              MessageResourceManager.Current.getMessage('CMD_ReceiptLastStep_Close')

          } else if (expenseState.value === ExpenseState.IntegrateDocumentation) {
            label =
              MessageResourceManager.Current.getMessage('CMD_ReceiptLastStep_Integrate')

          }
          return label;
        } else {
          return MessageResourceManager.Current.getMessage('CMD_Next');
        }
      }))
    }
  }
}
