import { ExpenseClassification } from "src/app/receipt-long-op/generated/domain-models/enums/generated-expense-classification";

export class ExpenseClassificationMapper {

    private static mapper = {
        [ExpenseClassification.Transport]: {
            icon: 'transport',
            color: '#fee8e9'
        },
        [ExpenseClassification.Accommodation]: {
            icon: 'rest',
            color: '#dae2f0'
        },
        [ExpenseClassification.Food]: {
            icon: 'roast',
            color: '#d7edca'
        },
        [ExpenseClassification.MileageRefound]: {
            icon: 'fill',
            color: '#FEEFC2'
        },
        [ExpenseClassification.Other]: {
            icon: 'pack',
            color: '#e0f3f3'
        }
    }

    static getIconByClassificationType(ExpenseClassification: ExpenseClassification) {
        return this.mapper[ExpenseClassification].icon;
    }

    static getColorByClassificationType(ExpenseClassification: ExpenseClassification) {
        return this.mapper[ExpenseClassification].color;
    }
}
