import { trigger, transition, style, query, animateChild, group, animate, state } from "@angular/animations";

export const faseInAnimation =
    trigger('routeAnimations', [
        // route 'enter' transition
        transition(':enter', [

            // group([
            //     query(':leave', [
            //         animate('300ms ease-out', style({ left: '100%' }))
            //     ]),
            //     query(':enter', [
            //         animate('300ms ease-out', style({ left: '0%' }))
            //     ]),
            // ]),

            // css styles at start of transition
            style({ opacity: 0 }),

            // animation and styles at end of transition
            animate('1s', style({ opacity: 1 })),


        ]),
        transition('* => OperationComplete', [

            // group([
            //     query(':leave', [
            //         animate('300ms ease-out', style({ left: '100%' }))
            //     ]),
            //     query(':enter', [
            //         animate('300ms ease-out', style({ left: '0%' }))
            //     ]),
            // ]),

            // css styles at start of transition
            style({ opacity: 0 }),

            // animation and styles at end of transition
            animate('1s', style({ opacity: 1 })),


        ]),

        transition('* <=> *', [

            // end state styles for route container (host)
            style({ opacity: 0 }),

            // animation and styles at end of transition
            animate('1s', style({ opacity: 1 })),

        ]),

        transition(':leave', [

            // css styles at start of transition
            style({ opacity: 1 }),

            // animation and styles at end of transition
            animate('1s', style({ opacity: 0 }))
        ]),
    ]);
