import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { AvailableExpenseIdentity } from '../../domain-models/available-expense.identity';

@IdentityTypeDecorator(AvailableExpenseIdentity)
@ModelTypeNameDecorator('AvailableExpenseBusinessData')
export class GeneratedAvailableExpenseBusinessData extends CoreModel<AvailableExpenseIdentity> {

	private _availableExpenseId: number = 0;

	/**
	 Id tipologia spesa ammessa
	 */
	@Expose()
	public get availableExpenseId(): number {
		return this.getValueForIdentityValue<number>(() => this._availableExpenseId, 'availableExpenseId');
	}
	public set availableExpenseId(value: number) {
		this.setValueForIdentityValue<number>(() => { this._availableExpenseId = value; }, value, 'availableExpenseId');
	}

	private _modelCode: number = 0;

	/**
	 Codice Modello spesa
	 */
	@Expose()
	public get modelCode(): number {
		return this.getValueForIdentityValue<number>(() => this._modelCode, 'modelCode');
	}
	public set modelCode(value: number) {
		this.setValueForIdentityValue<number>(() => { this._modelCode = value; }, value, 'modelCode');
	}

	private _companyCode: string;

	/**
	 Codice Ditta
	 */
	@Expose()
	public get companyCode(): string {
		return this.getValue<string>(() => this._companyCode, 'companyCode');
	}
	public set companyCode(value: string) {
		this.setValue<string>(() => { this._companyCode = value; }, value, 'companyCode');
	}

	private _businessModelCode: number = 0;

	/**
	 Codice Business
	 */
	@Expose()
	public get businessModelCode(): number {
		return this.getValue<number>(() => this._businessModelCode, 'businessModelCode');
	}
	public set businessModelCode(value: number) {
		this.setValue<number>(() => { this._businessModelCode = value; }, value, 'businessModelCode');
	}

	private _businessRowNumber: number;

	/**

	 */
	@Expose()
	public get businessRowNumber(): number {
		return this.getValue<number>(() => this._businessRowNumber, 'businessRowNumber');
	}
	public set businessRowNumber(value: number) {
		this.setValue<number>(() => { this._businessRowNumber = value; }, value, 'businessRowNumber');
	}
}
