import { Expose, Type } from '@nts/std';
import { GeneratedPaymentData } from '../generated/domain-models/generated-payment-data';
import { ExtendedAvailablePayment } from './extended-available-payment';
import { ExtendedAvailablePaymentIdentity } from './extended-available-payment.identity';
import { Supplier } from 'src/app/external-remote/supplier/supplier';
import { GeneralSubjectIdentity } from 'src/app/external-remote/general-subject/general-subject.identity';

export class PaymentData extends GeneratedPaymentData {

	private _supplierRef: Supplier;

	/**
	 Fornitore
	 */
	@Expose()
	@Type(() => Supplier)
	public get supplierRef(): Supplier {
		return this.getValueForExternal<Supplier>(
			() => this._supplierRef,
			'supplierRef',
			Supplier
		);
	}
	public set supplierRef(value: Supplier) {
		this.setValueForExternal<Supplier, GeneralSubjectIdentity>(() => { this._supplierRef = value; }, value, 'supplierRef');
	}

	private _extendedPaymentTypeId: number;

	/**
	 Id tipo pagamento
	 */
	@Expose()
	public get extendedPaymentTypeId(): number {
		return this.getValue<number>(() => this._extendedPaymentTypeId, 'extendedPaymentTypeId');
	}
	public set extendedPaymentTypeId(value: number) {
		this.setValue<number>(() => { this._extendedPaymentTypeId = value; }, value, 'extendedPaymentTypeId');
	}

	private _extendedPaymentTypeRef: ExtendedAvailablePayment;

	//l'oldPaymentAbsoluteId rappresenta l'id "assoluto" del pagamento selezionato di una spesa; in pratica questo id viene usato quando
	//apriamo una spesa in modifica(non offline) e vogliamo salvare il pagamento originale della spesa, per permetterci di selezionarlo anche
	//quando non sarebbe valido. è absolute perchè sfrutta la posizione che il pagamento otterrebbe nella lista di ExtendedAvailablePayment
	//dell'ultimo step della ReceiptLongOp.
	oldPaymentAbsoluteId: number = null;

	/**
	 Tipo pagamento
	 */
	@Expose()
	@Type(() => ExtendedAvailablePayment)
	public get extendedPaymentTypeRef(): ExtendedAvailablePayment {
		return this.getValueForExternal<ExtendedAvailablePayment>(
			() => this._extendedPaymentTypeRef,
			'extendedPaymentTypeRef',
			ExtendedAvailablePayment
		);
	}
	public set extendedPaymentTypeRef(value: ExtendedAvailablePayment) {
		this.setValueForExternal<ExtendedAvailablePayment, ExtendedAvailablePaymentIdentity>(() => { this._extendedPaymentTypeRef = value; }, value, 'extendedPaymentTypeRef');
	}
}
