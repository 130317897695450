import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';

@IdentityTypeNameDecorator('LeadIdentity')
export class LeadIdentity extends BaseIdentity {

    // tslint:disable-next-line: variable-name
    private _id: number;

    @Expose()
    get id(): number {
        return this.getValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValue<number>(() => { this._id = value; }, value, 'id');
    }

    private _companyId: number;

    @Expose()
    public get companyId(): number {
        return this.getValue<number>(() => this._companyId, 'companyId');
    }
    public set companyId(value: number) {
        this.setValue<number>(() => { this._companyId = value; }, value, 'companyId');
    }
}

