import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';
import { ExpenseModelIdentity } from '../../../expense-model/domain-models/expense-model.identity';

@IdentityTypeNameDecorator('AvailablePaymentIdentity')
export class GeneratedAvailablePaymentIdentity extends ExpenseModelIdentity {

	private _availablePaymentId: number;

	@Expose()
	public get availablePaymentId(): number {
		return this.getValue<number>(() => this._availablePaymentId, 'availablePaymentId');
	}
	public set availablePaymentId(value: number) {
		this.setValue<number>(() => { this._availablePaymentId = value; }, value, 'availablePaymentId');
	}
}
