import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { EditExpenseAnnotationParamsIdentity } from '../../domain-models/edit-expense-annotation-params.identity';

@IdentityTypeDecorator(EditExpenseAnnotationParamsIdentity)
@ModelTypeNameDecorator('ExpenseAnnotationNote')
export class GeneratedExpenseAnnotationNote extends CoreModel<EditExpenseAnnotationParamsIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _note: string;

	/**
	 Note
	 */
	@Expose()
	public get note(): string {
		return this.getValue<string>(() => this._note, 'note');
	}
	public set note(value: string) {
		this.setValue<string>(() => { this._note = value; }, value, 'note');
	}
}
