import { Expose, Type } from "@nts/std";
import { FileDataIdentity } from "src/app/external-remote/file-data/file-data.identity";

export class UploadFileOutputDto {

    @Expose()
    public storageFileName: string;

    @Expose()
    @Type(() => FileDataIdentity)
    public identity: FileDataIdentity;
}
