import { appAddMediaImageIcon } from './add-media-image';
import { appDriveIcon } from './drive';
import { appFillIcon } from './fill';
import { appHomeIcon } from './home';
import { appInsinuateIcon } from './insinuate';
import { appJournalIcon } from './journal';
import { appMediaImageIcon } from './media-image';
import { appOrganizeIcon } from './organize';
import { appPackIcon } from './pack';
import { appPhotographIcon } from './photograph';
import { appRestIcon } from './rest';
import { appRoastIcon } from './roast';
import { appSpinIcon } from './spin';
import { appTransportIcon } from './transport';
import { appWarningCircleOutlineIcon } from './warning-circle-outline';
export const receiptIcons = [appAddMediaImageIcon, appDriveIcon, appFillIcon, appHomeIcon, appInsinuateIcon, appJournalIcon, appMediaImageIcon, appOrganizeIcon, appPackIcon, appPhotographIcon, appRestIcon, appRoastIcon, appSpinIcon, appTransportIcon, appWarningCircleOutlineIcon];
