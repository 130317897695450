import { RECEIPT_STEPS } from "../receipt-steps.service";
import { BaseState } from "./base-state";
import { ExpenseDataState } from "./expense-data-state";
import { PaymentDataState } from "./payment-data-state";
import { StepStateInterface } from "./step-state.interface";

export class AccommodationDataState extends BaseState implements StepStateInterface {
    classType = AccommodationDataState;
    property = 'accommodationData';
    slug = RECEIPT_STEPS.accommodationData;
    async previous(context: any): Promise<StepStateInterface> {
        return new ExpenseDataState();
    }
    async next(context: any): Promise<StepStateInterface> {
        return new PaymentDataState();
    }
}
