
import {
    IdentityTypeDecorator, OCCModel, ModelTypeNameDecorator, Expose, AllowedChars, NumberDecorator,
    StringDecorator, EnumDecorator
} from '@nts/std';
import { CurrencyIdentity } from './currency.identity';


@IdentityTypeDecorator(CurrencyIdentity)
@ModelTypeNameDecorator('Currency')
export class Currency extends OCCModel<CurrencyIdentity> {

    // tslint:disable-next-line: variable-name
    private _id: number;

    @Expose()
    @NumberDecorator({ displayNameKey: 'Currency_Id' })
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }

    // tslint:disable-next-line: variable-name
    private _name: string;

    @Expose()
    get name(): string {
        return this.getValue<string>(() => this._name, 'name');
    }
    set name(value: string) {
        this.setValue<string>(() => { this._name = value; }, value, 'name');
    }


    // tslint:disable-next-line: variable-name
    private _description: string;

    @Expose()
    get description(): string {
        return this.getValue<string>(() => this._description, 'description');
    }
    set description(value: string) {
        this.setValue<string>(() => { this._description = value; }, value, 'description');
    }

    // tslint:disable-next-line: variable-name
    private _isoCode: string;

    @Expose()
    get isoCode(): string {
        return this.getValue<string>(() => this._isoCode, 'isoCode');
    }
    set isoCode(value: string) {
        this.setValue<string>(() => { this._isoCode = value; }, value, 'isoCode');
    }

    // tslint:disable-next-line: variable-name
    private _symbol: string;

    @Expose()
    get symbol(): string {
        return this.getValue<string>(() => this._symbol, 'symbol');
    }
    set symbol(value: string) {
        this.setValue<string>(() => { this._symbol = value; }, value, 'symbol');
    }

    private _isDefault: boolean;

    @Expose()
    get isDefault(): boolean {
        return this.getValue<boolean>(() => this._isDefault, 'isDefault');
    }
    set isDefault(value: boolean) {
        this.setValue<boolean>(() => { this._isDefault = value; }, value, 'isDefault');
    }


}
