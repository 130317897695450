import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';

@IdentityTypeNameDecorator('ExpenseModelIdentity')
export class GeneratedExpenseModelIdentity extends BaseIdentity {

	private _modelCode: number;

	@Expose()
	public get modelCode(): number {
		return this.getValue<number>(() => this._modelCode, 'modelCode');
	}
	public set modelCode(value: number) {
		this.setValue<number>(() => { this._modelCode = value; }, value, 'modelCode');
	}
}
