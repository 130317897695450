import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, Transform, DateTimeOffset, TimeSpan, CoreModel
} from '@nts/std';
import { LabelIdentity } from '../../domain-models/label.identity';
import { ExpenseClassification } from 'src/app/receipt-long-op/generated/domain-models/enums/generated-expense-classification';


@IdentityTypeDecorator(LabelIdentity)
@ModelTypeNameDecorator('Label')
export class GeneratedLabel extends CoreModel<LabelIdentity> {

	private _fixedIdentity: number;

	/**
	 Identity
	 */
	@Expose()
	public get fixedIdentity(): number {
		return this.getValueForIdentityValue<number>(() => this._fixedIdentity, 'fixedIdentity');
	}
	public set fixedIdentity(value: number) {
		this.setValueForIdentityValue<number>(() => { this._fixedIdentity = value; }, value, 'fixedIdentity');
	}

	private _serial: number = 0;

	/**
	 Serial
	 */
	@Expose()
	public get serial(): number {
		return this.getValueForIdentityValue<number>(() => this._serial, 'serial');
	}
	public set serial(value: number) {
		this.setValueForIdentityValue<number>(() => { this._serial = value; }, value, 'serial');
	}

	private _classification: ExpenseClassification;

	/**
	 Classificazione spesa
	 */
	@Expose()
	public get classification(): ExpenseClassification {
		return this.getValue<ExpenseClassification>(() => this._classification, 'classification');
	}
	public set classification(value: ExpenseClassification) {
		this.setValue<ExpenseClassification>(() => { this._classification = value; }, value, 'classification');
	}

	private _labelDefinition: string;

	/**
	 Etichetta
	 */
	@Expose()
	public get labelDefinition(): string {
		return this.getValue<string>(() => this._labelDefinition, 'labelDefinition');
	}
	public set labelDefinition(value: string) {
		this.setValue<string>(() => { this._labelDefinition = value; }, value, 'labelDefinition');
	}
}
