import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { NTSTranslatePipe } from "@nts/std";
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
    selector: 'nts-fullscreen-preview',
    templateUrl: './fullscreen-preview.component.html',
    styleUrls: ['./fullscreen-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NTSTranslatePipe,
        NgIf,
        SvgIconComponent
    ]
})
export class FullscreenPreviewComponent implements OnChanges {

    @Input() imageSrc: SafeResourceUrl;
    @Input() show: boolean;
    @Input() inIframe: boolean = true;

    @Output() imageSrcChange: EventEmitter<SafeResourceUrl> = new EventEmitter<SafeResourceUrl>();


    constructor(
        private cd: ChangeDetectorRef,
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['imageSrc']) {
            this.cd.detectChanges();
        }
    }

    @HostListener('keydown.escape')
    closeFullScreen() {
        this.imageSrc = null;
        this.imageSrcChange.next(null);
    }
}
