import {
	InternalViewModel, StringPropertyViewModel, GuidPropertyViewModel, EnumPropertyViewModel, BoolPropertyViewModel, NumericPropertyViewModel, DateTimeOffsetPropertyViewModel, DateTimePropertyViewModel, 
	NNumericPropertyViewModel, NDateTimeOffsetPropertyViewModel, NDateTimePropertyViewModel
} from '@nts/std';
import { ExpenseSelection } from '../../domain-models/expense-selection';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';

export class GeneratedExpenseSelectionViewModel extends InternalViewModel<ExpenseSelection, ReceiptParamsIdentity> {

	private _id: NumericPropertyViewModel;

	public get id(): NumericPropertyViewModel {
		return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
	}

	private _expenseClassification: EnumPropertyViewModel;

	public get expenseClassification(): EnumPropertyViewModel {
		return this.getEnumPropertyViewModel((value) => { this._expenseClassification = value; }, this._expenseClassification, 'expenseClassification');
	}

}
