import { Pipe, PipeTransform } from '@angular/core';
import { OfflineReceipt } from 'src/app/shared/models/offline-receipt.interface';

@Pipe({
    name: 'selectedReceiptsFilter',
    pure: false,
    standalone: true,
})
export class SelectedReceiptsFilterPipe implements PipeTransform {

    transform(
        items: Array<OfflineReceipt>,
    ): Array<any> {
        return items.filter(item => item.isSelected === true);
    }
}
