import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ExpenseTypeIdentity } from '../../domain-models/expense-type.identity';
import { ExpenseTypeBusinessData } from '../../domain-models/expense-type-business-data';
import { ExpenseClassification } from 'src/app/receipt-long-op/generated/domain-models/enums/generated-expense-classification';

@IdentityTypeDecorator(ExpenseTypeIdentity)
@ModelTypeNameDecorator('ExpenseType')
export class GeneratedExpenseType extends CoreModel<ExpenseTypeIdentity> {

	private _code: number = 0;

	/**
	 Codice tipo spesa
	 */
	@Expose()
	public get code(): number {
		return this.getValueForIdentityValue<number>(() => this._code, 'code');
	}
	public set code(value: number) {
		this.setValueForIdentityValue<number>(() => { this._code = value; }, value, 'code');
	}

	private _description: string;

	/**
	 Descrizione tipo spesa
	 */
	@Expose()
	public get description(): string {
		return this.getValue<string>(() => this._description, 'description');
	}
	public set description(value: string) {
		this.setValue<string>(() => { this._description = value; }, value, 'description');
	}

	private _refoundClass: ExpenseClassification;

	/**
	 Classificazione
	 */
	@Expose()
	public get refoundClass(): ExpenseClassification {
		return this.getValue<ExpenseClassification>(() => this._refoundClass, 'refoundClass');
	}
	public set refoundClass(value: ExpenseClassification) {
		this.setValue<ExpenseClassification>(() => { this._refoundClass = value; }, value, 'refoundClass');
	}

	private _businessData: ExpenseTypeBusinessData;

	/**
	 Dati Business
	 */
	@Expose()
	@Type(() => ExpenseTypeBusinessData)
	public get businessData(): ExpenseTypeBusinessData {
		return this.getValueForInternal<ExpenseTypeBusinessData>(
			(value) => {
				this._businessData = value;
			},
			this._businessData,
			'businessData',
			ExpenseTypeBusinessData
		);
	}
	public set businessData(value: ExpenseTypeBusinessData) {
		this.setValueForInternal<ExpenseTypeBusinessData>(() => { this._businessData = value; }, value, 'businessData');
	}

	private _companyId: number = 0;

	/**

	 */
	@Expose()
	public get companyId(): number {
		return this.getValue<number>(() => this._companyId, 'companyId');
	}
	public set companyId(value: number) {
		this.setValue<number>(() => { this._companyId = value; }, value, 'companyId');
	}
}
