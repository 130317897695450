import {
	IdentityTypeDecorator, ModelTypeNameDecorator, Expose, Type, OCCAuditDeactivableModel, CoreModel
} from '@nts/std';
import { ExpenseModel } from 'src/app/expense-model/domain-models/expense-model';
import { ExpenseModelIdentity } from 'src/app/expense-model/domain-models/expense-model.identity';
import { ReceiptParamsIdentity } from '../../domain-models/receipt-params.identity';

@IdentityTypeDecorator(ReceiptParamsIdentity)
@ModelTypeNameDecorator('ExpenseModelData')
export class GeneratedExpenseModelData extends CoreModel<ReceiptParamsIdentity> {

	private _id: number = 0;

	/**
	 Id long op
	 */
	@Expose()
	public get id(): number {
		return this.getValueForIdentityValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
	}

	private _expenseModelCode: number;

	/**
	 Codice Modello spesa
	 */
	@Expose()
	public get expenseModelCode(): number {
		return this.getValue<number>(() => this._expenseModelCode, 'expenseModelCode');
	}
	public set expenseModelCode(value: number) {
		this.setValue<number>(() => { this._expenseModelCode = value; }, value, 'expenseModelCode');
	}

	private _expenseModelRef: ExpenseModel;

	/**
	 Modello spesa
	 */
	@Expose()
	@Type(() => ExpenseModel)
	public get expenseModelRef(): ExpenseModel {
		return this.getValueForExternal<ExpenseModel>(
			() => this._expenseModelRef,
			'expenseModelRef',
			ExpenseModel
		);
	}
	public set expenseModelRef(value: ExpenseModel) {
		this.setValueForExternal<ExpenseModel, ExpenseModelIdentity>(() => { this._expenseModelRef = value; }, value, 'expenseModelRef');
	}
}
