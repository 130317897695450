import { IdentityTypeNameDecorator, Expose, BaseIdentity } from '@nts/std';
import { ExpenseAnnotationIdentity } from '../../../expense-annotation/domain-models/expense-annotation.identity';

@IdentityTypeNameDecorator('ExpenseIdentity')
export class GeneratedExpenseIdentity extends ExpenseAnnotationIdentity {

	private _expenseId: number;

	@Expose()
	public get expenseId(): number {
		return this.getValue<number>(() => this._expenseId, 'expenseId');
	}
	public set expenseId(value: number) {
		this.setValue<number>(() => { this._expenseId = value; }, value, 'expenseId');
	}
}
