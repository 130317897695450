import { LongOpParametersTypeDecorator, LongOpResultTypeDecorator, LongOpRootViewModel, NumericPropertyViewModel } from "@nts/std";
import { ReceiptLongOp } from "../domain-models/receipt-long-op";
import { ReceiptLongOpIdentity } from "../domain-models/receipt-long-op-identity";
import { ReceiptParams } from "../domain-models/receipt-params";
import { ReceiptResult } from "../domain-models/receipt-result";
import { ReceiptParamsViewModel } from "./receipt-params.view-model";
import { ReceiptResultViewModel } from "./receipt-result.view-model";

@LongOpParametersTypeDecorator(ReceiptParamsViewModel)
@LongOpResultTypeDecorator(ReceiptResultViewModel)
export class ReceiptLongOpViewModel extends LongOpRootViewModel<ReceiptLongOp, ReceiptLongOpIdentity, ReceiptParamsViewModel,
ReceiptResultViewModel, ReceiptParams, ReceiptResult>  {

    private _id: NumericPropertyViewModel;

    public get id(): NumericPropertyViewModel {
        return this.getNumericPropertyViewModel((value) => { this._id = value; }, this._id, 'id');
    }
}
