import { BaseIdentity, Expose, IdentityTypeNameDecorator } from '@nts/std';

@IdentityTypeNameDecorator('ExtendedAvailablePaymentIdentity')
export class ExtendedAvailablePaymentIdentity extends BaseIdentity {

	private _id: number;

	@Expose()
	public get id(): number {
		return this.getValue<number>(() => this._id, 'id');
	}
	public set id(value: number) {
		this.setValue<number>(() => { this._id = value; }, value, 'id');
	}
}
