import { appAdministrationEditingIcon } from './administration-editing';
import { appApprovedIcon } from './approved';
import { appAttachmentIcon } from './attachment';
import { appAviFormatIcon } from './avi-format';
import { appCompressIcon } from './compress';
import { appExpenseAnnotationAlertIcon } from './expense-annotation-alert';
import { appGifFormatIcon } from './gif-format';
import { appIntegrateDocumentationIcon } from './integrate-documentation';
import { appIntegratedDocumentationIcon } from './integrated-documentation';
import { appJournalIcon } from './journal';
import { appJpegFormatIcon } from './jpeg-format';
import { appMediaVideoIcon } from './media-video';
import { appMoneySquareIcon } from './money-square';
import { appMpegFormatIcon } from './mpeg-format';
import { appPngFormatIcon } from './png-format';
import { appRawFormatIcon } from './raw-format';
import { appRecordedIcon } from './recorded';
import { appSvgFormatIcon } from './svg-format';
import { appTifFormatIcon } from './tif-format';
import { appTiffFormatIcon } from './tiff-format';
import { appWebEditingIcon } from './web-editing';
import { appWebpFormatIcon } from './webp-format';
export const sharedIcons = [appAdministrationEditingIcon, appApprovedIcon, appAttachmentIcon, appAviFormatIcon, appCompressIcon, appExpenseAnnotationAlertIcon, appGifFormatIcon, appIntegrateDocumentationIcon, appIntegratedDocumentationIcon, appJournalIcon, appJpegFormatIcon, appMediaVideoIcon, appMoneySquareIcon, appMpegFormatIcon, appPngFormatIcon, appRawFormatIcon, appRecordedIcon, appSvgFormatIcon, appTifFormatIcon, appTiffFormatIcon, appWebEditingIcon, appWebpFormatIcon];
